import { Typography, Paper, Stack, IconButton, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useGetRecordsForASetOfRefsQuery } from '../slices/trackerApiSlice';
import { useState } from 'react';

function CalButton({ day, disabled, color }: { day: string | number, disabled?: boolean, color?: string }) {
  return (
        <IconButton
            size='small'
            sx={{
              fontSize: '0.8rem',
              width: '2rem',
              height: '2rem',
              borderColor: 'red',
              borderSize: '1px',
              backgroundColor: color ? color : 'white',

            }}
            disabled={day === '-' || disabled}
        >
            {day}
        </IconButton>
  );
}


function MonthCalendar({ currentMonth, colorMap, colorType }:
{ currentMonth: Date, colorMap: any, colorType: string }) {
  const numberOfDays = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
  const firstDayOfWeek = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay();
  const emptyDays = Array(firstDayOfWeek).fill('-');
  const daysInMonth = [];

  for (let i = 1; i <= numberOfDays; i++) {
    daysInMonth.push(i);
  }

  const days = [...emptyDays, ...daysInMonth];
  //add to days array to make it divisible equal to 49

  const numberOfDaysToAdd = 42 - days.length;
  const emptyDaysToAdd = Array(numberOfDaysToAdd).fill('-');
  days.push(...emptyDaysToAdd);

  // Define the days of the week
  const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  const getColor = (cMonth: Date, day: any, cMap: any, cType: string): string => {
    const date = new Date(cMonth.getFullYear(), cMonth.getMonth(), day);
    const dayString = date.toISOString().split('T')[0];
    if (cMap && cMap[dayString]) {
      return cMap[dayString][cType];
    }

    return 'white';
  };

  return (
        <Paper elevation={1} style={{ padding: '10px' }}>
            <Stack spacing={0.5}>
                <Typography variant="body1" fontWeight="800" align="center">
                    {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
                </Typography>
                <Stack direction="row"
                    spacing={0.5}
                    display="block"
                    justifyContent="flex-start"
                >
                    {daysOfWeek.map((day, index) => (
                        <CalButton
                            key={currentMonth.getMonth() + index}
                            day={day}
                            disabled={true} />
                    ))}
                </Stack>
                { // Create a row for each week 
                    days.map((day, index) => {
                      if (index % 7 === 0) {
                        return (
                                <Stack key={index} direction="row" spacing={0.5}
                                    display="block"
                                    justifyContent="flex-start">
                                    {days.slice(index, index + 7).map((d, idx) => (
                                        <CalButton
                                            key={idx}
                                            day={d}
                                            color={d !== '-' ?
                                              getColor(currentMonth, d, colorMap, colorType)
                                              : 'white'}
                                        />
                                    ))}
                                </Stack>
                        );
                      }
                      return null;
                    })}
            </Stack>
        </Paper>
  );
}

function dataToColorMap(dataArray: any) {
  const gradientTotal = ['#ffffffff', '#df705820', '#e26b5a3e', '#e26c5c7d', '#e36d5aa4', '#e36d5bca', '#e36d5bff'];
  const gradientValid = ['#ffffffff', '#00850017', '#00800042', '#0080006a', '#00800096', '#008000c0', '#008000ff'];
  const gradientInvalid = ['#ffffffff', '#d3000017', '#d4000041', '#d400006b', '#d3000096', '#d40000be', '#d40000ff'];

  // Initialize an object to store the results
  const result: any = {};

  // Initialize min and max values for color scale
  let minTotal = Number.MAX_SAFE_INTEGER;
  let maxTotal = Number.MIN_SAFE_INTEGER;
  let minValid = Number.MAX_SAFE_INTEGER;
  let maxValid = Number.MIN_SAFE_INTEGER;
  let minInvalid = Number.MAX_SAFE_INTEGER;
  let maxInvalid = Number.MIN_SAFE_INTEGER;

  dataArray.forEach((entry: any) => {
    const date = new Date(entry.date);
    const day = date.toISOString().split('T')[0];

    if (!result[day]) {
      result[day] = {
        totalOccurrences: 0,
        totalInvalid: 0,
        totalValid: 0,
      };
    }

    result[day].totalOccurrences++;
    if (entry.result === 'verification-invalid') {
      result[day].totalInvalid++;
    } else {
      result[day].totalValid++;
    }

    // Update min and max values for color scale
    minTotal = Math.min(minTotal, result[day].totalOccurrences);
    maxTotal = Math.max(maxTotal, result[day].totalOccurrences);
    minValid = Math.min(minValid, result[day].totalValid);
    maxValid = Math.max(maxValid, result[day].totalValid);
    minInvalid = Math.min(minInvalid, result[day].totalInvalid);
    maxInvalid = Math.max(maxInvalid, result[day].totalInvalid);
  });

  // Define a function to calculate color based on the count and gradient
  const getColor = (count: number, min: number, max: number, gradient: string[]) => {
    const t = (count - min) / (max - min);
    const index = Math.floor(t * (gradient.length - 1));
    return gradient[index];
  };

  // Loop through the result object and assign colors
  for (const day in result) {
    const dayData = result[day];
    dayData.colorTotal = getColor(dayData.totalOccurrences, minTotal, maxTotal, gradientTotal);
    dayData.colorValid = getColor(dayData.totalValid, minValid, maxValid, gradientValid);
    dayData.colorInvalid = getColor(dayData.totalInvalid, minInvalid, maxInvalid, gradientInvalid);
  }

  return {
    result,
    maxTotal,
    maxValid,
    maxInvalid,
  };
}

function ThreeMonthCalendars({ trackerRefs }: { trackerRefs: string[] }) {
  let colorMap = null;

  const [colorType, setColorType] = useState<string>('colorTotal');
  const currentMonth = new Date();
  const previousMonth = new Date(currentMonth);
  previousMonth.setMonth(previousMonth.getMonth() - 1);
  const twoMonthsAgo = new Date(previousMonth);
  twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 1);

  const { data } = useGetRecordsForASetOfRefsQuery(trackerRefs);

  if (data)
    colorMap = dataToColorMap(data);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setColorType(event.target.value);
  };

  return (
        <Stack spacing={2}>
            <Stack spacing={2} direction='row' justifyContent='space-between'>
                <MonthCalendar currentMonth={twoMonthsAgo} colorMap={colorMap?.result} colorType={colorType} />
                <MonthCalendar currentMonth={previousMonth} colorMap={colorMap?.result} colorType={colorType} />
                <MonthCalendar currentMonth={currentMonth} colorMap={colorMap?.result} colorType={colorType} />
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <Typography variant="body1" fontWeight="800" align="center" paddingLeft={5}>
                    {!colorMap ? 'No verifications observed' :
                      colorType === 'colorTotal' ? `Peak with ${colorMap.maxTotal} verifications` :
                        colorType === 'colorValid' ? `Peak with ${colorMap.maxValid} valid verifications` :
                          `Peak with ${colorMap.maxInvalid} invalid verifications`}
                </Typography>
                <FormControl component="fieldset">
                    <RadioGroup
                        row
                        aria-label="options"
                        name="radio-options"
                        value={colorType}
                        onChange={handleChange}
                    >
                        <FormControlLabel
                            value="colorTotal"
                            control={<Radio />}
                            label="Total"
                        />
                        <FormControlLabel
                            value="colorValid"
                            control={<Radio />}
                            label="Valid"
                        />
                        <FormControlLabel
                            value="colorInvalid"
                            control={<Radio />}
                            label="Invalid"
                        />
                    </RadioGroup>
                </FormControl>
            </Stack>
        </Stack>
  );
}

export default ThreeMonthCalendars;
