export interface DomainAnalysis {
  _id: string;
  domain: string;
  analysis: {
    message: string;
    success: boolean;
    unsafe: boolean;
    domain: string;
    root_domain: string;
    ip_address: string;
    server: string;
    content_type: string;
    status_code: number;
    page_size: number;
    domain_rank: number;
    dns_valid: boolean;
    parking: boolean;
    spamming: boolean;
    malware: boolean;
    phishing: boolean;
    suspicious: boolean;
    adult: boolean;
    risk_score: number;
    country_code: string;
    category: string;
    domain_age: {
      human: string;
      timestamp: number;
      iso: string;
    };
    domain_trust: string;
    redirected: boolean;
    short_link_redirect: boolean;
    hosted_content: boolean;
    page_title: string;
    risky_tld: boolean;
    spf_record: boolean;
    dmarc_record: boolean;
    technologies: string[];
    a_records: string[];
    mx_records: string[];
    ns_records: string[];
    language_code: string;
    final_url: string;
    scanned_url: string;
    request_id: string;
  };
  createdAt: string;
  updatedAt: string;
}

export enum DomainAnalysisLevel {
  INFO = 0,
  WARNING = 1,
  DANGER = 2,
}

export const domainAnalysisDictionary = {
  domain: {
    label: 'Domain',
    type: 'string',
    description: 'The domain name',
  },
  analysis: {
    message: {
      label: 'Message',
      type: 'string',
      description: 'The message from the analysis',
      message: 'The message from the analysis',
      level: DomainAnalysisLevel.INFO,
    },
    success: {
      label: 'Success',
      type: 'boolean',
      description: 'Whether the analysis was successful',
      message: 'Whether the analysis was successful',
      level: DomainAnalysisLevel.INFO,
    },
    unsafe: {
      label: 'Unsafe',
      type: 'boolean',
      description: 'Whether the domain is unsafe',
      message: 'Is this domain suspected of being unsafe due to phishing, malware, spamming, or abusive behavior? View the confidence level by analyzing the "risk_score".',
      level: DomainAnalysisLevel.DANGER,
    },
    domain: {
      label: 'Domain',
      type: 'string',
      description: 'The domain name',
      message: 'Domain name of the final destination URL of the scanned link, after following all redirects. This value will display sub domains.',
      level: DomainAnalysisLevel.INFO,
    },
    root_domain: {
      label: 'Root Domain',
      type: 'string',
      description: 'The root domain name',
      message: 'Parent domain to identify the root level domain of the final destination URL. This value excludes sub domains.',
      level: DomainAnalysisLevel.INFO,
    },
    ip_address: {
      label: 'IP Address',
      type: 'string',
      description: 'The IP address of the domain',
      message: 'The IP address corresponding to the server of the domain name.',
      level: DomainAnalysisLevel.INFO,
    },
    server: {
      label: 'Server',
      type: 'string',
      description: 'The server of the domain',
      message: 'The server banner of the domain\'s IP address. For example: "nginx/1.16.0". Value will be "N/A" if unavailable.',
      level: DomainAnalysisLevel.INFO,
    },
    content_type: {
      label: 'Content Type',
      type: 'string',
      description: 'The content type of the domain',
      message: 'MIME type of URL\'s content. For example "text/html; charset=UTF-8". Value will be "N/A" if unavailable.',
      level: DomainAnalysisLevel.INFO,
    },
    status_code: {
      label: 'Status Code',
      type: 'number',
      description: 'The status code of the domain',
      message: 'HTTP Status Code of the URL\'s response. This value should be "200" for a valid website. Value is "0" if URL is unreachable.',
      level: DomainAnalysisLevel.INFO,
    },
    page_size: {
      label: 'Page Size',
      type: 'number',
      description: 'The page size of the domain',
      message: 'Total number of bytes to download the URL\'s content. Value is "0" if URL is unreachable.',
      level: DomainAnalysisLevel.INFO,
    },
    domain_rank: {
      label: 'Domain Rank',
      type: 'number',
      description: 'The domain rank of the domain',
      message: 'Estimated popularity rank of website globally. Value is "0" if the domain is unranked or has low traffic.',
      level: DomainAnalysisLevel.INFO,
    },
    dns_valid: {
      label: 'DNS Invalid',
      type: 'boolean',
      description: 'Whether the DNS is valid',
      message: 'The domain of the URL has valid DNS records.',
      level: DomainAnalysisLevel.WARNING,
    },
    parking: {
      label: 'Parking',
      type: 'boolean',
      description: 'Parked domains are domains that display a parking page when visited. This means that the domain does not have any content yet.',
      message: 'Is the domain of this URL currently parked with a for sale notice?',
      level: DomainAnalysisLevel.WARNING,
    },
    spamming: {
      label: 'Spamming',
      type: 'boolean',
      description: 'Whether the domain is used for spamming',
      message: 'Is the domain of this URL associated with email SPAM or abusive email addresses?',
      level: DomainAnalysisLevel.DANGER,
    },
    malware: {
      label: 'Malware',
      type: 'boolean',
      description: 'This domain is used to distribute malware',
      message: 'Is this URL associated with malware or viruses?',
      level: DomainAnalysisLevel.DANGER,
    },
    phishing: {
      label: 'Phishing',
      type: 'boolean',
      description: 'Indicates if the domain is used in phishing attacks',
      message: 'Is this URL associated with malicious phishing behavior?',
      level: DomainAnalysisLevel.DANGER,
    },
    suspicious: {
      label: 'Suspicious',
      type: 'boolean',
      description: 'Indicates if the domain is suspicious',
      message: 'Is this URL suspected of being malicious or used for phishing or abuse? Use in conjunction with the "risk_score" as a confidence level.',
      level: DomainAnalysisLevel.DANGER,
    },
    adult: {
      label: 'Adult',
      type: 'boolean',
      description: 'Indicates if the domain is related to adult content',
      message: 'Is this URL or domain hosting dating or adult content?',
      level: DomainAnalysisLevel.WARNING,
    },
    risk_score: {
      label: 'Risk Score',
      type: 'number',
      description: 'The risk score of the domain',
      message: 'The IPQS risk score which estimates the confidence level for malicious URL detection. Risk Scores 85+ are high risk, while Risk Scores = 100 are confirmed as accurate.',
      level: DomainAnalysisLevel.INFO,
    },
    country_code: {
      label: 'Country Code',
      type: 'string',
      description: 'The country code of the domain',
      message: 'The country corresponding to the server\'s IP address.',
      level: DomainAnalysisLevel.INFO,
    },
    category: {
      label: 'Category',
      type: 'string',
      description: 'The category of the domain',
      message: 'Website classification and category related to the content and industry of the site. Over 70 categories are available including "Video Streaming", "Trackers", "Gaming", "Privacy", "Advertising", "Hacking", "Malicious", "Phishing", etc. The value will be "N/A" if unknown.',
      level: DomainAnalysisLevel.INFO,
    },
    domain_age: {
      human: {
        label: 'Domain Age',
        type: 'string',
        description: 'The age of the domain',
        message: 'The age of the domain in human readable format. For example: "5 years, 3 months".',
        level: DomainAnalysisLevel.INFO,
      },
      timestamp: {
        label: 'Domain Age Timestamp',
        type: 'number',
        description: 'The timestamp of the domain age',
        message: 'The age of the domain in seconds since registration. Value will be "0" if unavailable.',
        level: DomainAnalysisLevel.INFO,
      },
      iso: {
        label: 'Domain Age ISO',
        type: 'string',
        description: 'The ISO of the domain age',
        message: 'The age of the domain in ISO 8601 format. For example: "P5Y3M".',
        level: DomainAnalysisLevel.INFO,
      },
    },
    domain_trust: {
      label: 'Domain Trust',
      type: 'string',
      description: 'The trust of the domain',
      message: 'Risk classification of the URL\'s domain based on past abuse issues and positive behavior signals. Values include: "trusted", "positive", "neutral", "suspicious", "malicious", or "not rated".',
      level: DomainAnalysisLevel.INFO,
    },
    redirected: {
      label: 'Redirected',
      type: 'boolean',
      description: 'Whether the domain is redirected',
      message: 'Does the URL redirect to another domain when loaded in a browser?',
      level: DomainAnalysisLevel.INFO,
    },
    short_link_redirect: {
      label: 'Short Link Redirect',
      type: 'boolean',
      description: 'Whether the domain is a short link redirect',
      message: 'Indicates if a URL shortener was found in the link or redirect of the URL\'s path.',
      level: DomainAnalysisLevel.INFO,
    },
    hosted_content: {
      label: 'Hosted Content',
      type: 'boolean',
      description: 'Whether the domain is hosted content',
      message: 'Identifies free content hosting services like Weebly, Blogspot, and others which are more prone to hosting malicious content by abusive users. These sites are typically suspended very quickly and serve content on a sub domain of a popular website. Cybercriminals favor these sites since the overall domain reputation will be high.',
      level: DomainAnalysisLevel.INFO,
    },
    page_title: {
      label: 'Page Title',
      type: 'string',
      description: 'The title of the page',
      message: 'Returns the URL\'s title meta tag as text.',
      level: DomainAnalysisLevel.INFO,
    },
    risky_tld: {
      label: 'Risky TLD',
      type: 'boolean',
      description: 'Whether the TLD is risky',
      message: 'Signals that the domain belongs to a risky TLD extension frequently associated with malware, scams, or phishing.',
      level: DomainAnalysisLevel.WARNING,
    },
    spf_record: {
      label: 'No SPF Record',
      type: 'boolean',
      description: 'Whether the domain has an SPF record',
      message: 'Confirms if the domain has a proper SPF DNS record.',
      level: DomainAnalysisLevel.WARNING,
    },
    dmarc_record: {
      label: 'No DMARC Record',
      type: 'boolean',
      description: 'Whether the domain has a DMARC record',
      message: 'Confirms if the domain has a proper DMARC DNS record.',
      level: DomainAnalysisLevel.WARNING,
    },
    technologies: {
      label: 'Technologies',
      type: 'string',
      description: 'The technologies used by the domain',
      message: 'Comma separated list of technologies found on the URL, such as WordPress, Shopify, Cloudflare, Google Analytics, Google Ads, and similar popular services.',
      level: DomainAnalysisLevel.INFO,
    },
    a_records: {
      label: 'A Records',
      type: 'string',
      description: 'The A records of the domain',
      message: 'List of A records associated with the URL\'s domain name.',
      level: DomainAnalysisLevel.INFO,
    },
    mx_records: {
      label: 'MX Records',
      type: 'string',
      description: 'The MX records of the domain',
      message: 'List of MX records associated with the URL\'s domain name.',
      level: DomainAnalysisLevel.INFO,
    },
    ns_records: {
      label: 'NS Records',
      type: 'string',
      description: 'The NS records of the domain',
      message: 'List of NS records associated with the URL\'s domain name.',
      level: DomainAnalysisLevel.INFO,
    },
    language_code: {
      label: 'Language Code',
      type: 'string',
      description: 'The language code of the domain',
      message: 'Language code of the URL\'s content. For example "en" for English. Value will be "N/A" if unavailable.',
      level: DomainAnalysisLevel.INFO,
    },
    final_url: {
      label: 'Final URL',
      type: 'string',
      description: 'The final URL of the domain',
      message: 'The final destination URL of the scanned link, after following all redirects.',
      level: DomainAnalysisLevel.INFO,
    },
    scanned_url: {
      label: 'Scanned URL',
      type: 'string',
      description: 'The scanned URL of the domain',
      message: 'Original URL which was analyzed for malware, phishing, abuse, etc. before any redirections.',
      level: DomainAnalysisLevel.INFO,
    },
    request_id: {
      label: 'Request ID',
      type: 'string',
      description: 'The request ID of the domain',
      message: 'Unique identifier for the URL scan request. Use this value to reference the scan in the future.',
      level: DomainAnalysisLevel.INFO,
    },
  },
};

export interface DomainAnalysisDictionaryElement {
  label: string;
  type: string;
  description: string;
  message?: string;
  level?: DomainAnalysisLevel;
}

export function retrieveIOCs(domainAnalysis: DomainAnalysis): DomainAnalysisDictionaryElement[] {
  const iocs:DomainAnalysisDictionaryElement[] = [];
  
  if (domainAnalysis.analysis.unsafe) { iocs.push(domainAnalysisDictionary.analysis.unsafe); }
  if (!domainAnalysis.analysis.dns_valid) { iocs.push(domainAnalysisDictionary.analysis.dns_valid); }
  if (domainAnalysis.analysis.parking) { iocs.push(domainAnalysisDictionary.analysis.parking); }
  if (domainAnalysis.analysis.spamming) { iocs.push(domainAnalysisDictionary.analysis.spamming); }
  if (domainAnalysis.analysis.malware) { iocs.push(domainAnalysisDictionary.analysis.malware); }
  if (domainAnalysis.analysis.phishing) { iocs.push(domainAnalysisDictionary.analysis.phishing); }
  if (domainAnalysis.analysis.suspicious) { iocs.push(domainAnalysisDictionary.analysis.suspicious); }
  if (domainAnalysis.analysis.adult) { iocs.push(domainAnalysisDictionary.analysis.adult); }
  if (domainAnalysis.analysis.risky_tld) { iocs.push(domainAnalysisDictionary.analysis.risky_tld); }
  if (!domainAnalysis.analysis.spf_record) { iocs.push(domainAnalysisDictionary.analysis.spf_record); }
  if (!domainAnalysis.analysis.dmarc_record) { iocs.push(domainAnalysisDictionary.analysis.dmarc_record); }
  if (domainAnalysis.analysis.redirected) { iocs.push(domainAnalysisDictionary.analysis.redirected); }
  if (domainAnalysis.analysis.hosted_content) { iocs.push(domainAnalysisDictionary.analysis.hosted_content); }

  return iocs;
}