import { useParams } from 'react-router-dom';
import { useShowUserSimpleProfileQuery } from '../slices/showApiSlice';
import { useGetProofsByUserQuery } from '../slices/proofApiSlice';
import { Container, Box, Stack, Typography, Divider, Card, CardContent, Grid, Chip } from '@mui/material';
import ProofSummary from '../components/ProofSummary';
import {
  useShowUserMerkleQuery,
} from '../slices/merkleApiSlice';
import { Link } from '@mui/material';
import makeBlockie from 'ethereum-blockies-base64';
import { dateAndHour } from '../utils/dates';
import { UserMerkleLeaf } from '../utils/userMerkle';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DataVerifier from '../components/DataVerifier';
import { useState } from 'react';

interface UserMerkle {
  _id: string;
  root: string;
  updatedAt: string;
  leaves: any[]; // replace any with the actual type of leaves
  // include other properties if there are any
}

export default function ShowUser() {

  const { id } = useParams();
  const { data: user } = useShowUserSimpleProfileQuery(id);
  const { data: userProofs } = useGetProofsByUserQuery(id);
  const { data: userMerkle } = useShowUserMerkleQuery(id) as { data: UserMerkle | undefined };

  const [selectedLeaf, setSelectedLeaf] = useState<UserMerkleLeaf | null>(null);
  const [showUserLeaf, setShowUserLeaf] = useState(false);

  const openLeaf = (leafId: string) => () => {
    const leaf = userMerkle?.leaves.find((l: UserMerkleLeaf) => l._id === leafId);
    setSelectedLeaf(leaf);
    setShowUserLeaf(true);
  };

  return (
        <>
            <Container >
                <Box textAlign="left" >
                    <Stack spacing={2} >
                        <Stack spacing={2}>
                            <Typography variant="h6">Account Profile</Typography>
                            <Box sx={{ p: 2 }}>
                                <Typography variant="body1"><strong>Name: </strong>{user?.displayName}</Typography>
                                <Typography variant="body1"><strong>Email: </strong><Link href={`mailto:${user?.email}`}>
                                    {user?.email}
                                </Link>
                                </Typography>
                                <Typography variant="body1"><strong>Security level: </strong>basic</Typography>
                            </Box>
                        </Stack>
                        <Divider />
                        <Stack spacing={2}>
                            <Typography variant="h6">Locks</Typography>
                            <Box sx={{ p: 2 }}>
                                <Card>
                                    <CardContent>
                                        <Stack direction='row' display='flex' justifyContent='space-between'
                                        >
                                            <Stack direction='row' spacing={2} alignItems='center' >
                                                <img src={makeBlockie(userMerkle ? userMerkle.root : '---')} width='60px' />
                                                <Stack spacing={0.5}>
                                                    <Typography variant="body1" fontSize='small' >
                                                        {userMerkle ? userMerkle.root : '---'}
                                                    </Typography>
                                                    <Typography variant="body1" fontSize='small' >
                                                        Number of locks: {userMerkle ? userMerkle.leaves.length : '---'}
                                                    </Typography>
                                                    <Typography variant="body1" fontSize='small' >
                                                        Last update: {userMerkle ? dateAndHour(new Date(userMerkle.updatedAt)) : '---'}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        <Grid container spacing={2} pr={1} mt={1}>
                                            {
                                                userMerkle?.leaves.map((leaf: UserMerkleLeaf, index: number) => (
                                                    <Grid item key={index}> <Chip label={leaf.tag} icon={<LocalOfferIcon fontSize='small' />}
                                                        onClick={openLeaf(leaf._id as string)} /> </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Stack>

                        <Divider />
                        <Stack spacing={2}>
                            <Typography variant="h6">Proofs Published</Typography>
                            {userProofs &&
                                <Box
                                    display="flex"
                                    sx={{
                                      p: 1,
                                    }}>
                                    {userProofs.proofs.map((proof: any) => {
                                      return (
                                            <ProofSummary key={proof._id} userProof={proof} />
                                      );
                                    })}
                                </Box>
                            }
                        </Stack>
                    </Stack>
                </Box>
            </Container>
            {selectedLeaf &&
                <DataVerifier
                    userId={id as string}
                    leaf={selectedLeaf}
                    root={userMerkle?.root as string}
                    show={showUserLeaf}
                    treeRef={userMerkle?._id as string}
                    dialHandler={() => setShowUserLeaf(false)}
                />}
        </>
  );
}