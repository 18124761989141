import { Stack, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, Badge, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useGetAllEmailsQuery, useMergeToAllEmailsMutation, useRefreshEmailAnalysisMutation, usePostNewEmailAnalysisMutation } from '../slices/emailsApiSlice';
import { setEmailsOsint } from '../slices/emailsSlice';
import EmailsList from '../components/EmailsList';
import Filter from '../components/email-utils/Filter';
import SaveIcon from '@mui/icons-material/Save';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

export default function Emails() {

  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [shouldSave, setShouldSave] = useState(false);

  const userInfo = useSelector((state: any) => {
    const user = state.user;
    if (user) {
      return JSON.parse(user.user);
    }
    return null;
  });

  const emails = useSelector((state: any) => {
    if (state?.emails?.emailsOsint) {
      return state.emails.emailsOsint;
    }
    return [];
  });

  const dispatch = useDispatch();
  const { data: emailsList, error } = useGetAllEmailsQuery({});
  const [postNewEmailAnalysis] = usePostNewEmailAnalysisMutation();
  const [refreshEmailAnalysis] = useRefreshEmailAnalysisMutation();
  const [mergeToAllEmails] = useMergeToAllEmailsMutation();

  useEffect(() => {
    if (emailsList) {
      dispatch(setEmailsOsint(emailsList));
    }
    if (error) {
      console.error('Failed to fetch domains:', error);
    }
  }, [emailsList, error, dispatch]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const analysisSubmission = async (email: string) => {
    const result: any = await postNewEmailAnalysis(email);
    if (result) {
      const analysis = {
        email,
        analysis: {
          ...result.data.analysis,
        },
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
      dispatch(setEmailsOsint([analysis, ...emails]));
      setShouldSave(true);
    } else {
      console.error('Failed to fetch email:', email);
    }
  };

  const newAnalysisSubmission = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const textValue = (e.currentTarget.elements[0] as HTMLInputElement).value;
    analysisSubmission(textValue);
    handleClose();
  };

  const addBulkAnalysisSubmission = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // const emailsTest: string[] = [ 'jos'];
    // let i = emailsTest.length;
    // for (const email of emailsTest) {
    //   await analysisSubmission(email);
    //   console.log('Emails remaining:', i--);
    //   // eslint-disable-next-line @typescript-eslint/no-loop-func
    //   await new Promise(resolve => setTimeout(resolve, 5000)); // wait for 5 seconds
    // }
  };

  function saveEmails(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();
    const emailsTest = emails.map((email: any) => email.email);
    // const emailsTest = ['asd@dsad.es'];
    mergeToAllEmails(emailsTest);
    setShouldSave(false);
  }

  function deleteEmail(email: string): void {
    dispatch(setEmailsOsint(emails.filter((d: any) => d.email !== email)));
    setShouldSave(true);
  }

  async function refreshEmail(email: string): Promise<void> {
    try {
      const result: any = await refreshEmailAnalysis(email);
      const updatedEmails = emails.filter((d: any) => d.email !== email);

      dispatch(setEmailsOsint([result.data, ...updatedEmails]));
    } catch (refreshError) {
      console.error('Failed to refresh email analysis:', refreshError);
    }
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h5" textAlign='left' align="center">
        Email Open-Source Intelligence
      </Typography>
      {userInfo && !userInfo.isAnonymous && <>
        <Stack spacing={2} direction='row' justifyContent='space-between'>
          <Stack spacing={2} direction='row'>
            <Filter applyFilter={setFilter} />
            <Typography variant="body2" fontWeight={800} textAlign='left' align="center">{emails.length}</Typography>
          </Stack>
          <Stack spacing={2} direction='row'>
            <Tooltip title="Add Emails" arrow>
              <IconButton aria-label="add-emails" onClick={handleOpen} color='primary'>
                <PersonAddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add Bulk Emails" arrow>
              <IconButton aria-label="save-bulk-emails" onClick={addBulkAnalysisSubmission} color='primary'>
                <PlaylistAddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Save" arrow>
              <IconButton aria-label="save-emails" color='primary' onClick={saveEmails} disabled={!shouldSave}>
                <Badge color="secondary" variant="dot" invisible={!shouldSave}>
                  <SaveIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Add Emails</DialogTitle>
          <form onSubmit={newAnalysisSubmission}>
            <DialogContent>
              <TextField name="emails" label="Emails" fullWidth multiline />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Submit</Button>
            </DialogActions>
          </form>
        </Dialog>
        <EmailsList
          filter={filter}
          deleteEmail={deleteEmail}
          refreshEmail={refreshEmail}
        />
      </>}
      {!userInfo && <Typography variant="body1" textAlign='left' align="center">This feature is exclusive for registered users</Typography>}
    </Stack>
  );
}