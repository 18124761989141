import { ShowURL } from '../constants';
import { apiSlice } from './apiSlice';

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    showUserSimpleProfile: builder.query({
      query: (id) => ({
        url: ShowURL + `/user-simple/${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    showUserCompleteProfileByEmail: builder.query({
      query: (email) => ({
        url: ShowURL + `/user-complete/email/${email}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useShowUserSimpleProfileQuery,
  useShowUserCompleteProfileByEmailQuery,
} = usersApiSlice;
