import { createSlice } from '@reduxjs/toolkit';

const currentStorage = localStorage.getItem('authToken');

const initialState = {
  authToken: currentStorage ? currentStorage : null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.authToken = action.payload;
      localStorage.setItem('authToken', action.payload);

      const expirationDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 5);
      localStorage.setItem('expirationDate', expirationDate.toISOString());
    },
    logout: (state) => {
      state.authToken = null;
      localStorage.clear();
    },
  },
});

export const { setCredentials, logout } = authSlice.actions;

export default authSlice.reducer;