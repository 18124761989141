import { Badge, IconButton, Tooltip, Popover } from '@mui/material';
import Person2Icon from '@mui/icons-material/Person2';
import { useSelector } from 'react-redux';
import { useState } from 'react';
// import { UserNotification } from '../types';
// import { removeUserNotification } from '../slices/userSlice';


function AppScope() {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const dispatch = useDispatch();

  const { userInfo, userNotifications } = useSelector((state: any) => {
    const user = state.user;
    return {
      userInfo: user ? JSON.parse(user.user) : null,
      userNotifications: user && user.userNotifications ? user.userNotifications : new Set(),
    };
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => { setAnchorEl(event.currentTarget); };
  const handleClose = () => { setAnchorEl(null); };
  const open = Boolean(anchorEl);
  const id = open ? 'notifications-popover' : undefined;

  // const handleRemoveNotification = (notification: UserNotification) => {
  //   // const updatedNotifications = new Set(userNotifications);
  //   // updatedNotifications.delete(notification);
  //   dispatch(removeUserNotification(notification));
  // };

  return (
    <>
      {userInfo &&
        <>
          <Tooltip title="Notifications" placement="bottom">
            <IconButton
              color='inherit'
              tabIndex={0}
              sx={{ m: 0, borderRadius: '25%', padding: '6px', border: '1px solid', borderColor: 'inherit' }}
              onClick={handleClick}>
              <Badge color="secondary" badgeContent={userNotifications?.size || 0}>
                <Person2Icon />
              </Badge>
            </IconButton>
          </Tooltip>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            sx={{
              mt: 1,
            }}>
          </Popover>
        </>
      }
    </>
  );
}

export default AppScope;