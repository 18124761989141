import { createSlice } from '@reduxjs/toolkit';

const urlsOsintStorage = localStorage.getItem('urlsOsint');

const initialState = {
  urlsOsint: urlsOsintStorage ? JSON.parse(urlsOsintStorage) : null,
};

const urlsSlice = createSlice({
  name: 'UrlsOsintSlice',
  initialState,
  reducers: {
    setUrlsOsint: (state, action) => {
      state.urlsOsint = action.payload;
      localStorage.setItem('urlsOsint', JSON.stringify(action.payload));
    },
    reset: (state) => {
      state.urlsOsint = null;
      localStorage.clear();
    },
  },
});

export const {
  setUrlsOsint,
  reset,
} = urlsSlice.actions;

export default urlsSlice.reducer;