import { Badge, IconButton, Tooltip, Popover, List, ListItem, ListItemText, Button } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { UserNotification, NotificationTypes } from '../types';
import useAuthentication from '../auth/fbase';
import { Link as RouterLink } from 'react-router-dom';
import { removeUserNotification } from '../slices/userSlice';


function NotificationTrigger({ notification }: { notification: UserNotification }) {

  const [triggered, setTriggered] = useState(false);
  const { loading, verifyEmail, logout } = useAuthentication();
  // const navigate = useNavigate();

  const handleButtonClick = () => {
    // Handle button click based on notification type
    switch (notification.type) {
      case NotificationTypes.ANONYMOUS_ACCOUNT:
        //@audit alternative to async logout so we can redirect to sign up page
        logout();
        break;
      case NotificationTypes.EMAIL_NOT_VERIFIED:
        // Handle email not verified notification
        verifyEmail();
        setTriggered(true);
        break;
      case NotificationTypes.MFA_NOT_VERIFIED:
        // Handle MFA not verified notification
        break;
      case NotificationTypes.APP_LINK:
        // Handle MFA not verified notification
        setTriggered(true);
        break;
      default:
        // Handle other notification types
        break;
    }
  };

  return (
        <>
            {notification.type === NotificationTypes.ANONYMOUS_ACCOUNT && (
                <Button onClick={handleButtonClick}
                    size="small">Sign Up</Button>
            )}
            {notification.type === NotificationTypes.EMAIL_NOT_VERIFIED && (
                <Button
                    onClick={handleButtonClick}
                    disabled={triggered || loading}
                    size="small"
                >
                    Verify Email
                </Button>
            )}
            {notification.type === NotificationTypes.MFA_NOT_VERIFIED && (
                <Button onClick={handleButtonClick}>Verify MFA</Button>
            )}
            {notification.type === NotificationTypes.APP_LINK && notification.to && (
                // <RouterLink to={notification.to}>
                //     {notification.text || 'View'}
                // </RouterLink>
                <Button
                    component={RouterLink}
                    to={notification.to}
                    size="small"
                >
                    {notification.text || 'View'}
                </Button>

            )}
        </>
  );
}


function UserNotifications() {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();

  const { userInfo, userNotifications } = useSelector((state: any) => {
    const user = state.user;
    return {
      userInfo: user ? JSON.parse(user.user) : null,
      userNotifications: user && user.userNotifications ? user.userNotifications : new Set(),
    };
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => { setAnchorEl(event.currentTarget); };
  const handleClose = () => { setAnchorEl(null); };
  const open = Boolean(anchorEl);
  const id = open ? 'notifications-popover' : undefined;

  const handleRemoveNotification = (notification: UserNotification) => {
    // const updatedNotifications = new Set(userNotifications);
    // updatedNotifications.delete(notification);
    dispatch(removeUserNotification(notification));
  };

  return (
        <>
            {userInfo &&
                <>

                    <Tooltip title="Notifications" placement="bottom">
                        <IconButton
                            color='inherit'
                            tabIndex={0}
                            sx={{ m: 0, borderRadius: '25%', padding: '6px', border: '1px solid', borderColor: 'inherit' }}
                            onClick={handleClick}
                            disabled={userNotifications?.size === 0}>
                            <Badge color="secondary" badgeContent={userNotifications?.size || 0}>
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    {userNotifications?.size > 0 && <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        sx={{
                          mt: 1,
                        }}>

                        <List sx={
                            {
                              p: 0,
                              maxHeight: '50vh',
                              maxWidth: '400px',
                              overflow: 'auto',
                            }
                        }>
                            {userNotifications && Array.from<UserNotification>(userNotifications).map((notification: UserNotification) => (
                                <ListItem key={notification.message}>
                                    <ListItemText primary={notification.message} primaryTypographyProps={{ fontSize: '12px' }} />
                                    <NotificationTrigger notification={notification} />
                                    <IconButton size='small' onClick={() => handleRemoveNotification(notification)}>
                                        <ClearIcon fontSize="inherit" />
                                    </IconButton>
                                </ListItem>
                            ))}
                        </List>
                    </Popover>
                    }
                </>
            }
        </>
  );
}

export default UserNotifications;