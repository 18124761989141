import { useState } from 'react';
import { IconButton, InputBase, Paper } from '@mui/material';
import doublecheckimage from '../assets/images/doublecheck.svg';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DomainIcon from '@mui/icons-material/Domain';
import UserCompleteSummary from '../components/UserCompleteSummary';
import { useLocation } from 'react-router-dom';

export default function OpenVerification() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryType = queryParams.get('type');
  const queryValue = queryParams.get('value');

  const [searchType, setSearchType] = useState((queryType === 'domain' || queryType === 'email') ? queryType : 'email');
  const [currentValue, setCurrentValue] = useState(queryValue || '');
  const [searchValue, setSearchValue] = useState('');

  const handleSearchTypeChange = (type: string) => {
    setSearchType(type);
  };

  const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCurrentValue(event.target.value);
  };

  const handleSearch = (event: any) => {
    event.preventDefault();
    setSearchValue(currentValue);
    setCurrentValue('');
  };

  return (
        <>
            <img src={doublecheckimage} alt="double check" style={{ height: '100px', marginBottom: '10px' }} />
            <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 450, maxWidth: 650, margin: '18px auto', mb: 12 }}
            >
                <IconButton
                    sx={{ p: '10px' }}
                    aria-label="menu"
                    onClick={() => handleSearchTypeChange('domain')}
                    color={searchType === 'domain' ? 'primary' : 'inherit'}>
                    <DomainIcon />
                </IconButton>
                <IconButton
                    sx={{ p: '10px' }}
                    aria-label="menu"
                    onClick={() => handleSearchTypeChange('email')}
                    color={searchType === 'email' ? 'primary' : 'inherit'}>
                    <AccountCircleIcon />
                </IconButton>
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={searchType === 'domain' ?
                      'Search published data from Domains' : 'Search published data from Users'}
                    defaultValue={queryValue || ''}
                    inputProps={{ 'aria-label': 'search published data' }}
                    onChange={handleSearchValueChange}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        handleSearch(event);
                      }
                    }}
                />
                <IconButton type="button"
                    color={currentValue ? 'primary' : 'inherit'}
                    disabled={!currentValue}
                    sx={{ p: '10px' }} aria-label="search" onClick={handleSearch}>
                    <SearchIcon />
                </IconButton>
            </Paper>
            {searchValue && searchType === 'email' &&
                <UserCompleteSummary userEmail={searchValue} isWriting={!!currentValue} />}
        </>
  );
}
