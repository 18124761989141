import React, { useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Chip, Divider, Stack } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import PublicIcon from '@mui/icons-material/Public';
import { IPolicy } from '../types';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  data: IPolicy[];
}

const PoliciesList: React.FC<Props> = ({ data }) => {
  const [expanded, setExpanded] = useState<string | boolean>(false);

  const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
          setExpanded(isExpanded ? panel : false);
        };

  return (

        <div>
            {data.map((policy) => (
                <Accordion
                    key={policy._id}
                    expanded={expanded === policy._id}
                    onChange={handleChange(policy._id)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={'panel' + policy._id + 'bh-content'}
                        id={'panel' + policy._id + 'bh-header'}
                    >
                        <Typography textAlign="left" sx={{ width: '25%', flexShrink: 0 }}>
                            {policy.title}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{policy.short}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box mt={2} mb={2}>
                            <Typography textAlign="left" m="2">
                                Description: {policy.description}
                            </Typography>
                        </Box>
                        <Divider />
                        {
                            policy.circuit_type &&
                            <Box mt={2} mb={2}>
                                <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                                    <Typography textAlign="left" mt="5">
                                        Proof Type:
                                    </Typography>
                                    <Chip
                                        key={policy.circuit_type}
                                        label={policy.circuit_type}
                                        size="small" />
                                </Stack>
                            </Box>
                        }
                        <Divider />
                        {
                            policy.keys.length > 0 &&
                            <Stack
                                mt={2}
                                mb={2}
                                spacing={2}
                                direction="row"
                                sx={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}
                            >

                                <Typography textAlign="left">
                                    Keys:
                                </Typography>
                                <Box sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  alignItems: 'flex-start', // Add this property to align the elements to the left
                                  flexWrap: 'wrap',
                                }}>
                                    {
                                        policy.keys.map((key, index) => (
                                            <Chip
                                                key={index}
                                                label={key.name}
                                                size="small"
                                                sx={{ m: '4px' }}
                                                icon={key.visibility === 'public' ? <PublicIcon /> : <LockIcon />}
                                            />
                                        ))}
                                </Box>
                            </Stack>
                        }
                        <Box>
                            <Stack direction="row" spacing={1} justifyContent="flex-end"
                                sx={{ marginTop: 2 }}>
                                <Button variant="contained" component={RouterLink}
                                    to={'/prove/' + policy._id} endIcon={<AddCircleOutlinedIcon />}>
                                    Create Proof
                                </Button>
                            </Stack>
                        </Box>
                    </AccordionDetails>
                </Accordion>

            ))}
        </div>

  );
};

export default PoliciesList;
