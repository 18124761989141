import { Stack, Typography, IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { dateAndHour } from '../../utils/dates';

interface CellHeaderProps {
  domain: string;
  lastUpdated: string;
  registered: string;
  countryCode: string;
  languageCode: string;
}

export default function CellHeader({ domain, lastUpdated, registered }: CellHeaderProps) {
  const lastUpdatedDate = new Date(lastUpdated);

  return (
    <Stack spacing={1} direction="column">
      <Stack direction="row" spacing={0.5} alignItems={'center'}>
        <Typography 
        variant="body1"
        fontWeight="bold"
        >{domain}</Typography>
        <IconButton  
        onClick={() => window.open(`http://${domain}`, '_blank')}>
          <OpenInNewIcon sx={{ 
            width: '16px', 
          }}/>
        </IconButton>
      </Stack>
      <Typography variant="body2">Last updated: {dateAndHour(lastUpdatedDate)}</Typography>
      <Typography variant="body2">Registered: {registered}</Typography>
    </Stack>
  );
}