export interface EmailAnalysis {
  _id: string;
  email: string;
  analysis: {
    message: string;
    success: boolean;
    valid: boolean;
    disposable: boolean;
    smtp_score: number;
    overall_score: number;
    first_name: string;
    generic: boolean;
    common: boolean;
    dns_valid: boolean;
    honeypot: boolean;
    deliverability: string;
    frequent_complainer: boolean;
    spam_trap_score: string;
    catch_all: boolean;
    timed_out: boolean;
    suspect: boolean;
    recent_abuse: boolean;
    fraud_score: number;
    suggested_domain: string;
    leaked: boolean;
    domain_age: {
      human: string;
      timestamp: number;
      iso: string;
    };
    first_seen: {
      human: string;
      timestamp: number;
      iso: string;
    };
    domain_trust: string;
    sanitized_email: string;
    domain_velocity: string;
    user_activity: string;
    associated_names: {
      status: string;
      names: string[];
    };
    associated_phone_numbers: {
      status: string;
      phone_numbers: string[];
    };
    risky_tld: boolean;
    spf_record: boolean;
    dmarc_record: boolean;
    mx_records: string[];
    request_id: string;
  };
  createdAt: string;
  updatedAt: string;
}

export enum EmailAnalysisLevel {
  INFO = 0,
  WARNING = 1,
  DANGER = 2,
}

export const emailAnalysisDictionary = {
  email: {
    label: 'Email',
    type: 'string',
    description: 'The email address',
  },
  analysis: {
    message: {
      label: 'Message',
      type: 'string',
      description: 'The message from the analysis',
      message: 'The message from the analysis',
      level: EmailAnalysisLevel.INFO,
    },
    success: {
      label: 'Success',
      type: 'boolean',
      description: 'Whether the analysis was successful',
      message: 'Whether the analysis was successful',
      level: EmailAnalysisLevel.INFO,
    },
    valid: {
      label: 'Valid',
      type: 'boolean',
      description: 'Whether the email is valid',
      message: 'Does this email address appear valid?',
      level: EmailAnalysisLevel.DANGER,
    },
    disposable: {
      label: 'Disposable',
      type: 'boolean',
      description: 'Whether the email is disposable',
      message: 'Is this email suspected of belonging to a temporary or disposable mail service? Usually associated with fraudsters and scammers.',
      level: EmailAnalysisLevel.DANGER,
    },
    smtp_score: {
      label: 'SMTP Score',
      type: 'number',
      description: 'The SMTP score of the email',
      message: 'Validity score of email server\'s SMTP setup. Range: "-1" - "3". Scores above "-1" can be associated with a valid email.',
      level: EmailAnalysisLevel.INFO,
    },
    overall_score: {
      label: 'Overall Score',
      type: 'number',
      description: 'The overall score of the email',
      message: 'Overall email validity score. Range: "0" - "4". Scores above "1" can be associated with a valid email.',
      level: EmailAnalysisLevel.INFO,
    },
    first_name: {
      label: 'First Name',
      type: 'string',
      description: 'The first name of the email',
      message: 'Suspected first name based on email. Returns "CORPORATE" if the email is suspected of being a generic company email. Returns "UNKNOWN" if the first name was not determinable.',
      level: EmailAnalysisLevel.INFO,
    },
    generic: {
      label: 'Generic',
      type: 'boolean',
      description: 'Whether the email is generic',
      message: 'Is this email suspected as being a catch all or shared email for a domain?',
      level: EmailAnalysisLevel.WARNING,
    },
    common: {
      label: 'Common',
      type: 'boolean',
      description: 'Whether the email is common',
      message: 'Is this email from common free email providers? ("gmail.com", "yahoo.com", "hotmail.com", etc.)',
      level: EmailAnalysisLevel.INFO,
    },
    dns_valid: {
      label: 'DNS Valid',
      type: 'boolean',
      description: 'Whether the DNS is valid',
      message: 'Does the email\'s hostname have valid DNS entries? Partial indication of a valid email.',
      level: EmailAnalysisLevel.WARNING,
    },
    honeypot: {
      label: 'Honeypot',
      type: 'boolean',
      description: 'Whether the email is a honeypot',
      message: 'Is this email believed to be a "honeypot" or "SPAM trap"? Bulk mail sent to these emails increases your risk of being blacklisted by large ISPs & ending up in the spam folder.',
      level: EmailAnalysisLevel.INFO,
    },
    deliverability: {
      label: 'Deliverability',
      type: 'string',
      description: 'The deliverability of the email',
      message: 'How likely is this email to be delivered to the user and land in their mailbox. Values can be "high", "medium", or "low".',
      level: EmailAnalysisLevel.INFO,
    },
    frequent_complainer: {
      label: 'Frequent Complainer',
      type: 'boolean',
      description: 'Whether the email is a frequent complainer',
      message: 'Indicates if this email frequently unsubscribes from marketing lists or reports spam complaints.',
      level: EmailAnalysisLevel.INFO,
    },
    spam_trap_score: {
      label: 'Spam Trap Score',
      type: 'string',
      description: 'The spam trap score of the email',
      message: 'Intelligent confidence level of the email address being an active SPAM trap. Values can be "high", "medium", "low", or "none". We recommend scrubbing emails with a "high" status, typically for any promotional mailings. This data is meant to provide a more accurate result for the "frequent_complainer" and "honeypot" data points, which collect data from spam complaints, spam traps, and similar techniques.',
      level: EmailAnalysisLevel.INFO,
    },
    catch_all: {
      label: 'Catch All',
      type: 'boolean',
      description: 'Whether the email is a catch all',
      message: 'Is this email likely to be a "catch all" where the mail server verifies all emails tested against it as valid? It is difficult to determine if the address is truly valid in these scenarios, since the email\'s server will not confirm the account\'s status.',
      level: EmailAnalysisLevel.WARNING,
    },
    timed_out: {
      label: 'Timed Out',
      type: 'boolean',
      description: 'Whether the email timed out',
      message: 'Did the email verification connection to the mail service provider timeout during the verification? If so, we recommend increasing the "timeout" variable above the default 7 second value so more time can be spent during the mailbox verification request to mail servers. Lookups that timeout with a "valid" result as false are most likely false and should be not be trusted.',
      level: EmailAnalysisLevel.WARNING,
    },
    suspect: {
      label: 'Suspect',
      type: 'boolean',
      description: 'Whether the email is suspect',
      message: 'This value indicates if the mail server is currently replying with a temporary mail server error or if the email verification system is unable to verify the email address due to a broken SMTP handshake. This status will also be true for "catch all" email addresses as defined below. If this value is true, then we suspect the "valid" result may be tainted and there is not a guarantee that the email address is truly valid. This status is rarely true for popular mailbox providers and typically only returns as true for a small percentage of business mail servers.',
      level: EmailAnalysisLevel.DANGER,
    },
    recent_abuse: {
      label: 'Recent Abuse',
      type: 'boolean',
      description: 'Whether the email has recent abuse',
      message: 'This value will indicate if there has been any recently verified abuse across our network for this email address. Abuse could be a confirmed chargeback, fake signup, compromised device, fake app install, or similar malicious behavior within the past few days.',
      level: EmailAnalysisLevel.DANGER,
    },
    fraud_score: {
      label: 'Fraud Score',
      type: 'number',
      description: 'The fraud score of the email',
      message: 'The overall Fraud Score of the user based on the email\'s reputation and recent behavior across the IPQS threat network. Fraud Scores >= 75 are suspicious, but not necessarily fraudulent.',
      level: EmailAnalysisLevel.INFO,
    },
    suggested_domain: {
      label: 'Suggested Domain',
      type: 'string',
      description: 'The suggested domain of the email',
      message: 'Default value is "N/A". Indicates if this email\'s domain should in fact be corrected to a popular mail service. This field is useful for catching user typos. For example, an email address with "gmai.com", would display a suggested domain of "gmail.com". This feature supports all major mail service providers.',
      level: EmailAnalysisLevel.INFO,
    },
    leaked: {
      label: 'Leaked',
      type: 'boolean',
      description: 'Whether the email has been leaked',
      message: 'Was this email address associated with a recent database leak from a third party? Leaked accounts pose a risk as they may have become compromised during a database breach.',
      level: EmailAnalysisLevel.WARNING,
    },
    domain_age: {
      human: {
        label: 'Domain Age',
        type: 'string',
        description: 'The age of the domain',
        message: 'The age of the domain in human readable format. For example: "5 years, 3 months".',
        level: EmailAnalysisLevel.INFO,
      },
      timestamp: {
        label: 'Domain Age Timestamp',
        type: 'number',
        description: 'The timestamp of the domain age',
        message: 'The age of the domain in seconds since registration. Value will be "0" if unavailable.',
        level: EmailAnalysisLevel.INFO,
      },
      iso: {
        label: 'Domain Age ISO',
        type: 'string',
        description: 'The ISO of the domain age',
        message: 'The age of the domain in ISO 8601 format. For example: "P5Y3M".',
        level: EmailAnalysisLevel.INFO,
      },
    },
    first_seen: {
      human: {
        label: 'First Seen',
        type: 'string',
        description: 'The first seen of the email',
        message: 'A human description of when this domain was registered. (Ex: 3 months ago)',
        level: EmailAnalysisLevel.INFO,
      },
      timestamp: {
        label: 'First Seen Timestamp',
        type: 'number',
        description: 'The timestamp of the first seen',
        message: 'The unix time since epoch when this domain was first registered',
        level: EmailAnalysisLevel.INFO,
      },
      iso: {
        label: 'First Seen ISO',
        type: 'string',
        description: 'The ISO of the first seen',
        message: 'The time this domain was registered in ISO8601 format',
        level: EmailAnalysisLevel.INFO,
      },
    },
    domain_trust: {
      label: 'Domain Trust',
      type: 'string',
      description: 'The trust of the domain',
      message: 'Risk classification of the email\'s domain based on past abuse issues and positive behavior signals. Values include: "trusted", "positive", "neutral", "suspicious", "malicious", or "not rated".',
      level: EmailAnalysisLevel.INFO,
    },
    sanitized_email: {
      label: 'Sanitized Email',
      type: 'string',
      description: 'The sanitized email',
      message: 'Sanitized email address with all aliases and masking removed, such as multiple periods for Gmail.com.',
      level: EmailAnalysisLevel.INFO,
    },
    domain_velocity: {
      label: 'Domain Velocity',
      type: 'string',
      description: 'The domain velocity',
      message: 'Indicates the level of legitimate users interacting with the email address domain. Values can be "high", "medium", "low", or "none". Domains like "IBM.com", "Microsoft.com", "Gmail.com", etc. will have "high" scores as this value represents popular domains. New domains or domains that are not frequently visited by legitimate users will have a value as "none". This field is restricted to upgraded plans.',
      level: EmailAnalysisLevel.INFO,
    },
    user_activity: {
      label: 'User Activity',
      type: 'string',
      description: 'The user activity',
      message: 'Frequency at which this email address makes legitimate purchases, account registrations, and engages in legitimate user behavior online. Values can be "high", "medium", "low", or "none". Values of "high" or "medium" are strong signals of healthy usage. New email addresses without a history of legitimate behavior will have a value as "none". This field is restricted to higher plan tiers.',
      level: EmailAnalysisLevel.INFO,
    },
    associated_names: {
      status: {
        label: 'Associated Names Status',
        type: 'string',
        description: 'The associated names status',
        message: 'Status of the associated names data point. Values can be "high", "medium", "low", or "none". This field is restricted to higher plan tiers.',
        level: EmailAnalysisLevel.INFO,
      },
      names: {
        label: 'Associated Names',
        type: 'string',
        description: 'The associated names',
        message: 'Displays first and last names linked to the email address, if available in our data sources. Match rates vary by country. This field is restricted to upgraded plans. Object value contains, "status", and "names" as an array.',
        level: EmailAnalysisLevel.INFO,
      },
    },
    associated_phone_numbers: {
      status: {
        label: 'Associated Phone Numbers Status',
        type: 'string',
        description: 'The associated phone numbers status',
        message: 'Status of the associated phone numbers data point. Values can be "high", "medium", "low", or "none". This field is restricted to higher plan tiers.',
        level: EmailAnalysisLevel.INFO,
      },
      phone_numbers: {
        label: 'Associated Phone Numbers',
        type: 'string',
        description: 'The associated phone numbers',
        message: 'Displays phone numbers linked to the email address, if available in our data sources. Match rates vary by country. This field is restricted to upgraded plans. Object value contains, "status", and "phone_numbers" as an array.',
        level: EmailAnalysisLevel.INFO,
      },
    },
    risky_tld: {
      label: 'Risky TLD',
      type: 'boolean',
      description: 'Whether the TLD is risky',
      message: 'Signals that the email belongs to a risky TLD extension frequently associated with malware, scams, or phishing.',
      level: EmailAnalysisLevel.WARNING,
    },
    spf_record: {
      label: 'No SPF Record',
      type: 'boolean',
      description: 'Whether the domain has an SPF record',
      message: 'Confirms if the domain has a proper SPF DNS record.',
      level: EmailAnalysisLevel.WARNING,
    },
    dmarc_record: {
      label: 'No DMARC Record',
      type: 'boolean',
      description: 'Whether the domain has a DMARC record',
      message: 'Confirms if the domain has a proper DMARC DNS record.',
      level: EmailAnalysisLevel.WARNING,
    },
    mx_records: {
      label: 'MX Records',
      type: 'string',
      description: 'The MX records of the domain',
      message: 'List of MX records associated with the email\'s domain name.',
      level: EmailAnalysisLevel.INFO,
    },
    request_id: {
      label: 'Request ID',
      type: 'string',
      description: 'The request ID of the email',
      message: 'Unique identifier for the email scan request. Use this value to reference the scan in the future.',
      level: EmailAnalysisLevel.INFO,
    },
  },
};

export interface EmailAnalysisDictionaryElement {
  label: string;
  type: string;
  description: string;
  message?: string;
  level?: EmailAnalysisLevel;
}

export function retrieveIOCs(emailAnalysis: EmailAnalysis): EmailAnalysisDictionaryElement[] {
  const iocs:EmailAnalysisDictionaryElement[] = [];
  
  if (emailAnalysis.analysis.honeypot) { iocs.push(emailAnalysisDictionary.analysis.honeypot); }
  if (emailAnalysis.analysis.disposable) { iocs.push(emailAnalysisDictionary.analysis.disposable); }
  if (emailAnalysis.analysis.generic) { iocs.push(emailAnalysisDictionary.analysis.generic); }
  if (emailAnalysis.analysis.common) { iocs.push(emailAnalysisDictionary.analysis.common); }
  if (emailAnalysis.analysis.frequent_complainer) { iocs.push(emailAnalysisDictionary.analysis.frequent_complainer); }
  if (emailAnalysis.analysis.catch_all) { iocs.push(emailAnalysisDictionary.analysis.catch_all); }
  if (emailAnalysis.analysis.timed_out) { iocs.push(emailAnalysisDictionary.analysis.timed_out); }
  if (emailAnalysis.analysis.suspect) { iocs.push(emailAnalysisDictionary.analysis.suspect); }
  if (emailAnalysis.analysis.recent_abuse) { iocs.push(emailAnalysisDictionary.analysis.recent_abuse); }
  if (emailAnalysis.analysis.leaked) { iocs.push(emailAnalysisDictionary.analysis.leaked); }

  return iocs;
}