import { UserURL } from '../constants';
import { apiSlice } from './apiSlice';

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: UserURL + '/auth',
        method: 'POST',
        body: credentials,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: UserURL + '/logout',
        method: 'POST',
      }),
    }),
    getUserProfile: builder.query({
      query: () => ({
        url: UserURL + '/profile',
        method: 'GET',
      }),
      // keepUnusedDataFor: 5,
    }),
    putUserProfile: builder.mutation({
      query: () => ({
        url: UserURL + '/profile',
        method: 'PUT',
      }),
    }),
    status: builder.query({
      query: () => ({
        url: UserURL + '/status',
        method: 'GET',
      }),
    }),
    register: builder.mutation({
      query: (displayName) => ({
        url: UserURL,
        method: 'POST',
        body: displayName,
      }),
    }),

  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useGetUserProfileQuery,
  usePutUserProfileMutation,
  useStatusQuery,
} = usersApiSlice;
