import { DomainOsintURL } from '../constants';
import { apiSlice } from './apiSlice';

export const domainsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    postNewDomainAnalysis: builder.mutation({
      query: (domain: string) => ({
        url: `${DomainOsintURL}/${domain}`,
        method: 'POST',
      }),
    }),
    getDomainAnalysis: builder.query({
      query: (domain: string) => ({
        url: `${DomainOsintURL}/${domain}`,
        method: 'GET',
      }),
    }),
    refreshDomainAnalysis: builder.mutation({
      query: (domain: string) => ({
        url: `${DomainOsintURL}/${domain}`,
        method: 'PUT',
      }),
    }),
    deleteDomainAnalysis: builder.mutation({
      query: (domain: string) => ({
        url: `${DomainOsintURL}/${domain}`,
        method: 'DELETE',
      }),
    }),
    postAllDomains: builder.mutation({
      query: (domains: string[]) => ({
        url: `${DomainOsintURL}/all`,
        method: 'POST',
        body: { domains },
      }),
    }),
    getAllDomains: builder.query({
      query: () => ({
        url: `${DomainOsintURL}/all`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  usePostNewDomainAnalysisMutation,
  useGetDomainAnalysisQuery,
  useDeleteDomainAnalysisMutation,
  usePostAllDomainsMutation,
  useGetAllDomainsQuery,
  useRefreshDomainAnalysisMutation,
} = domainsApiSlice;