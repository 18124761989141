import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Alert, CircularProgress, Link, Stack } from '@mui/material';
import useAuthentication from '../auth/fbase';
import logo from '../assets/images/mzstratalogoc_opt.svg';
import { useRegisterMutation } from '../slices/usersApiSlice';

export default function SignUp() {

  const navigate = useNavigate();

  // const { userInfo } = useSelector((state: any) => state.user);
  const userInfo = useSelector((state: any) => {
    const user = state.user;
    if (user) {
      return JSON.parse(user.user);
    }
    return null;
  });
  const { loading, error, signup } = useAuthentication();
  const [registerBackend] = useRegisterMutation();

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get('redirect') || '/';

  React.useEffect(() => {
    console.log('redirecting...', userInfo);
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const displayName = data.get('displayName') as string;
      const email = data.get('email') as string;
      const password = data.get('password') as string;
      console.log('email', email, 'password', password);
      await signup(email, password);
      await registerBackend({ displayName });
    } catch (errorHS) {
      console.error(errorHS);
    }
  };

  return (
        <Stack
            direction="column"
            justifyContent='space-between'
            alignItems="flex-start"
        >
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              pl: 10,
              pt: 10,
            }}>
                <Link component={RouterLink} to="/" sx={{
                  height: 64,
                  width: 96,
                }}>
                    <img src={logo} alt="logo" height='100%' width='100%' />
                </Link>
            </Box>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                      marginTop: 8,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                >
                    <Stack direction="row" spacing={2} sx={{ mb: 2 }} width='100%'>
                        <Box sx={{
                          width: '100%',
                          p: 0,
                        }}>
                            <Typography variant="h4" textAlign='left' >
                                Register for an account.
                            </Typography>
                        </Box>
                        <Box sx={{
                          width: '100%',
                        }}>
                            {loading && <CircularProgress />}
                        </Box>
                    </Stack>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="displayName"
                            label="Name"
                            name="displayName"
                            autoComplete="name"
                            variant="standard"
                            InputLabelProps={{
                              shrink: true,
                              sx: {
                                fontSize: '1.2rem',
                              },
                            }}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            variant="standard"
                            InputLabelProps={{
                              shrink: true,
                              sx: {
                                fontSize: '1.2rem',
                              },
                            }}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            variant="standard"
                            InputLabelProps={{
                              shrink: true,
                              sx: {
                                fontSize: '1.2rem',
                              },
                            }}
                        />
                        {/* <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="I want to receive inspiration, marketing promotions and updates via email."
                            />
                        </Grid> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={loading}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign Up
                        </Button>
                    </Box>
                </Box>
            </Container>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              pl: 10,
              pt: 10,
            }}>
                <Typography variant="h6" textAlign='left' >
                    Already registered?
                    <Link component={RouterLink} to={redirect ?
                      `/sign-in?redirect=${redirect}` : '/sign-in'
                    }
                        color="inherit"
                        sx={{
                          ml: 1,
                          mr: 1,
                          textDecoration: 'none',
                          fontWeight: 'bold',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}
                    >
                        Sign in
                    </Link>
                    to your account now.
                </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              pl: 10,
              pt: 10,
            }}>
                {error &&
                    <Alert severity="error">
                        {error}
                    </Alert>}
            </Box>
        </Stack>
  );
}