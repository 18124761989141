export type Tag = {
  name: string,
  description: string,
  format: string,
  tag: string,
};

export type TagSystem = {
  type: string,
  tags: Tag[],
};

export const tagSystem: TagSystem[] = [
  {
    type: 'Identification',
    tags: [
      {
        name: 'Name',
        description: 'The name of the person',
        format: 'value',
        tag: 'identification-name',
      },
      {
        name: 'Surname',
        description: 'The surname of the person',
        format: 'value',
        tag: 'identification-surname',
      },
      {
        name: 'ID Number',
        description: 'The ID of the person',
        format: 'value',
        tag: 'identification-idNumber',
      },
      {
        name: 'Scan of ID',
        description: 'The scan of the ID of the person',
        format: 'file',
        tag: 'identification-scanOfId',
      },
    ],
  },
  {
    type: 'Financial',
    tags: [
      {
        name: 'Bank account number',
        description: 'The bank account number of the person',
        format: 'value',
        tag: 'financial-bankAccountNumber',
      },
      {
        name: 'Bank account balance',
        description: 'The bank account balance of the person',
        format: 'value',
        tag: 'financial-bankAccountBalance',
      },
      {
        name: 'Bank account statement',
        description: 'The bank account statement of the person',
        format: 'file',
        tag: 'financial-bankAccountStatement',
      },
      {
        name: 'Bank account transactions',
        description: 'The bank account transactions of the person',
        format: 'file',
        tag: 'financial-bankAccountTransactions',
      },
    ],
  },
  {
    type: 'Contacts',
    tags: [
      {
        name: 'Email',
        description: 'The email of the person',
        format: 'value',
        tag: 'contacts-email',
      },
      {
        name: 'Phone number',
        description: 'The phone number of the person',
        format: 'value',
        tag: 'contacts-phoneNumber',
      },
      {
        name: 'Address',
        description: 'The address of the person',
        format: 'value',
        tag: 'contacts-address',
      },
    ],
  },
  {
    type: 'Health',
    tags: [
      {
        name: 'Medical history',
        description: 'The medical history of the person',
        format: 'file',
        tag: 'health-medicalHistory',
      },
      {
        name: 'Medical prescriptions',
        description: 'The medical prescriptions of the person',
        format: 'file',
        tag: 'health-medicalPrescriptions',
      },
      {
        name: 'Medical appointments',
        description: 'The medical appointments of the person',
        format: 'file',
        tag: 'health-medicalAppointments',
      },
      {
        name: 'Medical tests',
        description: 'The medical tests of the person',
        format: 'file',
        tag: 'health-medicalTests',
      },
      {
        name: 'Medical images',
        description: 'The medical images of the person',
        format: 'file',
        tag: 'health-medicalImages',
      },
      {
        name: 'Medical scans',
        description: 'The medical scans of the person',
        format: 'file',
        tag: 'health-medicalScans',
      },
      {
        name: 'Medical reports',
        description: 'The medical reports of the person',
        format: 'file',
        tag: 'health-medicalReports',
      },
      {
        name: 'Medical results',
        description: 'The medical results of the person',
        format: 'file',
        tag: 'health-medicalResults',
      },
      {
        name: 'Medical prescriptions',
        description: 'The medical prescriptions of the person',
        format: 'file',
        tag: 'health-medicalPrescriptions',
      },
      {
        name: 'Medical appointments',
        description: 'The medical appointments of the person',
        format: 'file',
        tag: 'health-medicalAppointments',
      },
      {
        name: 'Medical tests',
        description: 'The medical tests of the person',
        format: 'file',
        tag: 'health-medicalTests',
      },
      {
        name: 'Medical images',
        description: 'The medical images of the person',
        format: 'file',
        tag: 'health-medicalImages',
      },
      {
        name: 'Medical scans',
        description: 'The medical scans of the person',
        format: 'file',
        tag: 'health-medicalScans',
      },
    ],
  },
  {
    type: 'Biometric',
    tags: [
      {
        name: 'Fingerprint',
        description: 'The fingerprint of the person',
        format: 'file',
        tag: 'biometric-fingerprint',
      },
      {
        name: 'Iris scan',
        description: 'The iris scan of the person',
        format: 'file',
        tag: 'biometric-irisScan',
      },
      {
        name: 'Facial recognition',
        description: 'The facial recognition of the person',
        format: 'file',
        tag: 'biometric-facialRecognition',
      },
      {
        name: 'DNA',
        description: 'The DNA of the person',
        format: 'file',
        tag: 'biometric-dna',
      },
      {
        name: 'Voice recognition',
        description: 'The voice recognition of the person',
        format: 'file',
        tag: 'biometric-voiceRecognition',
      },
      {
        name: 'Handwriting',
        description: 'The handwriting of the person',
        format: 'file',
        tag: 'biometric-handwriting',
      },
      {
        name: 'Signature',
        description: 'The signature of the person',
        format: 'file',
        tag: 'biometric-signature',
      },
      {
        name: 'Keystroke',
        description: 'The keystroke of the person',
        format: 'file',
        tag: 'biometric-keystroke',
      },
      {
        name: 'Gait',
        description: 'The gait of the person',
        format: 'file',
        tag: 'biometric-gait',
      },
      {
        name: 'Ear shape',
        description: 'The ear shape of the person',
        format: 'file',
        tag: 'biometric-earShape',
      },
    ],
  },
];
