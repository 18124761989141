import { Button, Paper, Stack, Typography } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import QuizIcon from '@mui/icons-material/Quiz';
import { useSelector } from 'react-redux';
import {
  useGetUserProfileQuery,
  useStatusQuery,
} from '../slices/usersApiSlice';
import {
  setUnhandledReports,
  setUserReports,
  setUserProofs,
} from '../slices/userSlice';
import {
  setTotalVerifications,
  setTotalReports,
  setTotalProofs,
  setTotalFailedVerifications,
} from '../slices/dashboardSlice';
import {
  setUserMerkle,
} from '../slices/merkleSlice';
import { setUserProfile } from '../slices/profileSlice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { dateAndHour } from '../utils/dates';

// import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

export default function HomeScreen() {

  const dispatch = useDispatch();
  const totalProofs = useSelector((state: any) => state.dashboard.totalProofs);
  const totalVerifications = useSelector((state: any) => state.dashboard.totalVerifications);
  const failedVerifications = useSelector((state: any) => state.dashboard.totalFailedVerifications);
  const reportedVerifications = useSelector((state: any) => state.dashboard.totalReports);

  const processProofsAndReports = (userStatus: any) => {
    console.log(userStatus);
    const proofs = userStatus.proofs;
    const reports = userStatus.reports;
    const merkle = userStatus.data;
    const total = proofs.length;
    let totalNumberOfVerifications = 0;
    let totalNumberOfFailedVerifications = 0;
    let totalNumberOfReportedVerifications = 0;
    let unhandledReports = 0;

    reports.forEach((report: any) => {
      totalNumberOfVerifications += report.validVerifications + report.invalidVerifications;
      totalNumberOfFailedVerifications += report.invalidVerifications;
      totalNumberOfReportedVerifications += report.reports.length;
      report.reports.forEach((reported: any) => {
        if (!reported.handled) {
          unhandledReports++;
        }
      });
    });

    dispatch(setTotalProofs(total));
    dispatch(setTotalFailedVerifications(totalNumberOfFailedVerifications));
    dispatch(setTotalVerifications(totalNumberOfVerifications));
    dispatch(setTotalReports(totalNumberOfReportedVerifications));
    dispatch(setUnhandledReports(unhandledReports));
    dispatch(setUserReports(reports));
    dispatch(setUserProofs(proofs));
    dispatch(setUserMerkle(JSON.stringify(merkle)));
  };


  const userInfo = useSelector((state: any) => {
    const user = state.user;
    if (user) {
      return JSON.parse(user.user);
    }
    return null;
  });

  const profile = useSelector((state: any) => {
    const prof = state.profile;
    if (prof) {
      return JSON.parse(prof.userProfile);
    }
    return null;
  });

  const { data: userProfile } = useGetUserProfileQuery({});
  const { data: userStatus } = useStatusQuery({});

  useEffect(() => {
    if (userStatus) {
      processProofsAndReports(userStatus);
    }
    if (userProfile) {
      dispatch(setUserProfile(JSON.stringify(userProfile)));
    }
  }, [userStatus, userProfile]);

  return (
        <>
            <Stack
                spacing={4}>
                <Stack
                    spacing={2}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'left',
                    }}
                >
                    <Typography variant="h5" >
                        {'Welcome, '}
                        {profile ? profile.user.displayName : 'Anon'}
                    </Typography>
                    <Typography variant="body1" >
                        Start committing your data publicly and prove your identity
                    </Typography>
                </Stack>
                <Stack spacing={4} >
                    <Stack direction="row" spacing={4}>
                        <Paper sx={{ p: 3, width: '100%', textAlign: 'center' }} elevation={3}>
                            <Stack spacing={1} >
                                <Typography variant="body2" fontWeight="bold">
                                    Total number of verifications
                                </Typography>
                                <Typography variant="body1" fontSize='20px'>
                                    {totalVerifications || '--'}
                                </Typography>
                            </Stack>
                        </Paper>
                        <Paper sx={{ p: 3, width: '100%', textAlign: 'center', border: '2px solid', borderColor: 'primary.light' }} elevation={0}>
                            <Stack spacing={1} >
                                <Typography variant="body2" fontWeight="bold">
                                    Last checked in at
                                </Typography>
                                <Typography variant="body1" fontSize='20px'>
                                    {userInfo && !userInfo.isAnonymous ? dateAndHour(new Date(parseInt(userInfo.lastLoginAt))) : '--:--:--'}
                                </Typography>
                            </Stack>
                        </Paper>
                    </Stack>
                    <Stack direction="row" spacing={4}>
                        <Paper elevation={0} sx={{ p: 3, width: '100%', textAlign: 'center', border: '2px solid', borderColor: 'primary.dark' }}>
                            <Stack spacing={1} >
                                <Typography variant="body2" fontWeight="bold">
                                    Number of published policies
                                </Typography>
                                <Typography variant="body1" fontSize='20px'>
                                    {totalProofs || '--'}
                                </Typography>
                            </Stack>
                        </Paper>
                        <Paper sx={{ p: 3, width: '100%', textAlign: 'center', backgroundColor: 'primary.main' }} elevation={3}>
                            <Stack spacing={1} >
                                <Typography variant="body2" fontWeight="bold">
                                    Number of failed verifications (reported / total)
                                </Typography>
                                <Typography variant="body1" fontSize='20px'>
                                    {reportedVerifications || '--'} / {failedVerifications || '--'}
                                </Typography>
                            </Stack>
                        </Paper>
                    </Stack>
                </Stack>
                <Stack
                    spacing={2}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'left',
                    }}
                >
                    <Typography variant="h6" >
                        Tips & Tricks
                    </Typography>
                    <Typography variant="body1" >
                        Here are some tips to help you get started
                    </Typography>
                </Stack>
                <Stack spacing={2}>
                    <Paper elevation={0} sx={{ p: 1, width: '100%', textAlign: 'center', backgroundColor: '#eeeeee' }}>
                        <Stack direction="row" spacing={2} justifyContent="space-between"  >
                            <QuizIcon sx={{ fontSize: 25 }} />
                            <Stack spacing={1} width='100%'>
                                <Typography variant="body2" fontWeight="bold" textAlign="left" >
                                    FAQ: How does Strata Protection work?
                                </Typography>
                                <Typography variant="body1" textAlign="left">
                                    In this FAQ, you will learn how Strata Protection works and how it can help you.
                                </Typography>
                            </Stack>
                            <Button
                                variant="text"
                                size="small"
                                color="inherit"
                                sx={{
                                  width: '150px',
                                }}
                            >Learn more</Button>
                        </Stack>
                    </Paper>
                    <Paper elevation={0} sx={{ p: 1, width: '100%', textAlign: 'center', backgroundColor: '#eeeeee' }}>
                        <Stack direction="row" spacing={2} justifyContent="space-between"  >
                            <SchoolIcon sx={{ fontSize: 25 }} />
                            <Stack spacing={1} width='100%'>
                                <Typography variant="body2" fontWeight="bold" textAlign="left" >
                                    Tutorial: Share data securely
                                </Typography>
                                <Typography variant="body1" textAlign="left">
                                    In this tutorial, you will learn how to securely publish your data to the world.
                                </Typography>
                            </Stack>
                            <Button
                                variant="text"
                                size="small"
                                color="inherit"
                                sx={{
                                  width: '150px',
                                }}
                            >Get started</Button>
                        </Stack>
                    </Paper>
                    <Paper elevation={0} sx={{ p: 1, width: '100%', textAlign: 'center', backgroundColor: '#eeeeee' }}>
                        <Stack direction="row" spacing={2} justifyContent="space-between"  >
                            <SchoolIcon sx={{ fontSize: 25 }} />
                            <Stack spacing={1} width='100%'>
                                <Typography variant="body2" fontWeight="bold" textAlign="left" >
                                    Tutorial: Install Office Add-In
                                </Typography>
                                <Typography variant="body1" textAlign="left">
                                    Learn how to install the Strata Protection Office Add-In and use it to verify data sent from your colleagues.
                                </Typography>
                            </Stack>
                            <Button
                                variant="text"
                                size="small"
                                color="inherit"
                                sx={{
                                  width: '150px',
                                }}
                            >Get started</Button>
                        </Stack>
                    </Paper>
                    <Paper elevation={0} sx={{ p: 1, width: '100%', textAlign: 'center', backgroundColor: '#eeeeee' }}>
                        <Stack direction="row" spacing={2} justifyContent="space-between"  >
                            <QuizIcon sx={{ fontSize: 25 }} />
                            <Stack spacing={1} width='100%'>
                                <Typography variant="body2" fontWeight="bold" textAlign="left" >
                                    FAQ: What are proofs? How do they protect me?
                                </Typography>
                                <Typography variant="body1" textAlign="left">
                                    Proofs are a way to prove that your data is authentic. Learn more about how they work and how they protect you.
                                </Typography>
                            </Stack>
                            <Button
                                variant="text"
                                size="small"
                                color="inherit"
                                sx={{
                                  width: '150px',
                                }}
                            >Learn more</Button>
                        </Stack>
                    </Paper>
                </Stack>
            </Stack>
        </>
  );
}