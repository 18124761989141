import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';
import { BaseURL } from '../constants';
import { auth } from '../auth/fbase';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL,
    prepareHeaders: async (headers, { }) => {
      const user = auth.currentUser;
      if (user) {
        // get access token from firebase
        const accessToken = await user.getIdToken();
        if (accessToken) {
          headers.set('Authorization', `Bearer ${accessToken}`);
        }
      } else
        console.log('no user in auth');
      return headers;
    },
    // credentials: "include"
  }),
  tagTypes: ['Circuits', 'Policies', 'Proofs', 'Users', 'Reports', 'Activity', 'Domains'],
  endpoints: () => ({
    // ...
  }),
});
