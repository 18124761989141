import { Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, TextField, Tooltip, Typography, styled } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SaveIcon from '@mui/icons-material/Save';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { useEffect, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { RootState } from '../store';
import { useGetAllHashesQuery, useMergeToAllHashesMutation, useRetrieveHashIndicatorsMutation, usePostFileBinaryMutation } from '../slices/filesApiSlice';
import { setFilesOsint } from '../slices/filesSlice';
import { FileAnalysis } from '../utils/fileType';
import FilesList from '../components/FilesList';
import Filter from '../components/email-utils/Filter';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function FilesOSINT() {
  const [open, setOpen] = useState(false);
  const [shouldSave, setShouldSave] = useState(false);
  const [filter, setFilter] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [fileHash, setFileHash] = useState('');
  const [file, setFile] = useState<any>(null);
  const [newHashIndicators] = useRetrieveHashIndicatorsMutation();
  const [mergeToAllHashes] = useMergeToAllHashesMutation();
  const [postFileBinary] = usePostFileBinaryMutation();
  const hashes = useSelector((state: RootState) => { return state.files.filesOsint || []; });

  const dispatch = useDispatch();
  const { data: hashesList, error: errorGetAllHashes } = useGetAllHashesQuery({});
  useEffect(() => {
    if (hashesList) {
      dispatch(setFilesOsint(hashesList));
    }
    if (errorGetAllHashes) {
      console.error('Failed to fetch hashes:', errorGetAllHashes);
    }
  }, [hashesList, errorGetAllHashes, dispatch]);

  const handleHashSubmit = async (event: any) => {
    event.preventDefault();
    console.log('Hash submitted:', fileHash);
    if (fileHash) {
      const response = await newHashIndicators(fileHash);
      if ('error' in response) {
        console.error('Failed to fetch hash indicators:', response.error);
      } else {
        const result: FileAnalysis = response.data;
        console.log('Hash result:', result);
        dispatch(setFilesOsint([result, ...hashes]));
        setShouldSave(true);
      }
    }
  };

  const handleFileHashSubmit = async (event: any) => {
    event.preventDefault();

    if (!file) {
      console.log('No file selected');
      return;
    }

    const reader = new FileReader();
    reader.onload = async (loadEvent: any) => {
      const arrayBuffer = loadEvent.target.result;
      const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      setFileHash(hashHex);
      console.log('File selected, hash calculated and submitted:', hashHex);
      // handle the file here, calculate the hash and submit
    };
    reader.readAsArrayBuffer(file);
  };

  const handleFileSubmit = async (event: any) => {
    event.preventDefault();
  
    if (!file) {
      console.log('No file selected');
      return;
    }
  
    const fileSizeInMB = file.size / (1024 * 1024);
    const maxFileSizeInMB = 15;
  
    if (fileSizeInMB > maxFileSizeInMB) {
      console.log(`File size exceeds the limit of ${maxFileSizeInMB}MB`);
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await postFileBinary(formData);
  
      console.log('File submitted:', response);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  function saveHashes(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();
    const hashesToMerge = hashes.map((hash: any) => hash.fileHash);
    // const emailsTest = ['asd@dsad.es'];
    mergeToAllHashes(hashesToMerge);
    setShouldSave(false);
  }

  const userInfo = useSelector((state: any) => {
    const user = state.user;
    if (user) {
      return JSON.parse(user.user);
    }
    return null;
  });

  function deleteHash(hash: string): void {
    dispatch(setFilesOsint(hashes.filter((d: any) => d.fileHash !== hash)));
    setShouldSave(true);
  }

  async function refreshHash(hash: string): Promise<void> {
    // try {
    //   const result: any = await refreshEmailAnalysis(email);
    //   const updatedEmails = emails.filter((d: any) => d.email !== email);

    //   dispatch(setEmailsOsint([result.data, ...updatedEmails]));
    // } catch (refreshError) {
    //   console.error('Failed to refresh email analysis:', refreshError);
    // }
    console.log('refreshing email:', hash);
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h5" textAlign='left' align="center">
        Files Open-Source Intelligence
      </Typography>
      {userInfo && !userInfo.isAnonymous &&
        <Stack spacing={2} direction='row' justifyContent='space-between'>
          <Stack spacing={2} direction='row' justifyContent='space-between'>
            <Stack spacing={2} direction='row'>
              <Filter applyFilter={setFilter} />
              <Typography variant="body2" fontWeight={800} textAlign='left' align="center">{10}</Typography>
            </Stack>
            <Stack spacing={2} direction='row'>
              <Tooltip title="Add Files" arrow>
                <IconButton aria-label="add-emails" onClick={handleOpen} color='primary'>
                  <NoteAddIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Save" arrow>
              <IconButton aria-label="save-emails" color='primary' onClick={saveHashes} disabled={!shouldSave}>
                <Badge color="secondary" variant="dot" invisible={!shouldSave}>
                  <SaveIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            </Stack>
          </Stack>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add file hash or submit file</DialogTitle>
            <DialogContent>
              <Stack spacing={2}>
                <TextField name="fileHash" label="fileHash" fullWidth multiline value={fileHash} onChange={(e) => setFileHash(e.target.value)} />
                <Button onClick={handleHashSubmit}>Submit Hash</Button>
                <Divider />
                <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon />}>
                  Select File
                  <VisuallyHiddenInput type="file" onChange={(e: any) => setFile(e.target.files[0])} />
                </Button>
                {file && <>
                  <Typography variant="body2" fontSize='small' textAlign='left' align="center">Selected file: {file.name}</Typography>
                  <Divider />
                  <Button onClick={handleFileHashSubmit}>Calculate hash (sha256)</Button>
                  <Button onClick={handleFileSubmit}>Submit file for analysis</Button>
                </>}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </Stack>
      }

      <FilesList 
          filter={filter}
          deleteHash={deleteHash}
          refreshHash={refreshHash}
        />
      {!userInfo && <Typography variant="body1" textAlign='left' align="center">This feature is exclusive for registered users</Typography>}
    </Stack>
  );
}