import { Chip, Tooltip } from '@mui/material';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { EmailAnalysisDictionaryElement, EmailAnalysisLevel } from '../../utils/emailTypes';

interface CellWarningProps {
  warnings: EmailAnalysisDictionaryElement[];
}

export default function CellWarning({ warnings }: CellWarningProps) {
  return (
    <>
      {warnings.map((warning, index) => {
        let Icon;
        let color;
        let textColor;

        switch (warning.level) {
          case EmailAnalysisLevel.DANGER:
            Icon = DangerousRoundedIcon;
            color = 'darkred';
            textColor = 'white';
            break;
          case EmailAnalysisLevel.WARNING:
            Icon = WarningRoundedIcon;
            color = '#ffe79a';
            textColor = 'black';
            break;
          case EmailAnalysisLevel.INFO:
            Icon = InfoRoundedIcon;
            color = 'lightblue';
            textColor = 'black';
            break;
          default:
            Icon = InfoRoundedIcon;
            color = 'lightblue';
            textColor = 'black';
            break;
        }

        return (
          <Tooltip key={index} title={warning.message || ''}>
            <Chip
              icon={Icon ? <Icon style={{ color: textColor }} /> : undefined}
              label={warning.label}
              style={{ backgroundColor: color, margin: '0.5rem', color: textColor }}
            />
          </Tooltip>
        );
      })}
    </>
  );
}