import React, { useState } from 'react';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import NotesIcon from '@mui/icons-material/Notes';
import { grey } from '@mui/material/colors';
import { IKey, IPolicy, IProof } from '../types';
import PreviewProof from './PreviewProof';
import {
  initCircuit,
  stringToIntegerHash,
  padHexString,
  verifyProof,
  hexStringToUint8Array,
} from '../utils/zkcircuits';
import { useGetCircuitByPolicyIdQuery } from '../slices/circuitApiSlice';
import ReportIcon from '@mui/icons-material/Report';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { useSelector } from 'react-redux';
import {
  usePostVerificationFailedMutation,
  usePostVerificationSuccessMutation,
  useSubmitReportMutation,
} from '../slices/reportsApiSlice';
import { dateAndHour } from '../utils/dates';
import { Link as RouterLink } from 'react-router-dom';
// import ReportHandler from './ReportHandler';

enum ProofStatus {
  Unverified,
  Verified,
  Error,
}

export default function PolicyValidatorForm({ proofData, policyData }: { proofData: IProof, policyData: IPolicy }) {
  const initialKeys: Record<string, string> = {};
  policyData.keys.forEach((key: IKey) => {
    if (key.visibility === 'public')
      initialKeys[key.var_name] = '';
  });

  const [keys, setKeys] = useState(initialKeys);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<ProofStatus>(ProofStatus.Unverified);
  const { data: circuitData } = useGetCircuitByPolicyIdQuery(policyData._id);
  const [postVerificationSuccess] = usePostVerificationSuccessMutation();
  const [postVerificationFailed] = usePostVerificationFailedMutation();
  const [submitReport, { isLoading }] = useSubmitReportMutation();
  const [reportText, setReportText] = useState(`Hi!

I've identified an issue with the verification of my data. Find below additional details that may help you solve this issue:

- Communication channel: [Identify how you came across the data received (e.g. email, website, SMS, etc.)]
- Data received: [Identify the data received]
- Occurrence date: [When did you received the data?]

Your prompt attention to this issue is greatly appreciated. Please let me know at [Your contact] if you need any additional information.

Best regards,
[Your Name]`);


  const userInfo = useSelector((state: any) => {
    const user = state.user;
    if (user) {
      return JSON.parse(user.user);
    }
    return null;
  });

  const [open, setOpen] = useState(false);
  const [openSnack, setOpenStack] = useState(false);

  const handleCloseSnack = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenStack(false);
  };

  const handleClickOpenReport = () => {
    setOpen(true);
  };

  const handleCloseReport = () => {
    setOpen(false);
  };

  const handleSubmitReport = async () => {
    //@audit need some validation here
    try {
      const res = await submitReport({
        refId: proofData._id as string,
        report: { message: reportText },
      });
      console.log(res);
      setOpenStack(true);
    } catch (error) {
      console.log(error);
    }
    setOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setKeys({ ...keys, [name]: value });
  };

  const validateProof = (e: React.FormEvent<HTMLFormElement>) => {
    // @audit current (potential) issues:
    // - Problems with field elements in the circuit -- probably cant use sha256
    // - No order is being respected in the keys
    e.preventDefault();
    setLoading(true);
    setStatus(ProofStatus.Unverified);
    console.log(circuitData);
    try {
      console.log('Performing action with keys:', keys);
      initCircuit(circuitData!.circuit.bytecode).then(async ({ api, acirComposer, acirBufferUncompressed }) => {
        const input: string[] = [];
        const promises: Promise<any>[] = [];
        Object.entries(keys).forEach(([, value]) => {
          const promise = stringToIntegerHash(value).then((digest) => {
            input.push(padHexString(digest, 32, false));
          });
          promises.push(promise);
        });

        await Promise.all(promises);
        input.push(proofData.proof);
        const proofValidation = input.join('');
        console.log('Proof validation:', proofValidation);

        const verificationResult = await verifyProof(hexStringToUint8Array(proofValidation), acirComposer, api, acirBufferUncompressed);
        console.log('Proof validation result:', verificationResult);
        if (verificationResult) {
          setStatus(ProofStatus.Verified);
          postVerificationSuccess(proofData._id as string);
        } else {
          setStatus(ProofStatus.Error);
          postVerificationFailed(proofData._id as string);
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
        <>
            <Card>
                <CardContent >
                    <Typography variant="body2" gutterBottom style={{ textAlign: 'right' }}
                        fontSize="small" color="text.secondary">
                        Policy Information
                    </Typography>
                    <Stack spacing={2} direction="row" sx={{ marginTop: 2 }}>
                        <Stack spacing={1}>
                            <Typography variant="h5"
                                fontWeight="bold">
                                {policyData.title}</Typography>
                            <Typography fontSize="small" color="text.secondary">by</Typography>
                            <Link
                                component={RouterLink}
                                to={`/show/user/${proofData.author}`}
                            >
                                <Typography variant="body1">{proofData.identity}</Typography>
                            </Link>
                        </Stack>
                        <Divider orientation="vertical" flexItem />
                        <Stack textAlign="left" spacing={1}>
                            <Stack direction="row" spacing={1}>
                                <Avatar sx={{ bgcolor: 'white', width: 24, height: 24 }} >
                                    <NotesIcon sx={{ color: grey[800] }} />
                                </Avatar>
                                <Typography variant="body1">{policyData.description}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Avatar sx={{ bgcolor: 'white', width: 24, height: 24 }} >
                                    <EventIcon sx={{ color: grey[800] }} />
                                </Avatar>
                                <Typography variant="body1">{dateAndHour(new Date(proofData.createdAt))}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <PreviewProof proofData={proofData.proof} />
                            </Stack>
                        </Stack>
                    </Stack>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body2" gutterBottom style={{ textAlign: 'right' }}
                        fontSize="small" color="text.secondary">
                        Validation Fields
                    </Typography>
                    <form onSubmit={validateProof}>

                        {policyData.keys.map((key: IKey) => (key.visibility === 'public' &&
                            <TextField
                                key={key.var_name}
                                name={key.var_name}
                                label={key.name}
                                helperText={key.help}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={keys[key.var_name]}
                                onChange={handleInputChange}
                            />
                        ))}

                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between"
                            sx={{ marginTop: 2 }}>
                            <Box>
                                {loading && <CircularProgress
                                    size={25}
                                />}
                                {status === ProofStatus.Verified &&
                                    <Stack direction='row' alignItems="center" spacing={1}>
                                        <ThumbUpIcon fontSize="large" sx={{ color: 'green' }} />
                                        <Typography variant="body1" fontWeight={400}>
                                            Proof has been correctly verified.
                                        </Typography>
                                    </Stack>
                                }
                                {status === ProofStatus.Error &&
                                    <Stack direction='row' alignItems="center" spacing={1}>
                                        <ReportIcon fontSize="large" sx={{ color: 'darkred' }} />
                                        <Typography variant="body1" fontWeight={600}>
                                            Proof verification failed!
                                        </Typography>
                                        {(userInfo && !userInfo.isAnonymous && userInfo.emailVerified) &&
                                            <Button
                                                variant="contained"
                                                onClick={handleClickOpenReport}
                                                color="secondary">
                                                Report
                                            </Button>
                                        }
                                        {(!userInfo || userInfo.isAnonymous) &&
                                            <Typography>
                                                {/* @audit: this should a router link */}
                                                <Link href="/sign-in">Login</Link> to report this occurrence.
                                            </Typography>
                                        }
                                        {(userInfo && !userInfo.emailVerified) &&
                                            <Typography>
                                                {/* @audit: this should a router link */}
                                                You need to verify your email to report.
                                            </Typography>
                                        }
                                    </Stack>
                                }
                            </Box>
                            <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                Validate Proof
                            </Button>
                        </Stack>
                    </form>
                </CardContent>
            </Card>

            {userInfo &&
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8}>
                        <Stack spacing={1}>
                            {/* {reports.reports.map((report: any) => (
                            <ReportHandler report={report} reportsID={reports._id} />
                        ))} */}
                        </Stack>
                    </Grid>
                </Grid>
            }

            <Dialog
                open={open}
                onClose={handleCloseReport}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>Report Failed Verification</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        On behalf of the community, we thank you for your contribution to the verification of this proof.
                    </DialogContentText>
                    <DialogContentText>
                        If this is the first time you are reporting a failed verification, take a look at <Link href="/faq">our guidelines</Link> to learn how best you can help the publisher.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="report"
                        label="Message"
                        type="text"
                        fullWidth
                        variant="filled"
                        multiline
                        maxRows={20}
                        defaultValue={reportText}
                        onChange={(e) => setReportText(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseReport}>Cancel</Button>
                    <Button
                        disabled={isLoading}
                        onClick={handleSubmitReport}
                    >Submit</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={openSnack}
                autoHideDuration={6000}
                onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                    Report submitted successfully!
                </Alert>
            </Snackbar>
        </>
  );
}