import { Avatar, Box, Button, Card, CardContent, CardHeader, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { dateAndHour } from '../utils/dates';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import {
  useShowUserCompleteProfileByEmailQuery,
} from '../slices/showApiSlice';
import makeBlockie from 'ethereum-blockies-base64';
import React from 'react';
import { red } from '@mui/material/colors';

export default function UserCompleteSummary(
  { userEmail, isWriting }: { userEmail: string, isWriting: boolean },
) {

  const { data: userCompleteProfile, isLoading, isError } = useShowUserCompleteProfileByEmailQuery(userEmail);

  console.log(userCompleteProfile);

  return (
        <Stack display='flex'>
            <Card sx={{ width: 345, m: 'auto' }}>
                <CardHeader
                    avatar={
                        (isWriting || isLoading) ? (
                            <Skeleton animation="wave" variant="circular" width={40} height={40} />
                        ) : (
                          (!userCompleteProfile || isError) ?
                                <Avatar
                                    alt="Root Image"
                                    sx={{ bgcolor: red[800] }}
                                >
                                    <NoAccountsIcon fontSize='large' />
                                </Avatar>
                            :
                                <Avatar
                                    alt="Root Image"
                                >
                                    <img src={makeBlockie(userCompleteProfile ? userCompleteProfile.dataRoot : '---')} width='60px' />
                                </Avatar>
                        )
                    }
                    // action={
                    //     isWriting ? null : (
                    //     <IconButton aria-label="settings">
                    //       <MoreVertIcon />
                    //     </IconButton>
                    //   )
                    // }
                    title={
                        (isWriting || isLoading) ? (
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="80%"
                                style={{ marginBottom: 6 }}
                            />
                        ) : (
                          (!userCompleteProfile || isError) ? <Typography textAlign='left' fontWeight={800} color={red[800]}>User not found!</Typography>
                            :
                                <Typography textAlign='left'>{userCompleteProfile.displayName}</Typography>
                        )
                    }
                    subheader={
                        (isWriting || isLoading) ? (
                            <Skeleton animation="wave" height={10} width="40%" />
                        ) : (
                          (!userCompleteProfile || isError) ?
                                <Typography textAlign='left'></Typography>
                            :
                                <Typography textAlign='left' fontSize='small' color='text.secondary'>
                                    {userCompleteProfile.email}</Typography>

                        )
                    }
                />
                {(isWriting || isLoading) ? (
                    <Skeleton sx={{ height: 80 }} animation="wave" variant="rectangular" />
                ) : (
                    <>
                        {!userCompleteProfile || isError ? (
                            <Paper
                                square
                                elevation={0}
                                sx={{
                                  p: 2,
                                  bgcolor: red[200],
                                  textAlign: 'center',
                                  color: 'grey.500',
                                  height: 80,
                                }}
                            />
                        ) : (
                            <Box textAlign='left' pl={2} pr={2}>
                                {/* <Divider />
                                <Typography
                                    sx={{ mt: 0.5, ml: 2 }}
                                    color="text.secondary"
                                    display="block"
                                    variant="caption"
                                    textAlign='right'
                                >
                                    Locks
                                </Typography> */}
                                <Typography variant="body1" color="text.secondary" >
                                    Number of locked data: {userCompleteProfile.dataLeaves}
                                </Typography>

                                <Typography variant="body1" color="text.secondary" >
                                    Last update: {dateAndHour(new Date(userCompleteProfile.dataLastUpdate))}
                                </Typography>
                                {/* <Divider sx={{ mt: 2 }} />
                                <Typography
                                    sx={{ mt: 0.5, ml: 2 }}
                                    color="text.secondary"
                                    display="block"
                                    variant="caption"
                                    textAlign='right'
                                >
                                    Rules
                                </Typography> */}

                                <Typography variant="body1" color="text.secondary" >
                                    Number of associated rules: {userCompleteProfile.proofs}
                                </Typography>

                                <Typography variant="body1" color="text.secondary" >
                                    Last update: {userCompleteProfile.lastProofTime ? dateAndHour(new Date(userCompleteProfile.lastProofTime)) : '---'}
                                </Typography>
                            </Box>
                        )}
                    </>
                )}
                <CardContent>
                    {(isWriting || isLoading) ? (
                        <React.Fragment>
                            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={10} width="80%" />
                        </React.Fragment>
                    ) : (
                      (!userCompleteProfile || isError) ? <Typography textAlign='left'>---</Typography>
                        :
                            <Button
                                component={RouterLink}
                                to={`/show/user/${userCompleteProfile._id}`}
                            >
                                <Typography variant="body1" fontSize='small'>view complete profile</Typography>
                            </Button>
                    )}
                </CardContent>
            </Card>

        </Stack>
  );
}