import { Box, Button, Card, CardContent, Container, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom'; //@audit redirect if user not authenticated
import ProofSummary from '../components/ProofSummary';
import UserActivity from '../components/UserActivity';
import makeBlockie from 'ethereum-blockies-base64';
import { Link as RouterLink } from 'react-router-dom';
import PublicIcon from '@mui/icons-material/Public';

export default function Profile() {

  const userProofs = useSelector((state: any) => state.user.userProofs);
  const userMerkle = useSelector((state: any) => {
    const merkle = state.merkle;
    if (merkle) {
      return JSON.parse(merkle.userMerkle);
    }
    return null;
  });
  const profile = useSelector((state: any) => {
    const prof = state.profile;
    if (prof) {
      return JSON.parse(prof.userProfile);
    }
    return null;
  });
  const userInfo = useSelector((state: any) => {
    const user = state.user;
    if (user) {
      return JSON.parse(user.user);
    }
    return null;
  });

  return (
        <>{profile && userMerkle && userInfo &&
            <Container
            >
                <Box textAlign="left" >
                    <Stack spacing={2} >
                        <Stack spacing={2}>
                            <Typography variant="h6">Your Profile</Typography>
                            <Grid container spacing={2} alignItems="center" pr={4}>
                                <Grid item xs={10}>
                                    <Typography variant="body1"><strong>Name: </strong>{profile?.user.displayName}</Typography>
                                    <Typography variant="body1"><strong>Email: </strong><Link href={`mailto:${userInfo?.email}`}>
                                        {userInfo?.email}</Link></Typography>
                                    <Typography variant="body1"><strong>Security level: </strong>basic</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button component={RouterLink} to={`/show/user/${profile.user._id}`} size='small' variant='outlined' color='inherit'
                                        endIcon={<PublicIcon />}
                                    >public profile</Button>
                                </Grid>
                            </Grid>
                        </Stack>
                        <Divider />
                        <Stack spacing={2}>
                            <Typography variant="h6">Rules and Data Published</Typography>
                            <Box
                                sx={{
                                  p: 2,
                                }}>
                                <Card>
                                    <CardContent>
                                        <Stack direction='row' display='flex' justifyContent='space-between'
                                        >
                                            <Stack direction='row' spacing={2} alignItems='center' >
                                                <img src={makeBlockie(userMerkle?.root ? userMerkle.root : '---')} width='60px' />
                                                <Stack spacing={1}>
                                                    <Typography variant="body1" fontSize='small' >
                                                        {userMerkle?.root ? userMerkle.root : '---'}
                                                    </Typography>
                                                    <Typography variant="body1" fontSize='small' >
                                                        Number of locks: {userMerkle ? userMerkle.leaves.length : '---'}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            <Button
                                                component={RouterLink}
                                                to='/data'
                                            >Manage</Button>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box
                                display="flex"
                                sx={{
                                  p: 1,
                                }}>
                                {userProofs.map((proof: any) => {
                                  return (
                                        <ProofSummary key={proof._id} userProof={proof} userFeatures />
                                  );
                                })}
                            </Box>
                        </Stack>
                        <Divider />
                        <Stack>
                            <Typography variant="h6">Thirty-Day Activity</Typography>
                            <UserActivity />
                        </Stack>
                    </Stack>
                </Box>
            </Container>
        }</>
  );
}