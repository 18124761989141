import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Grow,
  IconButton,
  InputBase,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import publishingDataImage from '../assets/images/publishingData.svg';
import styled from '@emotion/styled';
import { useState, useRef } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AddIcon from '@mui/icons-material/Add';
import {
  Tag,
  tagSystem,
} from '../utils/tagSystem';
import makeBlockie from 'ethereum-blockies-base64';
import { Link } from '@mui/material';
import {
  digestWithSalt,
  UserMerkleLeaf,
  verifyLeaf,
  buildUserMerkleTree,
} from '../utils/userMerkle';
import { useSelector } from 'react-redux';
import SendIcon from '@mui/icons-material/Send';
import { dateAndHour } from '../utils/dates';
import {
  setUserMerkle,
} from '../slices/merkleSlice';
import {
  useSetMerkleMutation,
} from '../slices/merkleApiSlice';
import { useDispatch } from 'react-redux';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function toCamelCase(str: string) {
  return str
    .split(' ')
    .map((word, index) => {
      // Convert first word to lowercase and the rest to TitleCase
      return index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');
}

export default function PublishingData() {
  const [openTagSystem, setOpenTagSystem] = useState(false);
  const [openTagSelected, setOpenTagSelected] = useState(false);
  const [openNewCustomTag, setOpenNewCustomTag] = useState(false);
  const [isBuildingLeaf, setIsBuildingLeaf] = useState(false);
  const [showUserLeaf, setShowUserLeaf] = useState(false);
  const [openNewSystemTag, setOpenNewSystemTag] = useState(false);
  const [customTagValueType, setCustomTagValueType] = useState('value');
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndexTagSystem, setSelectedIndexTagSystem] = useState(0);
  const [selectedIndexTagSelected, setSelectedIndexTagSelected] = useState(0);
  const [customTagValue, setCustomTagValue] = useState('custom-');
  const [currentLeaf, setCurrentLeaf] = useState<UserMerkleLeaf | null>(null);
  const [systemTagValue, setSystemTagValue] = useState<Tag | null>(null);
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [setMerkle, { isLoading: settingMerkle }] = useSetMerkleMutation();
  const dispatch = useDispatch();

  const userInfo = useSelector((state: any) => {
    const user = state.user;
    if (user) {
      return JSON.parse(user.user);
    }
    return null;
  });

  const currentUser = useSelector((state: any) => {
    const merkle = state.merkle;
    if (merkle) {
      return JSON.parse(merkle.userMerkle);
    }
    return null;
  });

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndexTagSelected(0);
    setSelectedIndexTagSystem(index);
    setSystemTagValue(tagSystem[index].tags[0]);
    setOpenTagSystem(false);
  };

  const handleToggleTagSelected = () => {
    setOpenTagSelected((prevOpenTagSelected) => !prevOpenTagSelected);
  };

  const handleMenuItemClickTagSelected = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndexTagSelected(index);
    setSystemTagValue(tagSystem[selectedIndexTagSystem].tags[index]);
    setOpenTagSelected(false);
  };

  const handleCloseTagSelected = (event: Event) => {
    if (
      anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenTagSelected(false);
  };

  const openLeaf = (tag: string) => () => {
    const leaf = currentUser.leaves.find((l: UserMerkleLeaf) => l.tag === tag);
    if (leaf) {
      setShowUserLeaf(true);
      setCurrentLeaf(leaf);
    }
  };

  const handleToggleTagSystem = () => {
    setOpenTagSystem((prevOpenTagSystem) => !prevOpenTagSystem);
  };

  const handleCustomTagValueChange = (event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    const regex = /^[A-Za-z0-9 ]*$/;
    if (regex.test(value)) {
      setCustomTagValue('custom-' + toCamelCase(event.target.value));
    } else {

    }
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenTagSystem(false);
  };


  function handleAddCustomTag(): void {
    if (customTagValue === 'custom-') {
      return;
    }

    setOpenNewCustomTag(true);
  }

  // async function createLeaves(data: any[]) {
  //   setIsBuildingLeaf(true);
  //   let newLeaves: UserMerkleLeaf[] = [];
  
  //   for (let d of data) {
  //     let digest = await digestWithSalt(d.dataValue);
  //     let newLeaf: UserMerkleLeaf = {
  //       tag: d.dataTag as string,
  //       tagType: d.dataTagType as string,
  //       hash: digest.hash,
  //       order: 0,
  //       date: (new Date()).toISOString(),
  //       salt: digest.salt,
  //       description: d.description as string,
  //     };
  //     if (d.dataType === 'file') {
  //       newLeaf.fileName = fileName;
  //     }
  //     newLeaves.push(newLeaf);
  //   }
  
  //   currentUser.leaves.push(...newLeaves);
  //   const merkle = await buildUserMerkleTree(currentUser.leaves);
  //   currentUser.root = merkle.root;
  //   dispatch(setUserMerkle(JSON.stringify(currentUser)));
  //   setIsBuildingLeaf(false);
  // }

  async function handleNewLeaf(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault(); // Prevent the form from submitting in the traditional way
    setIsBuildingLeaf(true);
    const form = event.currentTarget;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);
    digestWithSalt(data.dataValue).then((digest) => {
      let newLeaf: UserMerkleLeaf = {
        tag: data.dataTag as string,
        tagType: data.dataTagType as string,
        hash: digest.hash,
        order: 0,
        date: (new Date()).toISOString(),
        salt: digest.salt,
        description: data.description as string,
      };
      if (data.dataType === 'file') {
        newLeaf.fileName = fileName;
      }
      currentUser.leaves.push(newLeaf);
      buildUserMerkleTree(currentUser.leaves).then((merkle) => {
        currentUser.root = merkle.root;
        // setCurrentUser(currentUser);
        dispatch(setUserMerkle(JSON.stringify(currentUser)));
        setIsBuildingLeaf(false);
        setOpenNewCustomTag(false);
        setOpenNewSystemTag(false);
      });
    });
  }

  function handleCloseNewCustomTag(): void {
    setOpenNewCustomTag(false);
  }

  function handleCloseUserLeaf(): void {
    setShowUserLeaf(false);
  }

  function handleAddSystemTag(): void {
    setOpenNewSystemTag(true);
  }

  function handleCloseNewSystemTag(): void {
    setOpenNewSystemTag(false);
  }

  function handleFileName(event: React.ChangeEvent<HTMLInputElement>) {
    setFileName(event.target.files?.item(0)?.name);
  }

  async function deleteLeaf() {
    if (currentLeaf) {
      const index = currentUser.leaves.findIndex((leaf: UserMerkleLeaf) => leaf.tag === currentLeaf.tag);
      if (index >= 0) {
        currentUser.leaves.splice(index, 1);
        setCurrentLeaf(null);
        const merkle = await buildUserMerkleTree(currentUser.leaves);
        currentUser.root = merkle.root;
        // setCurrentUser(currentUser);
        dispatch(setUserMerkle(JSON.stringify(currentUser)));
        setShowUserLeaf(false);

      }
    }
  }

  function uploadMerkle() {
    setMerkle(currentUser).unwrap().then((result) => {
      dispatch(setUserMerkle(JSON.stringify(result)));
    });
  }

  function testCurrentLeaf(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    console.log('testCurrentLeaf', currentLeaf);
    const form = event.currentTarget;
    const formData = new FormData(form);
    console.log('formData', formData);
    digestWithSalt(formData.get('dataValue') as string, currentLeaf?.salt).then((digest) => {
      console.log('digest', digest);
      if (digest.hash === currentLeaf?.hash) {
        console.log('hashes match');
      } else {
        console.log('hashes do not match');
      }
    });
    if (currentLeaf)
      verifyLeaf(currentUser.leaves, currentLeaf, currentUser.root).then((result) => {
        console.log('result of proof', result);
      });
  }

  // const createLeavesHardcoded = async () => {
  //   const formDataArray = [
  //     // Replace this with your hardcoded form data
  //     { dataTag: 'custom-trustedEmail', dataTagType: 'custom', dataValue: 'asd@cysuitegroup.com', description: 'email for martad' },
  //   ];
  //   // Add more form data objects as needed

  //   await createLeaves(formDataArray);
  // };
  
  return (
        <>
            <Stack spacing={2}>
                <img src={publishingDataImage} alt="publishingData" style={{ height: '100px', marginBottom: '10px' }} />
                <Card >
                    <CardContent  >
                        <Stack spacing={2} textAlign='left'>
                            <Stack
                                spacing={2}
                                direction="row"
                            >
                                <Stack>
                                    <Typography variant="h6" gutterBottom>
                                        Locks & Fingerprints
                                    </Typography>
                                    {/* <Button variant="contained" color="primary" onClick={createLeavesHardcoded}>
                                      Create Leaves
                                    </Button> */}
                                    <Stack direction='row' spacing={2}>
                                        <Typography variant="body1" gutterBottom>
                                            Provide a representation of your data that can be verified by others.
                                            This representation is called a <b>lock</b> (also known as a <b>fingerprint</b>), and is a privacy friendly way to attest to the existence and integrity of your data.
                                            View more about this feature <Link>here</Link>, including what are <i>system tags</i> and how can they improve the security of your processes and existing tools.
                                        </Typography>
                                        <Stack spacing={1} minWidth={200} textAlign='center'>
                                            <Box>
                                                <img src={makeBlockie(currentUser?.root ? currentUser.root : '---')} width='60px' />
                                            </Box>
                                            <Stack>
                                                <Typography variant="body2" gutterBottom sx={{
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  whiteSpace: 'nowrap',
                                                }}>
                                                    {currentUser?.root ? currentUser.root : '---'}
                                                </Typography>
                                                <Typography variant="body2" gutterBottom
                                                    fontSize="small" color="text.secondary">
                                                    {!currentUser?.root || currentUser?.root === '---' ? 'No data'
                                                      : 'Current data representation'}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack spacing={2} justifyContent="space-between" alignItems='left'>
                                <Typography variant="body1" fontWeight={800} gutterBottom>
                                    System tag
                                </Typography>
                                <Box>
                                    <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" >
                                        <Button
                                            onClick={handleToggleTagSystem}
                                            endIcon={<ArrowDropDownIcon />}
                                        >
                                            {tagSystem[selectedIndexTagSystem].type}
                                        </Button>
                                        <Button
                                            onClick={handleToggleTagSelected}
                                            endIcon={<ArrowDropDownIcon />}
                                        >
                                            {tagSystem[selectedIndexTagSystem].tags[selectedIndexTagSelected].name}
                                        </Button>
                                        <Button
                                            size="small"
                                            aria-controls={openTagSelected ? 'split-button-menu' : undefined}
                                            aria-expanded={openTagSelected ? 'true' : undefined}
                                            aria-label="select merge strategy"
                                            aria-haspopup="menu"
                                            onClick={handleAddSystemTag}
                                        >
                                            <AddIcon />
                                        </Button>
                                    </ButtonGroup>
                                    <Popper
                                        sx={{
                                          zIndex: 1,
                                        }}
                                        open={openTagSystem}
                                        anchorEl={anchorRef.current}
                                        role={undefined}
                                        transition
                                        disablePortal
                                    >
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{
                                                  transformOrigin:
                                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                                }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={handleClose}>
                                                        <MenuList id="split-button-menu" autoFocusItem>
                                                            {
                                                                tagSystem.map((tagSys, index) => (
                                                                    <MenuItem
                                                                        key={tagSys.type}
                                                                        selected={index === selectedIndexTagSystem}
                                                                        onClick={(event) => handleMenuItemClick(event, index)}
                                                                    >
                                                                        {tagSys.type}
                                                                    </MenuItem>
                                                                ))}
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                    <Popper
                                        sx={{
                                          zIndex: 1,
                                        }}
                                        open={openTagSelected}
                                        anchorEl={anchorRef.current}
                                        role={undefined}
                                        transition
                                        disablePortal
                                    >
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{
                                                  transformOrigin:
                                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                                }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={handleCloseTagSelected}>
                                                        <MenuList id="split-button-menu" autoFocusItem>
                                                            {tagSystem[selectedIndexTagSystem].tags.map((tagSelected, index) => (
                                                                <MenuItem
                                                                    key={tagSelected.name}
                                                                    selected={index === selectedIndexTagSelected}
                                                                    onClick={(event) => handleMenuItemClickTagSelected(event, index)}
                                                                >
                                                                    {tagSelected.name}
                                                                </MenuItem>
                                                            ))}
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </Box>
                                <Divider orientation="horizontal" flexItem > or </Divider>
                                <Typography variant="body1" fontWeight={800} gutterBottom>
                                    Custom tag
                                </Typography>
                                <Box>
                                    <Paper
                                        component="form"
                                        sx={{ display: 'flex', alignItems: 'center', minWidth: 250, maxWidth: 450 }}
                                    >
                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder={'Create a custom tag'}
                                            inputProps={{ 'aria-label': 'search published data', maxLength: 40 }}
                                            onChange={handleCustomTagValueChange}
                                            onKeyPress={(event) => {
                                              const regex = /^[A-Za-z0-9 ]*$/;
                                              if (!regex.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}

                                        />
                                        <IconButton
                                            type="button"
                                            sx={{ p: '10px' }}
                                            aria-label="search"
                                            color='primary'
                                            disabled={customTagValue === 'custom-'}
                                            onClick={handleAddCustomTag}>
                                            <AddIcon />
                                        </IconButton>
                                    </Paper>
                                </Box>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>

                <Card>
                    <CardContent>
                        <Stack spacing={2} textAlign='left'>
                            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                <Typography variant="h6" gutterBottom>
                                    Current data locked
                                </Typography>
                                {isBuildingLeaf && <CircularProgress
                                    size={30}
                                />}
                            </Stack>
                            <Grid container spacing={2} pr={1}>
                                {
                                    currentUser?.leaves.map((leaf: UserMerkleLeaf, index: number) => (
                                        <Grid item key={index}> <Chip label={leaf.tag} icon={<LocalOfferIcon fontSize='small' />}
                                            onClick={openLeaf(leaf.tag)} /> </Grid>
                                    ))
                                }
                            </Grid>
                            <Stack spacing={2} direction='row' display='flex' justifyContent='flex-end'>
                                <Button
                                    variant="contained"
                                    startIcon={<SendIcon />}
                                    onClick={uploadMerkle}
                                    disabled={!currentUser || isBuildingLeaf || settingMerkle}
                                >
                                    Publish locks
                                </Button>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>

                {!userInfo && (
                    <Alert severity='warning' sx={{
                      mt: 2,
                    }}>
                        <Typography variant="body1" gutterBottom>
                            You are not currently signed in. You can keep playing and test the app,
                            but you will not be able to publish data.
                        </Typography>
                    </Alert>
                )}
            </Stack>
            <Dialog
                open={openNewCustomTag}
                onClose={handleCloseNewCustomTag}
                fullWidth={true}
                maxWidth="md"
            >
                <form onSubmit={handleNewLeaf}>
                    <DialogTitle>New Custom Tag:
                        <code style={{ color: 'rgb(227, 109, 91)' }}> {customTagValue}</code>
                    </DialogTitle>
                    <DialogContent>
                        <Stack spacing={2}>
                            <DialogContentText>
                                If this is the first time adding a new custom tag, take a look
                                at <Link href="/faq">our guidelines</Link> to learn how best you can help data verifiers.
                            </DialogContentText>
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems='center'
                            >
                                <FormLabel id="tag-label">Tag</FormLabel>
                                <TextField
                                    id="data-tag"
                                    name='dataTag'
                                    type="text"
                                    variant="filled"
                                    value={customTagValue}
                                    sx={{
                                      display: 'none',
                                    }}
                                />
                                <TextField
                                    id="data-tagType"
                                    name='dataTagType'
                                    type="text"
                                    variant="filled"
                                    value="custom"
                                    sx={{
                                      display: 'none',
                                    }}
                                />
                                <Chip label={customTagValue} icon={<LocalOfferIcon fontSize='small' />} />
                            </Stack>
                            <FormLabel id="type-label">Type</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="type-label"
                                name="dataType"
                                onChange={(e) => setCustomTagValueType(e.target.value)}
                                defaultValue='value'
                            >
                                <FormControlLabel value="value" control={<Radio />} label="Value" defaultChecked />
                                <FormControlLabel value="file" control={<Radio />} label="File" />
                            </RadioGroup>
                            <FormLabel id="description-label">Description</FormLabel>
                            <TextField
                                autoFocus
                                id="description"
                                aria-labelledby="description-label"
                                // label="Description"
                                name='description'
                                type="text"
                                fullWidth
                                variant="filled"
                                multiline
                                maxRows={20}
                                defaultValue={''}
                            // onChange={(e) => setReportText(e.target.value)}
                            />
                            <FormLabel id="data-label">Data</FormLabel>
                            {customTagValueType === 'value' ? (<TextField
                                autoFocus
                                id="data-value"
                                aria-labelledby="data-label"
                                // label="Description"
                                name='dataValue'
                                type="text"
                                fullWidth
                                variant="filled"
                                multiline
                                maxRows={20}
                                defaultValue={''}
                            // onChange={(e) => setReportText(e.target.value)}
                            />) :
                              (
                                    <Stack direction='row' spacing={2} alignItems='center'>
                                        <Button component="label" variant="contained" sx={{
                                          maxWidth: '200px',
                                        }} startIcon={<NoteAddIcon />}>
                                            Lock file
                                            <VisuallyHiddenInput
                                                type="file"
                                                name='dataValue'
                                                onChange={handleFileName}
                                            />
                                        </Button>
                                        <Typography variant="body1" fontSize='small' gutterBottom>
                                            {fileName}
                                        </Typography>
                                    </Stack>
                              )}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button type="button" onClick={handleCloseNewCustomTag}>Cancel</Button>
                        <Button
                            type='submit'
                            disabled={isBuildingLeaf}
                        >Build</Button>
                    </DialogActions>
                </form>
            </Dialog >
            <Dialog
                open={showUserLeaf}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="sm"
                onClose={handleCloseUserLeaf}
            >
                <DialogTitle id="alert-dialog-title">Lock Data</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" gutterBottom>
                        <b>Tag: </b>{currentLeaf?.tag}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <b>Description: </b>{currentLeaf?.description}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <b>Hash: </b>{currentLeaf?.hash}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <b>Salt: </b>{currentLeaf?.salt}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <b>Created At: </b>{currentLeaf ? dateAndHour(new Date(currentLeaf.date)) : '--'}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <b>Format: </b>{currentLeaf?.fileName ? 'File' : 'Value'}
                    </Typography>
                    {currentLeaf?.fileName && (
                        <Typography variant="body1" gutterBottom>
                            <b>File Name: </b>{currentLeaf?.fileName}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUserLeaf}>Close</Button>
                    <Button onClick={deleteLeaf} color='error' variant='contained'>Delete</Button>
                </DialogActions>
                <Divider />
                <DialogTitle id="alert-dialog-test-it">Test it</DialogTitle>
                <form onSubmit={testCurrentLeaf}>
                    <DialogContent>
                        {!currentLeaf?.fileName ? (<TextField
                            autoFocus
                            id="data-value"
                            aria-labelledby="data-label"
                            // label="Description"
                            name='dataValue'
                            type="text"
                            fullWidth
                            variant="filled"
                            multiline
                            maxRows={20}
                            defaultValue={''}
                        // onChange={(e) => setReportText(e.target.value)}
                        />) :
                          (
                                <Stack direction='row' spacing={2} alignItems='center'>
                                    <Button component="label" variant="contained" sx={{
                                      maxWidth: '200px',
                                    }} startIcon={<NoteAddIcon />}>
                                        Select file
                                        <VisuallyHiddenInput
                                            type="file"
                                            name='dataValue'
                                            onChange={handleFileName}
                                        />
                                    </Button>
                                    <Typography variant="body1" fontSize='small' gutterBottom>
                                        {fileName}
                                    </Typography>
                                </Stack>
                          )}
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit'>Verify</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog
                open={openNewSystemTag}
                onClose={handleCloseNewSystemTag}
                fullWidth={true}
                maxWidth="md"
            >
                <form onSubmit={handleNewLeaf}>
                    <DialogTitle>New System Tag: <code style={{ color: 'rgb(227, 109, 91)' }}>{systemTagValue?.tag}</code>
                    </DialogTitle>
                    <DialogContent>
                        <Stack spacing={2}>
                            <DialogContentText>
                                If this is the first time adding a new system tag, take a look
                                at <Link href="/faq">our guidelines</Link> and see what rules applied to each data lock.
                            </DialogContentText>
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems='center'
                            >
                                <FormLabel id="tag-label">Tag</FormLabel>
                                <TextField
                                    id="data-tag"
                                    name='dataTag'
                                    type="text"
                                    variant="filled"
                                    value={systemTagValue?.tag}
                                    sx={{
                                      display: 'none',
                                    }}
                                />
                                <TextField
                                    id="data-tagType"
                                    name='dataTagType'
                                    type="text"
                                    variant="filled"
                                    value="system"
                                    sx={{
                                      display: 'none',
                                    }}
                                />
                                <Chip label={systemTagValue?.tag} icon={<LocalOfferIcon fontSize='small' />} />
                            </Stack>
                            <TextField
                                id="data-type"
                                name='dataType'
                                type="text"
                                variant="filled"
                                value={systemTagValue?.format}
                                sx={{
                                  display: 'none',
                                }}
                            />
                            <FormLabel id="description-label">Description</FormLabel>
                            <TextField
                                autoFocus
                                id="description"
                                aria-labelledby="description-label"
                                // label="Description"
                                name='description'
                                type="text"
                                fullWidth
                                variant="filled"
                                multiline
                                maxRows={20}
                                defaultValue={systemTagValue?.description}
                            // onChange={(e) => setReportText(e.target.value)}
                            />
                            <FormLabel id="data-label">Data</FormLabel>
                            {systemTagValue?.format === 'value' ? (<TextField
                                autoFocus
                                id="data-value"
                                aria-labelledby="data-label"
                                // label="Description"
                                name='dataValue'
                                type="text"
                                fullWidth
                                variant="filled"
                                multiline
                                maxRows={20}
                                defaultValue={''}
                            // onChange={(e) => setReportText(e.target.value)}
                            />) :
                              (
                                    <Stack direction='row' spacing={2} alignItems='center'>
                                        <Button component="label" variant="contained" sx={{
                                          maxWidth: '200px',
                                        }} startIcon={<NoteAddIcon />}>
                                            Lock file
                                            <VisuallyHiddenInput
                                                type="file"
                                                name='dataValue'
                                                onChange={handleFileName}
                                            />
                                        </Button>
                                        <Typography variant="body1" fontSize='small' gutterBottom>
                                            {fileName}
                                        </Typography>
                                    </Stack>
                              )}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button type="button" onClick={handleCloseNewSystemTag}>Cancel</Button>
                        <Button
                            type='submit'
                            disabled={isBuildingLeaf}
                        >Build</Button>
                    </DialogActions>
                </form>
            </Dialog >
        </>
  );
}
