import { Stack, Typography } from '@mui/material';

export default function Settings() {

  return (
        <Stack
            spacing={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left',
            }}
        >
            <Typography variant="body1" >
                1. Add an alternative notification email address
            </Typography>
            <Typography variant="body1" >
                2. The tool needs 2FA (TOPT) -- Run away from SMS
            </Typography>
            <Typography variant="body1" >
                3. The tool can't have federated login ? (Google, Facebook, Twitter, Github, ... ) If the account is compromised, the attacker can access the tool
            </Typography>
            <Typography variant="body1" >
                4. How to handle account recovery?  Lock the account during 7 days after password recovery? Notify the alternative email address?
            </Typography>
        </Stack>
  );
}