import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { retrieveIOCs } from '../utils/domainTypes';
import CellHeader from './domain-utils/CellHeader';
import CellRiskScore from './domain-utils/CellRiskScore';
import CellWarning from './domain-utils/CellWarning';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

interface DomainsListProps {
  filter: string;
  deleteDomain: (domain: string) => void;
  refreshDomain: (domain: string) => void;
}

export default function DomainsList({ filter, deleteDomain, refreshDomain }: DomainsListProps) {

  const listDomains = useSelector((state: any) => {
    if (state?.domains?.domainsOsint) {
      return state.domains.domainsOsint;
    }
    return [];
  });

  const filteredDomains = useMemo(() => {
    if (filter) {
      return listDomains.filter((domain: any) => domain.domain.includes(filter));
    }
    return listDomains;
  }, [listDomains, filter]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Domain</TableCell>
                    <TableCell align="center">Risk Score</TableCell>
                    <TableCell align="left">Warnings</TableCell>
                    <TableCell align="right">Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody sx={{ maxHeight: '500px', overflow: 'auto' }}>
                {filteredDomains.map((domain: any) => (
                    <TableRow
                        key={domain._id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            <CellHeader
                                domain={domain.domain}
                                lastUpdated={domain.updatedAt}
                                registered={domain.analysis.domain_age.human}
                                countryCode={domain.analysis.country_code}
                                languageCode={domain.analysis.language_code}
                            />
                        </TableCell>
                        <TableCell align="center">
                            <CellRiskScore riskScore= {domain.analysis.risk_score} />
                        </TableCell>
                        <TableCell align="left">
                            <CellWarning warnings={retrieveIOCs(domain)} />
                        </TableCell>
                        <TableCell align="right">
                            <IconButton onClick={() => refreshDomain(domain.domain)} aria-label="analysis-refresh">
                                <RefreshRoundedIcon />
                            </IconButton>
                            <IconButton onClick={() => deleteDomain(domain.domain)} aria-label="analysis-deleteDomain">
                                <DeleteRoundedIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    </Paper>
  );
}