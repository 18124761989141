import { createSlice } from '@reduxjs/toolkit';

const domainsOsintStorage = localStorage.getItem('domainsOsint');

const initialState = {
  domainsOsint: domainsOsintStorage ? JSON.parse(domainsOsintStorage) : null,
};

const domainsSlice = createSlice({
  name: 'DomainsOsintSlice',
  initialState,
  reducers: {
    setDomainsOsint: (state, action) => {
      state.domainsOsint = action.payload;
      localStorage.setItem('domainsOsint', JSON.stringify(action.payload));
    },
    reset: (state) => {
      state.domainsOsint = null;
      localStorage.clear();
    },
  },
});

export const {
  setDomainsOsint,
  reset,
} = domainsSlice.actions;

export default domainsSlice.reducer;