export const BaseURL = '';//process.env.NODE_ENV === 'development' ? '' : 'https://api.strataprotection.com';
export const API_URL = BaseURL + '/api/v1';
export const CircuitURL = API_URL + '/circuits';
export const PolicyURL = API_URL + '/policies';
export const ProofURL = API_URL + '/proofs';
export const UserURL = API_URL + '/users';
export const ReportsURL = API_URL + '/reports';
export const ActivityURL = API_URL + '/activity';
export const TrackerURL = API_URL + '/tracker';
export const ShowURL = API_URL + '/show';
export const MerkleURL = API_URL + '/data';
export const DomainOsintURL = API_URL + '/domain-osint';
export const EmailOsintURL = API_URL + '/email-osint';
export const FileOsintURL = API_URL + '/file-osint';
export const UrlOsintURL = API_URL + '/url-osint';