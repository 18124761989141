import { createSlice } from '@reduxjs/toolkit';

const emailsOsintStorage = localStorage.getItem('emailsOsint');

const initialState = {
  emailsOsint: emailsOsintStorage ? JSON.parse(emailsOsintStorage) : null,
};

const emailsSlice = createSlice({
  name: 'EmailsOsintSlice',
  initialState,
  reducers: {
    setEmailsOsint: (state, action) => {
      state.emailsOsint = action.payload;
      localStorage.setItem('emailsOsint', JSON.stringify(action.payload));
    },
    reset: (state) => {
      state.emailsOsint = null;
      localStorage.clear();
    },
  },
});

export const {
  setEmailsOsint,
  reset,
} = emailsSlice.actions;

export default emailsSlice.reducer;