import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

export default function PreviewProof({ proofData }: { proofData: string }) {

  const [openModal, setOpenModal] = useState(false);


  const handlePreviewClick = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
        <>
            <IconButton color="primary" aria-label="preview proof"
                size="small"
                onClick={handlePreviewClick}
                sx={{
                  padding: '2px',
                }}>
                <ZoomOutMapIcon fontSize="small" />
            </IconButton>
            <Chip label={proofData.substring(0, 33) + '...'} />
            <Dialog open={openModal} onClose={handleModalClose}
                scroll="paper">
                <DialogTitle>Full Proof Value</DialogTitle>
                <DialogContent>
                    <Stack direction="row" spacing={2}>

                        <Typography variant="body2"
                            style={{
                              fontFamily: 'monospace',
                              wordBreak: 'break-word',
                            }}>
                            {proofData}
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
  );

}