import { Middleware, configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './slices/apiSlice';
import authSliceReducer from './slices/authSlice';
import userSliceReducer, { setUser } from './slices/userSlice';
import dashboardSlice from './slices/dashboardSlice';
import domainsSlice from './slices/domainsSlice';
import emailsSlice from './slices/emailsSlice';
import filesSlice from './slices/filesSlice';
import urlsSlice from './slices/urlsSlice';
import merkleSlice from './slices/merkleSlice';
import userProfileSlice from './slices/profileSlice';
import { refreshTokenId } from './auth/fbase';

const refreshTokenMiddleware: Middleware = (store) => (next) => (action) => {
  if (action.type === 'auth/logout') {
    console.log('middleware caught logout');
  } else {
    refreshTokenId().then((result) => {
      if (result?.renewed) {
        store.dispatch(setUser(JSON.stringify(result.user)));
      }
    });
  }

  return next(action);
};

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSliceReducer,
    user: userSliceReducer,
    dashboard: dashboardSlice,
    domains: domainsSlice,
    emails: emailsSlice,
    files: filesSlice,
    urls: urlsSlice,
    merkle: merkleSlice,
    profile: userProfileSlice,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['user.userNotifications'],
      },
    }).concat(
      refreshTokenMiddleware,
      apiSlice.middleware,
      // createSerializableStateInvariantMiddleware()
    );
  },
  devTools: process.env.NODE_ENV !== 'production',

});

export type RootState = ReturnType<typeof store.getState>;
export default store;