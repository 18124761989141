import { MerkleURL } from '../constants';
import { apiSlice } from './apiSlice';

export const merkleApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder: any) => ({
    setMerkle: builder.mutation({
      query: (data: any) => ({
        url: MerkleURL,
        method: 'POST',
        body: data,
      }),
    }),
    getUserMerkle: builder.query({
      query: () => ({
        url: MerkleURL,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    showUserMerkle: builder.query({
      query: (userId: string) => ({
        url: `${MerkleURL}/user/${userId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    getProofPath: builder.query({
      query: ({ userId, leafId }: { userId: string, leafId: string }) => ({
        url: `${MerkleURL}/user/${userId}/${leafId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useSetMerkleMutation,
  useGetUserMerkleQuery,
  useShowUserMerkleQuery,
  useGetProofPathQuery,
} = merkleApiSlice;