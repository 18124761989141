import { useSelector } from 'react-redux';
import { useGetPolicyDetailsQuery } from '../slices/policyApiSlice';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardActions, CardContent, IconButton, Typography } from '@mui/material';
import PageviewIcon from '@mui/icons-material/Pageview';
import DeleteIcon from '@mui/icons-material/Delete';

function formatDate(date: Date) {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  return `${year}-${month}-${day}`;
}

export default function ProofSummary({ userProof, userFeatures = false }:
{ userProof: any, userFeatures?: boolean }) {

  const userInfo = useSelector((state: any) => {
    const user = state.user;
    if (user) {
      return JSON.parse(user.user);
    }
    return null;
  });

  const { data: policyDetails }: { data?: any } = useGetPolicyDetailsQuery(userProof.policy);

  return (
        <>
            <Card sx={{
              m: 1,
            }}>
                <CardContent>
                    <Typography variant="body1" fontWeight="800">
                        {policyDetails?.title || 'Policy Title'}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} fontSize="small" color="text.secondary">
                        {userProof?.createdAt ? formatDate(new Date(userProof?.createdAt)) : '---'}
                    </Typography>
                    <Typography variant="body2">
                        {policyDetails?.short || 'Policy Short Description'}
                    </Typography>
                </CardContent>
                <CardActions>
                    <IconButton
                        component={RouterLink}
                        to={`/verify/${userProof._id}`}
                    >
                        <PageviewIcon />
                    </IconButton>
                    {userInfo && userFeatures &&
                        <IconButton
                            component={RouterLink}
                            to={`/verify/${userProof._id}`}
                            color="error"
                        >
                            <DeleteIcon />
                        </IconButton>
                    }
                </CardActions>
            </Card>
        </>
  );
}