import { ProofURL } from '../constants';
import { apiSlice } from './apiSlice';

export const proofApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProofs: builder.query({
      query: () => ({
        url: ProofURL,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    getProofDetails: builder.query({
      query: (id: string) => ({
        url: `${ProofURL}/${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    createProof: builder.mutation({
      query: (proofData: any) => ({
        url: ProofURL,
        method: 'POST',
        body: { ...proofData },
      }),
    }),
    getProofsByIdentity: builder.query({
      query: (identity: string) => ({
        url: `${ProofURL}/identity/${identity}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    getProofsByPolicy: builder.query({
      query: (policy: string) => ({
        url: `${ProofURL}/policy/${policy}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    getProofsByUser: builder.query({
      query: (user) => ({
        url: `${ProofURL}/user/${user}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    getUserProofsAndReports: builder.query({
      query: () => ({
        url: `${ProofURL}/user/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useGetProofsQuery,
  useGetProofDetailsQuery,
  useCreateProofMutation,
  useGetProofsByIdentityQuery,
  useGetProofsByPolicyQuery,
  useGetUserProofsAndReportsQuery,
  useGetProofsByUserQuery,
} = proofApiSlice;
