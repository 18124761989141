import React, { useState } from 'react';
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import NotesIcon from '@mui/icons-material/Notes';
import SendIcon from '@mui/icons-material/Send';
import BuildIcon from '@mui/icons-material/Build';
import { grey } from '@mui/material/colors';
import { IKey, IPolicy, ICircuit } from '../types';
import {
  initCircuit,
  generateWitness,
  generateProof,
  stringToIntegerHash,
  padHexString,
  cleanProofFromPublicData,
} from '../utils/zkcircuits';
import { useSelector } from 'react-redux';
import PreviewProof from './PreviewProof';
import { useCreateProofMutation } from '../slices/proofApiSlice';

export default function PolicyCreatorForm({ policyData, circuitData }: { policyData: IPolicy, circuitData: ICircuit | undefined }) {
  const initialKeys: Record<string, string> = {};
  policyData.keys.forEach((key: IKey) => {
    initialKeys[key.var_name] = '';
  });

  const [keys, setKeys] = useState(initialKeys);
  const [loading, setLoading] = useState(false);
  const [finalProof, setFinalProof] = useState(undefined);
  const [submissionError, setSubmissionError] = useState(undefined);
  const [proof, setProof] = useState<string | undefined>(undefined);
  const [createProof, { isLoading }] = useCreateProofMutation();

  const userInfo = useSelector((state: any) => {
    const user = state.user;
    if (user) {
      return JSON.parse(user.user);
    }
    return null;
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setKeys({ ...keys, [name]: value });
  };

  const buildProof = (e: React.FormEvent<HTMLFormElement>,
  ) => {
    // @audit current (potential) issues:
    // - if the proof fails, the constant error is "could not satisfy all constraints", can't capture?
    // - any time a proof is requested, a new circuit is generated, which is not necessary
    // - each key should have a type, validated against the circuit and submitted accordingly
    // - need to remove public inputs from proof
    e.preventDefault();
    setLoading(true);

    try {
      console.log('Performing action with keys:', keys);
      initCircuit(circuitData!.circuit.bytecode).then(async ({ api, acirComposer, acirBuffer, acirBufferUncompressed }) => {
        const input: any = [];
        const promises: Promise<any>[] = [];
        let numberOfPublicInputs = 0;
        Object.entries(keys).forEach(([key, value]) => {
          if (policyData.keys.find((k: IKey) => k.var_name === key)?.visibility === 'public') {
            numberOfPublicInputs++;
          }
          const promise = stringToIntegerHash(value).then((digest) => {
            input.push(padHexString(digest, 32, true));
          });
          promises.push(promise);
        });

        await Promise.all(promises);

        const witness = await generateWitness(input, acirBuffer);
        console.log('Witness generated!');
        console.log(witness);

        const localProof = await generateProof(witness, acirComposer, api, acirBufferUncompressed);
        // console.log("Proof generated!");
        // console.log(proof);
        // console.log("Removing public inputs from proof: " + numberOfPublicInputs);
        const cleanedProof = await cleanProofFromPublicData(localProof, numberOfPublicInputs);
        // console.log("Cleaned proof:");
        // console.log(cleanedProof);
        setProof(cleanedProof);
        // setProof(proof);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }

  };

  const submitProof = async () => {
    const proofData = {
      policy: policyData._id,
      circuit: circuitData!._id,
      proof: proof,
      whatever: 'whatever',
    };
    console.log('Submitting proof:', proofData);
    try {
      const res = await createProof(proofData).unwrap();
      setFinalProof(res);
      setSubmissionError(undefined);
    } catch (error: any) {
      console.log(error);
      if (error?.data?.message)
        setSubmissionError(error.data.message);
    }
  };

  return (
        <>
            <Card>
                <CardContent >
                    <Typography variant="body2"
                        gutterBottom style={{ textAlign: 'right' }}
                        fontSize="small" color="text.secondary">
                        Policy Information
                    </Typography>
                    <Stack spacing={2} direction="row" sx={{ marginTop: 2 }}>
                        <Stack spacing={1}>
                            <Typography variant="h5" fontWeight="bold">
                                {policyData.title}
                            </Typography>
                        </Stack>
                        <Divider orientation="vertical" flexItem />
                        <Stack textAlign="left" spacing={1}>
                            <Stack direction="row" spacing={1}>
                                <Avatar sx={{ bgcolor: 'white', width: 24, height: 24 }} >
                                    <NotesIcon sx={{ color: grey[800] }} />
                                </Avatar>
                                <Typography variant="body1">{policyData.description}</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body2" gutterBottom style={{ textAlign: 'right' }}
                        fontSize="small" color="text.secondary">
                        Proof specification
                    </Typography>
                    <form onSubmit={buildProof}>
                        <Stack
                            key="proof-inputs"
                            spacing={2}
                            direction="row"
                            sx={{ marginTop: 2, width: '100%' }}>
                            <Stack key="private-inputs" spacing={2} textAlign="left" sx={{ width: 'inherit' }} >
                                <Typography variant="body2"
                                    fontWeight="bold">
                                    Private Inputs
                                </Typography>
                                {policyData.keys.map((key: IKey) => (
                                  key.visibility === 'private' &&
                                    <TextField
                                        required
                                        key={key.var_name}
                                        name={key.var_name}
                                        label={key.name}
                                        helperText={key.help}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={keys[key.var_name]}
                                        onChange={handleInputChange}
                                    />
                                ))}
                            </Stack>
                            <Divider orientation="vertical" flexItem />
                            <Stack key="public-inputs" textAlign="left" spacing={2} sx={{ width: 'inherit' }}>
                                <Typography variant="body2" fontWeight="bold">
                                    Public Inputs
                                </Typography>
                                {policyData.keys.map((key: IKey) => (
                                  key.visibility === 'public' && <TextField
                                        required
                                        key={key.var_name}
                                        name={key.var_name}
                                        label={key.name}
                                        helperText={key.help}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={keys[key.var_name]}
                                        onChange={handleInputChange}
                                    />
                                ))}
                            </Stack>
                        </Stack>


                        <Stack direction="row" spacing={1} justifyContent={circuitData ? 'flex-end' : 'center'}
                            sx={{ marginTop: 4 }}>
                            {
                                circuitData &&
                                <>
                                    {loading && <CircularProgress
                                        size={30}
                                    />}
                                    <Button type="submit" variant="contained"
                                        endIcon={<BuildIcon />} color="primary" disabled={loading}>
                                        Build
                                    </Button>
                                </>
                            }
                            {
                                !circuitData &&
                                <>
                                    <Alert severity="warning">This policy is template only, no circuit is available.</Alert>
                                </>
                            }
                        </Stack>
                    </form>
                </CardContent>
            </Card>
            {proof &&
                <Card sx={{ marginTop: 4 }}>
                    <CardContent>
                        <Stack spacing={2} >
                            <Stack direction="column" sx={{ marginTop: 2 }}>
                                <Stack textAlign="left" spacing={1}>
                                    <Typography variant="body2" fontWeight="bold">
                                        Proof Details:
                                    </Typography>
                                    <Stack direction="row" justifyContent="flex-start">
                                        <PreviewProof proofData={proof} />
                                    </Stack>
                                </Stack>
                                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                    {
                                        finalProof &&
                                        <Alert severity="success">Proof submitted successfully!</Alert>
                                    }
                                    {
                                        submissionError &&
                                        <Alert severity="error">
                                            {submissionError}
                                        </Alert>
                                    }
                                    {isLoading && <CircularProgress
                                        size={30}
                                    />}
                                    <Button type="submit" variant="contained" onClick={submitProof}
                                        endIcon={<SendIcon />} disabled={!userInfo || userInfo.isAnonymous || !userInfo.emailVerified}>
                                        Publish
                                    </Button>
                                </Stack>
                            </Stack>
                            <Stack justifyContent="center">
                                {!userInfo &&
                                    <Alert severity="warning">Authenticate before submitting proofs!</Alert>
                                }
                                {userInfo?.isAnonymous &&
                                    <Alert severity="warning">You're anonymous! Create an account before submitting proofs.</Alert>
                                }
                                {userInfo && !userInfo.emailVerified &&
                                    <Alert severity="warning">Your e-mail is not verified! Verify it before submitting proofs.</Alert>
                                }
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
            }
        </>
  );
}