import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useGetActivityLogQuery } from '../slices/activityApiSlice';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CreateIcon from '@mui/icons-material/Create';
import BugReportIcon from '@mui/icons-material/BugReport';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import { dateAndHour } from '../utils/dates';
import { Typography } from '@mui/material';

function messageParser(message: string) {
  switch (message) {
    case 'proof-created':
      return 'Proof created';
    case 'proof-deleted':
      return 'Proof deleted';
    case 'verification-valid':
      return 'Verification valid';
    case 'verification-invalid':
      return 'Verification invalid';
    case 'report-created':
      return 'Report created';
    case 'report-handled':
      return 'Report solved';
    case 'profile-updated':
      return 'Profile updated';
    case 'account-created':
      return 'Account created';
    case 'data-updated':
      return 'Data updated';
    default:
      return 'Unknown activity';
  }
}

export default function UserActivity() {

  const { data, isLoading } = useGetActivityLogQuery(1);

  return (
        <>
            {isLoading && <div>Loading...</div>}
            {data &&
                <Timeline >
                    {data.records.map((activity: any) => (
                        <TimelineItem key={
                            activity.date
                        }>
                            {/* <TimelineOppositeContent color="text.secondary" >
                            </TimelineOppositeContent> */}
                            <TimelineSeparator>
                                <TimelineDot color="primary">
                                    {activity.action === 'account-created' && <PersonAddIcon />}
                                    {activity.action === 'proof-created' && <CreateIcon />}
                                    {activity.action === 'report-created' && <BugReportIcon />}
                                    {activity.action === 'report-handled' && <MarkEmailReadIcon />}
                                    {activity.action === 'data-updated' && <AutoModeIcon />}
                                </TimelineDot>
                                {activity.action !== 'account-created' && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography>
                                    {messageParser(activity.action)}
                                </Typography>
                                {/* <Divider /> */}
                                <Typography color="textSecondary" variant="body2">
                                    {dateAndHour(new Date(activity.date))}
                                </Typography>
                            </TimelineContent>

                        </TimelineItem>
                    ))}
                </Timeline>
            }
        </>
  );
}