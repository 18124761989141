import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, TextField, Stack, Alert, Snackbar, AlertTitle, Box } from '@mui/material';
import {
  useGetProofPathQuery,
} from '../slices/merkleApiSlice';
import { dateAndHour } from '../utils/dates';
import { UserMerkleLeaf } from '../utils/userMerkle';
import { useState } from 'react';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import styled from '@emotion/styled';
import {
  digestWithSalt,
  verifyProof,
} from '../utils/userMerkle';
import {
  usePostVerificationSuccessWithSubRefMutation,
  usePostVerificationFailedWithSubRefMutation,
} from '../slices/reportsApiSlice';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface LeafProof {
  leafSalt: string;
  proof: any[];
}

export default function DataVerifier(
  { userId, leaf, root, show, treeRef, dialHandler }:
  { userId: string, leaf: UserMerkleLeaf, root: string, show: boolean, treeRef: string, dialHandler: () => void },
) {
  const { data: proof } = useGetProofPathQuery({ userId: userId, leafId: leaf._id });
  const [result, setResult] = useState<boolean>(false); // replace any with the actual type of result
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [postVerificationSuccess] = usePostVerificationSuccessWithSubRefMutation();
  const [postVerificationFailed] = usePostVerificationFailedWithSubRefMutation();

  const handleCloseUserLeaf = () => {
    dialHandler();
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleFileName(event: React.ChangeEvent<HTMLInputElement>) {
    setFileName(event.target.files?.item(0)?.name);
  }

  const verifyLeaf = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formData = new FormData(form);
    const proofData = proof as LeafProof; // add this line to assert the type of proof

    const fixedArray = proofData.proof.map(obj => ({
      position: obj.position,
      data: new Uint8Array(obj.data.data),
    }));

    digestWithSalt(formData.get('dataValue') as string, proofData.leafSalt).then((digest) => {
      console.log(fixedArray, digest.hash, root);
      verifyProof(fixedArray, digest.hash, root).then((returnedResult) => {
        handleCloseUserLeaf();
        setResult(returnedResult);
        setOpen(true);
        if (returnedResult) {
          postVerificationSuccess({
            refId: treeRef,
            subRefId: leaf._id as string,
          });
        } else {
          postVerificationFailed({
            refId: treeRef,
            subRefId: leaf._id as string,
          });
        }
      });
    });
  };

  return (<>
    <Dialog
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
      onClose={handleCloseUserLeaf}
    >
      <DialogTitle id="alert-dialog-title">Lock Data</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          <b>Tag: </b>{leaf?.tag}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Description: </b>{leaf?.description}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Created At: </b>{leaf ? dateAndHour(new Date(leaf.date)) : '--'}
        </Typography>
        {/* <Typography variant="body1" gutterBottom>
                    <b>Format: </b>{leaf?.fileName ? 'File' : 'Value'}
                </Typography> */}
      </DialogContent>
      <DialogTitle id="alert-dialog-test-it">Verify data</DialogTitle>
      <form onSubmit={verifyLeaf}>
        <DialogContent>
          {!leaf?.isFile ? (<TextField
            autoFocus
            id="data-value"
            aria-labelledby="data-label"
            // label="Description"
            name='dataValue'
            type="text"
            fullWidth
            variant="filled"
            multiline
            maxRows={20}
            defaultValue={''}
          // onChange={(e) => setReportText(e.target.value)}
          />) :
            (
              <Stack direction='row' spacing={2} alignItems='center'>
                <Button component="label" variant="contained" sx={{
                  maxWidth: '200px',
                }} startIcon={<NoteAddIcon />}>
                  Select file
                  <VisuallyHiddenInput
                    type="file"
                    name='dataValue'
                    onChange={handleFileName}
                  />
                </Button>
                <Typography variant="body1" fontSize='small' gutterBottom>
                  {fileName}
                </Typography>
              </Stack>
            )}
        </DialogContent>
        <DialogActions>
          <Button type='submit'>Verify</Button>
        </DialogActions>
      </form>
    </Dialog>
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Box textAlign='left' >
        {result ?
          <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            <AlertTitle>Success</AlertTitle>
            Data related to <strong>{leaf.tag}</strong> was correctly verified.
          </Alert>
          :
          <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            <AlertTitle>Error</AlertTitle>
            Verification related to <strong>{leaf.tag}</strong> failed.
          </Alert>
        }
      </Box>
    </Snackbar>
  </>);
}
