import { PolicyURL } from '../constants';
import { apiSlice } from './apiSlice';

export const policyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder: any) => ({
    getPolicies: builder.query({
      query: () => ({
        url: PolicyURL,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    getPolicyDetails: builder.query({
      query: (id: string) => ({
        url: `${PolicyURL}/${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const { useGetPoliciesQuery, useGetPolicyDetailsQuery } = policyApiSlice;