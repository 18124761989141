import { MerkleTree } from 'merkletreejs';
// eslint-disable-next-line import/no-extraneous-dependencies
import SHA256 from 'crypto-js/sha256';

export type UserMerkleLeaf = {
  _id?: string,
  tag: string,
  tagType: string,
  hash: string,
  order: number,
  date: string,
  salt: string,
  description?: string,
  fileName?: string,
  isFile?: boolean,
};

export type UserMerkeTree = {
  root: string,
  createdAt: string,
  updatedAt: string,
  _id: string,
  leaves: UserMerkleLeaf[]
};

export const generateSecureString = (length: number) => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // Define the character set
  const randomValues = new Uint8Array(length); // Create an array to store random values
  crypto.getRandomValues(randomValues); // Fill the array with random values

  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = randomValues[i] % charset.length;
    result += charset.charAt(randomIndex);
  }

  return result;
};

//build a merkle from existing leaves, new leaf and salt
export const buildUserMerkleTree = async (leaves: UserMerkleLeaf[]): Promise<{ root: string; tree: MerkleTree; }> => {
  //sort leaves by order
  const leavesHashes = leaves.map(leaf => leaf.hash);
  const tree = new MerkleTree(leavesHashes, SHA256);
  const root = tree.getRoot().toString('hex');

  return {
    root,
    tree,
  };
};

export const verifyRoot = async (leaves: UserMerkleLeaf[], root: string): Promise<boolean> => {
  const leavesHashes = leaves.map(leaf => leaf.hash);
  const tree = new MerkleTree(leavesHashes, SHA256);
  return tree.getRoot().toString('hex') === root;
};

export const verifyLeaf = async (leaves: UserMerkleLeaf[], leaf: UserMerkleLeaf, root: string): Promise<boolean> => {
  const leavesHashes = leaves.map(l => l.hash);
  const tree = new MerkleTree(leavesHashes, SHA256);
  const proof = tree.getProof(leaf.hash);
  console.log(proof);
  console.log(tree.getRoot().toString('hex'));
  return tree.verify(proof, leaf.hash, root);
};

export const verifyPath = async (leaves: UserMerkleLeaf[], leaf: UserMerkleLeaf, path: string[]): Promise<boolean> => {
  const leavesHashes = leaves.map(l => l.hash);
  const tree = new MerkleTree(leavesHashes, SHA256);
  return tree.verify(path, leaf.hash, tree.getRoot().toString('hex'));
};

export const verifyProof = async (proof: any, hash: string, root: string): Promise<boolean> => {
  const tree = new MerkleTree([], SHA256);
  return tree.verify(proof, hash, root);
};

export const otherMethods = async (leaves: UserMerkleLeaf[]): Promise<boolean> => {
  const leavesHashes = leaves.map(leaf => leaf.hash);
  const tree = new MerkleTree(leavesHashes, SHA256);

  console.log(tree.getHexLayers());
  // console.log(tree.getPositionalHexProof(leavesHashes[4]));

  return true;
};

export const digestWithSalt = async (data: string | File, salt?: string): Promise<{ hash: string, salt: string }> => {
  let dataToHash: ArrayBuffer;
  salt = salt || generateSecureString(32);

  if (typeof data === 'string') {
    dataToHash = new TextEncoder().encode(data);
  } else {
    dataToHash = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => {
        if (event.target && event.target.result) {
          resolve(event.target.result as ArrayBuffer);
        } else {
          reject(new Error('FileReader event target is null'));
        }
      };
      reader.onerror = error => reject(error);
      reader.readAsArrayBuffer(data);
    });
  }

  const saltBytes = new TextEncoder().encode(salt);
  const dataWithSalt = new Uint8Array(dataToHash.byteLength + saltBytes.byteLength);
  dataWithSalt.set(new Uint8Array(dataToHash), 0);
  dataWithSalt.set(saltBytes, dataToHash.byteLength);

  const digestBuffer = await window.crypto.subtle.digest('SHA-256', dataWithSalt);
  const digestArray = new Uint8Array(digestBuffer);
  const digest = Array.from(digestArray).map(b => b.toString(16).padStart(2, '0')).join('');

  return { hash: digest, salt };
};
