import { ReportsURL } from '../constants';
import { apiSlice } from './apiSlice';

export const reportsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    postVerificationSuccess: builder.mutation({
      query: (refId: string) => ({
        url: `${ReportsURL}/ref/${refId}/valid`,
        method: 'POST',
      }),
    }),
    postVerificationFailed: builder.mutation({
      query: (refId: string) => ({
        url: `${ReportsURL}/ref/${refId}/invalid`,
        method: 'POST',
      }),
    }),
    postVerificationSuccessWithSubRef: builder.mutation({
      query: ({ refId, subRefId }: { refId: string, subRefId: string }) => ({
        url: `${ReportsURL}/ref/${refId}/valid/${subRefId}`,
        method: 'POST',
      }),
    }),
    postVerificationFailedWithSubRef: builder.mutation({
      query: ({ refId, subRefId }: { refId: string, subRefId: string }) => ({
        url: `${ReportsURL}/ref/${refId}/invalid/${subRefId}`,
        method: 'POST',
      }),
    }),
    submitReport: builder.mutation({
      query: ({ refId, report }: { refId: string, report: any }) => ({
        url: `${ReportsURL}/ref/${refId}/report`,
        method: 'POST',
        body: { ...report },
      }),
    }),
    markAsSolve: builder.mutation({
      query: ({ reportsID, reportID }: { reportsID: string, reportID: string }) => ({
        url: `${ReportsURL}/${reportsID}/${reportID}/solve`,
        method: 'POST',
      }),
    }),
    getReports: builder.query({
      query: (refId: string) => ({
        url: `${ReportsURL}/ref/${refId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  usePostVerificationSuccessMutation,
  usePostVerificationFailedMutation,
  usePostVerificationSuccessWithSubRefMutation,
  usePostVerificationFailedWithSubRefMutation,
  useSubmitReportMutation,
  useMarkAsSolveMutation,
  useGetReportsQuery,
} = reportsApiSlice;
