import { TableContainer, Button, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Link, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { RootState } from '../store';
import { FileAnalysis } from '../utils/fileType';
import { dateAndHour } from '../utils/dates';

interface FilesListProps {
  filter: string;
  deleteHash: (email: string) => void;
  refreshHash: (email: string) => void;
}

export default function EmailsList({ filter, deleteHash, refreshHash }: FilesListProps) {


  const listHashes = useSelector((state: RootState) => { return state.files.filesOsint || []; });


  const filteredEmails = useMemo(() => {
    if (filter) {
      return listHashes.filter((email: any) => email.email.includes(filter));
    }
    return listHashes;
  }, [listHashes, filter]);

  return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 700 }}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>File Hash (sha256)</TableCell>
                            <TableCell align="left">Type</TableCell>
                            <TableCell align="center">Risk</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ maxHeight: '500px', overflow: 'auto' }}>
                        {filteredEmails.map((hash: FileAnalysis) => (
                            <TableRow
                                key={hash._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Link component={Button} onClick={() => console.log('Clicked on:', hash.fileHash)}>
                                        <Typography style={{ fontFamily: 'monospace' }}>
                                            {hash.fileHash.length > 12 ? `${hash.fileHash.substring(0, 12)}...` : hash.fileHash}
                                        </Typography>
                                    </Link>                     
                                </TableCell>
                                <TableCell align="left">
                                    {hash.analysis.data.type !== 'unknown' ? <>
                                    {/* <Typography>First submission: {dateAndHour(new Date(hash.analysis.data.attributes.first_submission_date))}</Typography> */}
                                    <Typography>Last analysis: {dateAndHour(new Date(hash.analysis.data.attributes.last_analysis_date))}</Typography>
                                    <Typography>Meaningful name: {hash.analysis.data.attributes.meaningful_name}</Typography>
                                    <Typography>File type: {hash.analysis.data.attributes.type_tags?.join(', ')}</Typography>
                                    <Typography>Description: {hash.analysis.data.attributes.magic}</Typography>
                                    <Typography>Reputation: {hash.analysis.data.attributes.reputation}</Typography>
                                    <Typography>Size: {hash.analysis.data.attributes.size}</Typography>
                                    </> : 'Unknown file hash'}
                                </TableCell>
                                <TableCell align="center">
                                    {hash.analysis.data.type !== 'unknown' ? <>
                                    { hash.analysis.data.attributes.last_analysis_stats.malicious > 0 ? 'High' :
                                      hash.analysis.data.attributes.last_analysis_stats.suspicious > 0 ? 'Medium' : 'Low'}
                                      </> : 'Unknown'}
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => refreshHash(hash.fileHash)}>
                                        <RefreshRoundedIcon />
                                    </IconButton>
                                    <IconButton onClick={() => deleteHash(hash.fileHash)}>
                                        <DeleteRoundedIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
  );
}