import { useParams } from 'react-router-dom';
import { IPolicy, ICircuit } from '../types';
import { useEffect, useState } from 'react';
import axios from 'axios';
import PolicyCreatorForm from '../components/PolicyCreatorForm';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// import { useGetPolicyDetailsQuery } from '../slices/policyApiSlice';
// import { useGetCircuitDetailsQuery } from '../slices/circuitApiSlice';
import { Box, Button } from '@mui/material';

export default function Prove() {

  const [policyData, setPolicyData] = useState<IPolicy>();
  const [circuitData, setCircuitData] = useState<ICircuit>();

  const { id } = useParams();

  // const { data: policyDetails, isLoading: policyDetailsIsLoading, error: policyDetailsError } = useGetPolicyDetailsQuery(id);
  // const navigate = useNavigate(); // navigate('/verify/123?name=John')
  // const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const policyResponse = await axios.get('/api/v1/policies/' + id);
        setPolicyData(policyResponse.data);
      } catch (error) {
        // Handle errors
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!policyData) return;

        const circuitResponse = await axios.get('/api/v1/circuits/policy/' + policyData._id);
        setCircuitData(circuitResponse.data);
      } catch (error) {
        // Handle errors
        console.log('template only');
      }
    };

    fetchData();
  }, [policyData]);

  return (
        <>
            <Box sx={{
              pb: 2,
              display: 'flex',
              flexDirection: 'row',

            }}>
                <Button
                    startIcon={<KeyboardBackspaceIcon fontSize='medium' />}
                    component={RouterLink}
                    color='inherit'
                    to="/policies"
                    sx={{
                      textAlign: 'left',
                      justifyContent: 'flex-start',
                    }}
                >
                    Search other policies
                </Button>
            </Box>
            {policyData &&
                <PolicyCreatorForm policyData={policyData} circuitData={circuitData} />
            }
        </>
  );
}