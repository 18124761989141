import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { retrieveIOCs } from '../utils/emailTypes';
import CellHeader from './email-utils/CellHeader';
import CellRiskScore from './email-utils/CellRiskScore';
import CellWarning from './email-utils/CellWarning';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

interface EmailsListProps {
  filter: string;
  deleteEmail: (email: string) => void;
  refreshEmail: (email: string) => void;
}

export default function EmailsList({ filter, deleteEmail, refreshEmail }: EmailsListProps) {

  const listEmails = useSelector((state: any) => {
    if (state?.emails?.emailsOsint) {
      return state.emails.emailsOsint;
    }
    return [];
  });
    
  const filteredEmails = useMemo(() => {
    if (filter) {
      return listEmails.filter((email: any) => email.email.includes(filter));
    }
    return listEmails;
  }, [listEmails, filter]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell align="center">Risk Score</TableCell>
                    <TableCell align="left">Warnings</TableCell>
                    <TableCell align="right">Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody sx={{ maxHeight: '500px', overflow: 'auto' }}>
                {filteredEmails.map((email: any) => (
                    <TableRow
                        key={email._id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            <CellHeader
                                email={email.email}
                                lastUpdated={email.updatedAt}
                                registered={email.analysis.first_seen.human}
                                countryCode={email.analysis.country_code}
                                languageCode={email.analysis.language_code}
                            />
                        </TableCell>
                        <TableCell align="center">
                            <CellRiskScore riskScore= {email.analysis.fraud_score} />
                        </TableCell>
                        <TableCell align="left">
                            <CellWarning warnings={retrieveIOCs(email)} />
                        </TableCell>
                        <TableCell align="right">
                            <IconButton onClick={() => refreshEmail(email.email)} aria-label="analysis-refresh">
                                <RefreshRoundedIcon />
                            </IconButton>
                            <IconButton onClick={() => deleteEmail(email.email)} aria-label="analysis-deleteEmail">
                                <DeleteRoundedIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    </Paper>
  );
}