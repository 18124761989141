function dateAndHour(date: Date): string {
  if (!date) return '---';
  return date.toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });
}

export {
  dateAndHour,
};