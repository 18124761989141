import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInAnonymously,
  signOut,
} from 'firebase/auth';
import { useState } from 'react';
import {
  setUser,
  setUserNotifications,
  clearUser,
} from '../slices/userSlice';
import { reset as resetDashboard } from '../slices/dashboardSlice';
import { reset as resetMerkle } from '../slices/merkleSlice';
import { reset as resetProfile } from '../slices/profileSlice';
import { apiSlice } from '../slices/apiSlice';
import { useDispatch } from 'react-redux';
import {
  NotificationTypes,
  UserNotification,
} from '../types';

import { createAsyncThunk } from '@reduxjs/toolkit';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyDVFCwjqgX7VgM7bcusQEEkAuJ8g8H-7z8",
//     authDomain: "invisiblelabs-strata.firebaseapp.com",
//     projectId: "invisiblelabs-strata",
//     storageBucket: "invisiblelabs-strata.appspot.com",
//     messagingSenderId: "294613177555",
//     appId: "1:294613177555:web:80bc2762a25926ac45f026"
// };

const firebaseConfig = {
  apiKey: 'AIzaSyC9tLoLGcZuQET9LpyPJ0C8X2FamA4fBcs',
  authDomain: 'strataprotectionpp.firebaseapp.com',
  projectId: 'strataprotectionpp',
  storageBucket: 'strataprotectionpp.appspot.com',
  messagingSenderId: '91748722195',
  appId: '1:91748722195:web:4a4fb48c02856c071a81e6',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export default function useAuthentication() {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const dispatch = useDispatch();

  //validate if user has verified email, disabled, mfa, etc
  const createNotification = async () => {

    const userNotifications: UserNotification[] = [];
    const user = auth.currentUser;
    if (user?.isAnonymous) {
      userNotifications.push({
        type: NotificationTypes.ANONYMOUS_ACCOUNT,
        message: "You're anonymous! Most features are disabled.",
      });
    } else {
      if (!user?.emailVerified) {
        userNotifications.push({
          type: NotificationTypes.EMAIL_NOT_VERIFIED,
          message: 'Please verify your email, or you may lose access to your account.',
        });
        userNotifications.push({
          type: NotificationTypes.APP_LINK,
          message: 'Checkout the new features of the brand new Open Verification. You can now search for public proofs and policies!',
          to: '/open-verification',
          text: 'Try it!',
        });
      }
    }

    //add a notification with today's date and hour
    const today = new Date();
    const todayString = today.toDateString();
    const todayHour = today.getHours();
    const todayMinute = today.getMinutes();
    const todaySecond = today.getSeconds();
    const todayMillisecond = today.getMilliseconds();
    const todayTimestamp = today.getTime();
    userNotifications.push({
      type: NotificationTypes.NO_ACTION,
      message: `Welcome to Strata! Today is ${todayString} ${todayHour}:${todayMinute}:${todaySecond}:${todayMillisecond} ${todayTimestamp}`,
    });

    dispatch(setUserNotifications(userNotifications));
  };

  const login = async (email: string, password: string) => {
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      dispatch(setUser(JSON.stringify(user)));
      await createNotification();
    } catch (errorLogin: any) {
      if (errorLogin.code === 'auth/user-disabled') {
        setError('Your account has been disabled. Please contact support for assistance.');
      } else if (errorLogin.code === 'auth/user-not-found') {
        setError('User not found. Please check your email and password and try again.');
      } else if (errorLogin.code === 'auth/wrong-password') {
        setError('Incorrect password. Please check your email and password and try again.');
      } else {
        setError('Something went wrong. Please try again.');
      }
    }
    setLoading(false);
  };

  const signup = async (email: string, password: string) => {
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      dispatch(setUser(JSON.stringify(user)));
      await sendEmailVerification(user);
      await createNotification();
    } catch (errorSignup: any) {

      console.log(errorSignup);
      if (errorSignup.code === 'auth/missing-password') {
        setError('Please insert both e-mail and password.');
      } else if (errorSignup.code === 'auth/missing-email') {
        setError('Please insert both e-mail and password.');
      } else if (errorSignup.code === 'auth/invalid-email') {
        setError('Please insert a valid email.');
      } else if (errorSignup.code === 'auth/wrong-password') {
        setError('Incorrect password. Please check your email and password and try again.');
      } else {
        setError('Something went wrong. Please try again.');
      }
    }
    setLoading(false);
  };

  const logout = async () => {
    setLoading(true);
    try {
      await signOut(auth);
      dispatch(clearUser());
      dispatch(resetDashboard());
      dispatch(resetMerkle());
      dispatch(resetProfile());
      dispatch(apiSlice.util.resetApiState());
    } catch (errorLogout: any) {
      setError(errorLogout.message);
    }
    setLoading(false);
  };

  const resetPassword = (email: string) => async () => {
    console.log('reset called');
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (errorRP: any) {
      setError(errorRP.message);
    }
    setLoading(false);
  };

  const verifyEmail = async () => {
    console.log('verify called');
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        await sendEmailVerification(user);
      }
    } catch (errorVE: any) {
      setError(errorVE.message);
    }
    setLoading(false);
  };

  const anonymousLogin = async () => {
    setLoading(true);
    try {
      const userCredential = await signInAnonymously(auth);
      const user = userCredential.user;
      dispatch(setUser(JSON.stringify(user)));
      await createNotification();
    } catch (errorAL: any) {
      setError(errorAL.message);
    }
    setLoading(false);
  };

  return {
    login,
    signup,
    logout,
    resetPassword,
    verifyEmail,
    anonymousLogin,
    loading,
    error,
  };
}

export const refreshTokenId = async () => {
  try {
    const user = auth.currentUser;

    if (user) {
      const userTokenInfo = await user.getIdTokenResult();
      const currentTime = Math.floor(Date.now() / 1000);
      const tokenExpirationTime = Math.floor(new Date(userTokenInfo.expirationTime).getTime() / 1000);
      const timeToExpire = tokenExpirationTime - currentTime;
      const threshold = 300; // 5 minutes in seconds

      if (timeToExpire < threshold) {
        const res = await user.getIdToken(true);
        console.log('Token refreshed.', res);
        return {
          renewed: true,
          user: auth.currentUser,
        };
      } else {
        console.log('Token is still valid. No need to refresh.', timeToExpire, ' - ', threshold);
        return {
          renewed: false,
          user: null,
        };
      }
    }
  } catch (error) {
    console.log(error);
    return {
      renewed: false,
      user: null,
    };
  }
};

export const refreshToken = createAsyncThunk('auth/refreshToken', async (_, { dispatch }) => {
  try {
    const user = auth.currentUser;
    if (user) {

      const userTokenInfo = await user.getIdTokenResult();
      const currentTime = Math.floor(Date.now() / 1000);
      const tokenExpirationTime = Math.floor(new Date(userTokenInfo.expirationTime).getTime() / 1000);
      const timeToExpire = tokenExpirationTime - currentTime;
      const threshold = 300; // 5 minutes in seconds

      if (timeToExpire < threshold) {
        const res = await user.getIdToken(true);
        console.log('Token refreshed.', res);
        dispatch(setUser(user));

      } else {
        console.log('Token is still valid. No need to refresh.', timeToExpire, ' - ', threshold);
      }
    }
  } catch (error) {
    console.error(error);
  }
});
