import { createSlice } from '@reduxjs/toolkit';

const userMerkleStorage = localStorage.getItem('userMerkle');

const initialState = {
  userMerkle: userMerkleStorage ? userMerkleStorage : null,
};

const dashboardSlice = createSlice({
  name: 'UserMerkleSlice',
  initialState,
  reducers: {
    setUserMerkle: (state, action) => {
      state.userMerkle = action.payload;
      localStorage.setItem('userMerkle', action.payload);
    },
    reset: (state) => {
      state.userMerkle = null;
      localStorage.clear();
    },
  },
});

export const {
  setUserMerkle,
  reset,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;