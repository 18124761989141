import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import BugReportIcon from '@mui/icons-material/BugReport';
import InventoryIcon from '@mui/icons-material/Inventory';
import SettingsIcon from '@mui/icons-material/Settings';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DatasetIcon from '@mui/icons-material/Dataset';
import useAuthentication from '../auth/fbase';
import { Badge, Divider, Drawer, Stack } from '@mui/material';
import logo from '../assets/images/mzstratalogoc_opt.svg';
import DomainIcon from '@mui/icons-material/Domain';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import PhishingIcon from '@mui/icons-material/Phishing';

function Sidebar() {
  // const navigate = useNavigate();
  const location = useLocation();
  const userInfo = useSelector((state: any) => {
    const user = state.user;
    if (user) {
      return JSON.parse(user.user);
    }
    return null;
  });
  const numberOfUnhandledReports = useSelector((state: any) => {
    const user = state.user;
    if (user) {
      return user.unhandledReports;
    }
    return null;
  });

  const { logout } = useAuthentication();

  const logoutHandler = async () => {
    try {
      // await logoutApi(undefined).unwrap(); 
      //@audit alternative to async logout so we can redirect to sign up page
      logout();
    } catch (error) {
      console.error(error);
    }
  };

  return (
        <>
            <Drawer
                PaperProps={{
                  sx: {
                    backgroundColor: '#eaeaea',
                    width: 250,
                  },
                }}
                sx={{ flexShrink: 0 }}
                variant="permanent"
            >
                <Box
                    flexGrow={1}
                    display="flex"
                    flexDirection="column"
                >
                    <Stack px={3} py={1} rowGap={1} flexGrow={1} >
                        <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              // justifyContent: 'center',
                              // width: '100%',
                            }}
                        >
                            <Link component={RouterLink} to="/" sx={{
                              mt: 3,
                              mb: 3,
                              height: 64,
                              width: 96,
                            }}>
                                <img src={logo} alt="logo" style={{ height: '100%', width: '100%' }} />
                            </Link>
                        </Box>
                        <Divider orientation='horizontal' sx={{
                          mb: 3,
                        }} />
                        <Button
                            startIcon={<ManageSearchIcon fontSize='medium' />}
                            component={RouterLink}
                            color={location.pathname === '/open-verification' ? 'primary' : 'inherit'}
                            to="/open-verification"
                            sx={{
                              textAlign: 'left',
                              justifyContent: 'flex-start',
                            }}
                        >
                            Open Verification
                        </Button>
                        <Button
                            startIcon={<InventoryIcon fontSize='medium' />}
                            component={RouterLink}
                            color={location.pathname === '/policies' ? 'primary' : 'inherit'}
                            to="/policies"
                            sx={{
                              textAlign: 'left',
                              justifyContent: 'flex-start',
                            }}
                        >
                            Rules & Policies
                        </Button>
                        {userInfo && !userInfo.isAnonymous &&
                            <Button
                                startIcon={<DatasetIcon fontSize='medium' />}
                                component={RouterLink}
                                color={location.pathname === '/data' ? 'primary' : 'inherit'}
                                to="/data"
                                sx={{
                                  textAlign: 'left',
                                  justifyContent: 'flex-start',
                                }}
                            >
                                Publishing Data
                            </Button>
                        }
                        {userInfo && !userInfo.isAnonymous &&
                            <Button
                                startIcon={
                                    <Badge color="error" variant="dot" invisible={numberOfUnhandledReports === 0}>
                                        <BugReportIcon fontSize='medium' /> </Badge>}
                                component={RouterLink}
                                color={location.pathname === '/reported' ? 'primary' : 'inherit'}
                                to="/reported"
                                sx={{
                                  textAlign: 'left',
                                  justifyContent: 'flex-start',
                                }}
                            >
                                Reported Issues
                            </Button>
                        }
                        {userInfo && !userInfo.isAnonymous &&
                            <Button
                                startIcon={<DomainIcon fontSize='medium' />}
                                component={RouterLink}
                                color={location.pathname === '/domains' ? 'primary' : 'inherit'}
                                to="/domains"
                                sx={{
                                  textAlign: 'left',
                                  justifyContent: 'flex-start',
                                }}
                            >
                                Domains OSINT
                            </Button>
                        }
                        {userInfo && !userInfo.isAnonymous &&
                            <Button
                                startIcon={<ContactMailIcon fontSize='medium' />}
                                component={RouterLink}
                                color={location.pathname === '/emails' ? 'primary' : 'inherit'}
                                to="/emails"
                                sx={{
                                  textAlign: 'left',
                                  justifyContent: 'flex-start',
                                }}
                            >
                                Emails OSINT
                            </Button>
                        }
                        {userInfo && !userInfo.isAnonymous &&
                            <Button
                                startIcon={<CoronavirusIcon fontSize='medium' />}
                                component={RouterLink}
                                color={location.pathname === '/files-osint' ? 'primary' : 'inherit'}
                                to="/files-osint"
                                sx={{
                                  textAlign: 'left',
                                  justifyContent: 'flex-start',
                                }}
                            >
                                Files OSINT
                            </Button>
                        }
                        {userInfo && !userInfo.isAnonymous &&
                            <Button
                                startIcon={<PhishingIcon fontSize='medium' />}
                                component={RouterLink}
                                color={location.pathname === '/urls-osint' ? 'primary' : 'inherit'}
                                to="/urls-osint"
                                sx={{
                                  textAlign: 'left',
                                  justifyContent: 'flex-start',
                                }}
                            >
                                URLs OSINT
                            </Button>
                        }
                    </Stack>
                    <Stack
                        rowGap={2}
                        p={3}
                    >
                        {userInfo ? (
                            <Stack>
                                {!userInfo.isAnonymous && <>
                                    <Button
                                        startIcon={<AccountBoxIcon fontSize='medium' />}
                                        component={RouterLink}
                                        color={location.pathname === '/profile' ? 'primary' : 'inherit'}
                                        to="/profile"
                                        sx={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                        }}
                                    >
                                        Profile
                                    </Button>
                                    <Button
                                        startIcon={<SettingsIcon fontSize='medium' />}
                                        component={RouterLink}
                                        color={location.pathname === '/settings' ? 'primary' : 'inherit'}
                                        to="/settings"
                                        sx={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                        }}
                                    >
                                        Settings
                                    </Button>
                                </>}
                                <Button
                                    startIcon={<LogoutIcon fontSize='medium' />}
                                    onClick={logoutHandler}
                                    color='inherit'
                                    sx={{
                                      textAlign: 'left',
                                      justifyContent: 'flex-start',
                                    }}
                                >
                                    Leave
                                </Button>
                                <Box sx={{
                                  flexGrow: 0,
                                  textAlign: 'left',
                                  pt: 2,
                                }}>
                                    {userInfo.isAnonymous ? "You're anonymous!" : userInfo.email}
                                </Box>
                            </Stack>
                        ) : (
                            <Stack>
                                <Button
                                    startIcon={<LoginOutlinedIcon fontSize='medium' />}
                                    component={RouterLink} to="/sign-in"
                                    color='inherit'
                                    sx={{
                                      textAlign: 'left',
                                      justifyContent: 'flex-start',
                                    }}
                                >
                                    Enter
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                </Box>
            </Drawer >
        </>
  );
}

export { Sidebar };
