import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Box } from '@mui/material';

export default function Footer() {
  return (
        <Box
            component="footer"
            sx={{
              // backgroundColor: (theme) =>
              //     theme.palette.mode === "light"
              //         ? theme.palette.grey[200]
              //         : theme.palette.grey[800],
              pb: 4,
              marginTop: 'auto',
              pl: '248px',
            }}
        >
            <Box mt={5}>
                <Typography variant="body2" color="text.secondary" align="center">
                    {'Copyright © '}
                    <Link color="inherit" href="https://invisiblelab.dev/">
                        InvisibleLab
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
            </Box>
        </Box>
  );
}