import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import HomeScreen from './screens/HomeScreen';
import Policies from './screens/Policies';
import OpenVerification from './screens/OpenVerification';
import Prove from './screens/Prove';
import Verify from './screens/Verify';
import SignIn from './screens/SignIn';
import SignUp from './screens/SignUp';
import Settings from './screens/Settings';
import Reported from './screens/Reported';
import Domains from './screens/Domains';
import Emails from './screens/Emails';
import ShowUser from './screens/ShowUser';
import Profile from './screens/Profile';
import { enableMapSet } from 'immer';
import PublishingData from './screens/PublishingData';
import FilesOSINT from './screens/FilesOSINT';
import URLsOSINT from './screens/URLsOSINT';

enableMapSet();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<HomeScreen />} />
      <Route path="sign-in" element={<SignIn />} />
      <Route path="sign-up" element={<SignUp />} />
      <Route path="profile" element={<Profile />} />
      <Route path="settings" element={<Settings />} />
      <Route path="reported" element={<Reported />} />
      <Route path="domains" element={<Domains />} />
      <Route path="emails" element={<Emails />} />
      <Route path="files-osint" element={<FilesOSINT />} />
      <Route path="urls-osint" element={<URLsOSINT />} />
      <Route path="policies" element={<Policies />} />
      <Route path="data" element={<PublishingData />} />
      <Route path="open-verification" element={<OpenVerification />} />
      <Route path="verify/:id" element={<Verify />} />
      <Route path="prove/:id" element={<Prove />} />
      <Route path="show/user/:id" element={<ShowUser />} />
      <Route path="*" element={<div>Not Found</div>} />
    </Route>,
  ),
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
