import { Box, Container, CssBaseline, Stack } from '@mui/material';
import Footer from '../components/Footer';
import { Sidebar } from '../components/Sidebar';
import UserNotifications from '../components/UserNotifications';
import AppScope from '../components/AppScope';

function InAppLayout({ children }: any) {

  return (
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
            }}
        >
            <Sidebar />
            <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{
              m: 2,
            }} >
                <CssBaseline />
                <AppScope />
                <UserNotifications />
            </Stack>
            <Container>
                <Box
                    sx={{
                      // pt: '20px',
                      pl: '250px',
                    }}
                >
                    {children}
                </Box>
            </Container>
            <Footer />
        </Box>
  );
}

export default InAppLayout;

