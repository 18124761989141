import { Grid, Stack, Typography } from '@mui/material';
import ThreeMonthCalendars from '../components/ThreeMonthCalendars';

import { useSelector } from 'react-redux';
import ReportHandler from '../components/ReportHandler';


export default function Reported() {

  // const userInfo = useSelector((state: any) => {
  //   const user = state.user;
  //   if (user) {
  //     return JSON.parse(user.user);
  //   }
  //   return null;
  // });

  const proofs = useSelector((state: any) => {
    const user = state.user;
    if (user) {
      return user.userProofs;
    }
    return null;
  });

  const currentUser = useSelector((state: any) => {
    const merkle = state.merkle;
    if (merkle) {
      return JSON.parse(merkle.userMerkle);
    }
    return null;
  });

  const reportDeck = useSelector((state: any) => {
    const user = state.user;
    if (user) {
      return user.userReports;
    }
    return null;
  });

  const numberOfUnhandledReports = useSelector((state: any) => {
    const user = state.user;
    if (user) {
      return user.unhandledReports;
    }
    return null;
  });

  const proofIds = proofs ? proofs.map((proof: any) => proof._id) : [];
  if (currentUser) {
    proofIds.push(currentUser._id);
  }

  return (
        <Stack spacing={2}>
            <Typography variant="h5" textAlign='left' align="center">
                Verifications Heat Map
            </Typography>
            <ThreeMonthCalendars trackerRefs={proofIds} />

            {numberOfUnhandledReports && reportDeck ?
                <>
                    <Typography variant="h5" textAlign='left' align="center">
                        Unhandled reports ({numberOfUnhandledReports})
                    </Typography>
                    <Stack spacing={2}>
                        {reportDeck.map((reports: any) => (
                            <Grid container spacing={2} alignItems="center" key={reportDeck.updatedAt}>
                                {/* <Grid item xs={3}>
                                    <ProofSummary userProof={proofs.find((proof: { _id: any; }) => proof._id === reports.ref)} />
                                </Grid> */}
                                <Grid item xs={8}>
                                    <Stack spacing={1}>
                                        {reports.reports.map((report: any) => (
                                            <ReportHandler report={report} reportsID={reports._id} key={report.date} />
                                        ))}
                                    </Stack>
                                </Grid>
                            </Grid>
                        ))}
                    </Stack>
                </> : ''
            }

        </Stack>
  );
}