import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

export default function URLsOSINT() {
  const userInfo = useSelector((state: any) => {
    const user = state.user;
    if (user) {
      return JSON.parse(user.user);
    }
    return null;
  });

  return (
    <Stack spacing={2}>
      <Typography variant="h5" textAlign='left' align="center">
        URLs Open-Source Intelligence
      </Typography>
      {userInfo && !userInfo.isAnonymous && 
        <Stack spacing={2} direction='row' justifyContent='space-between'>
        </Stack>
      }
      {!userInfo && <Typography variant="body1" textAlign='left' align="center">This feature is exclusive for registered users</Typography>}
    </Stack>
  );
}