import { EmailOsintURL } from '../constants';
import { apiSlice } from './apiSlice';

export const emailsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    postNewEmailAnalysis: builder.mutation({
      query: (email: string) => ({
        url: `${EmailOsintURL}/${email}`,
        method: 'POST',
      }),
    }),
    getEmailAnalysis: builder.query({
      query: (email: string) => ({
        url: `${EmailOsintURL}/${email}`,
        method: 'GET',
      }),
    }),
    refreshEmailAnalysis: builder.mutation({
      query: (email: string) => ({
        url: `${EmailOsintURL}/${email}`,
        method: 'PUT',
      }),
    }),
    deleteEmailAnalysis: builder.mutation({
      query: (email: string) => ({
        url: `${EmailOsintURL}/${email}`,
        method: 'DELETE',
      }),
    }),
    postAllEmails: builder.mutation({
      query: (emails: string[]) => ({
        url: `${EmailOsintURL}/all`,
        method: 'POST',
        body: { emails },
      }),
    }),
    mergeToAllEmails: builder.mutation({
      query: (emails: string[]) => ({
        url: `${EmailOsintURL}/all`,
        method: 'PATCH',
        body: { emails },
      }),
    }),
    getAllEmails: builder.query({
      query: () => ({
        url: `${EmailOsintURL}/all`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  usePostNewEmailAnalysisMutation,
  useGetEmailAnalysisQuery,
  useDeleteEmailAnalysisMutation,
  usePostAllEmailsMutation,
  useGetAllEmailsQuery,
  useRefreshEmailAnalysisMutation,
  useMergeToAllEmailsMutation,
} = emailsApiSlice;