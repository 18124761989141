import PoliciesList from '../components/PoliciesList';
import { useGetPoliciesQuery } from '../slices/policyApiSlice';
import policyImage from '../assets/images/policyimage_opt.svg';

export default function Policies() {

  const { data: policies, isLoading, error } = useGetPoliciesQuery(undefined);

  return (
        <>
            {isLoading ? (
                <div>Loading...</div>
            ) : error ? (
                <div>Something went wrong...</div>
            ) : (
                <div>
                    <img src={policyImage} alt="policy" style={{ height: '100px', marginBottom: '10px' }} />
                    {Array.isArray(policies) && <PoliciesList data={policies} />}
                </div>
            )}
        </>
  );
}