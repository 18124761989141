import { createSlice } from '@reduxjs/toolkit';

const filesOsintStorage = localStorage.getItem('filesOsint');

const initialState = {
  filesOsint: filesOsintStorage ? JSON.parse(filesOsintStorage) : null,
};

const filesSlice = createSlice({
  name: 'FilesOsintSlice',
  initialState,
  reducers: {
    setFilesOsint: (state, action) => {
      state.filesOsint = action.payload;
      localStorage.setItem('filesOsint', JSON.stringify(action.payload));
    },
    reset: (state) => {
      state.filesOsint = null;
      localStorage.clear();
    },
  },
});

export const {
  setFilesOsint,
  reset,
} = filesSlice.actions;

export default filesSlice.reducer;