import { ActivityURL } from '../constants';
import { apiSlice } from './apiSlice';

export const activityApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getActivityLog: builder.query({
      query: (page) => ({
        url: `${ActivityURL}/?page=${page}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useGetActivityLogQuery,
} = activityApiSlice;