import { Stack, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, Badge, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useGetAllDomainsQuery, usePostAllDomainsMutation, useRefreshDomainAnalysisMutation, usePostNewDomainAnalysisMutation } from '../slices/domainsApiSlice';
import { setDomainsOsint } from '../slices/domainsSlice';
import DomainsList from '../components/DomainsList';
import Filter from '../components/domain-utils/Filter';
import SaveIcon from '@mui/icons-material/Save';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

export default function Domains() {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [shouldSave, setShouldSave] = useState(false);

  const userInfo = useSelector((state: any) => {
    const user = state.user;
    if (user) {
      return JSON.parse(user.user);
    }
    return null;
  });

  const domains = useSelector((state: any) => {
    if (state?.domains?.domainsOsint) {
      return state.domains.domainsOsint;
    }
    return [];
  });
  const dispatch = useDispatch();
  const { data: domainsList, error } = useGetAllDomainsQuery({});
  const [postNewDomainAnalysis] = usePostNewDomainAnalysisMutation();
  const [refreshDomainAnalysis] = useRefreshDomainAnalysisMutation();
  const [postAllDomains] = usePostAllDomainsMutation();

  useEffect(() => {
    if (domainsList) {
      dispatch(setDomainsOsint(domainsList));
    }
    if (error) {
      console.error('Failed to fetch domains:', error);
    }
  }, [domainsList, error, dispatch]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const analysisSubmission = async (domain: string) => {
    const result: any = await postNewDomainAnalysis(domain);
    if (result) {
      const analysis = {
        domain,
        analysis: {
          ...result.data.analysis,
        },
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
      dispatch(setDomainsOsint([analysis, ...domains]));
      setShouldSave(true);
    } else {
      console.error('Failed to fetch domain:', domain);
    }
  };

  const newAnalysisSubmission = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const textValue = (e.currentTarget.elements[0] as HTMLInputElement).value;
    analysisSubmission(textValue);
    handleClose();
  };

  const addBulkAnalysisSubmission = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // const domainsTest = ['aconinvlco.com'];
    // for (const domain of domainsTest) {
    //   await postNewDomainAnalysis(domain);
    //   // eslint-disable-next-line @typescript-eslint/no-loop-func
    //   await new Promise(resolve => setTimeout(resolve, 5000)); // wait for 5 seconds
    // }
  };

  function saveDomains(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();
    const domainsTest = domains.map((domain: any) => domain.domain);
    postAllDomains(domainsTest);
    setShouldSave(false);
  }

  function deleteDomain(domain: string): void {
    dispatch(setDomainsOsint(domains.filter((d: any) => d.domain !== domain)));
    setShouldSave(true);
  }

  async function refreshDomain(domain: string): Promise<void> {
    try {
      const result: any = await refreshDomainAnalysis(domain);
      const updatedDomains = domains.filter((d: any) => d.domain !== domain);

      dispatch(setDomainsOsint([result.data, ...updatedDomains]));
    } catch (refreshError) {
      console.error('Failed to refresh domain analysis:', refreshError);
    }
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h5" textAlign='left' align="center">
        Domain Open-Source Intelligence
      </Typography>
      {userInfo && !userInfo.isAnonymous && <>
        <Stack spacing={2} direction='row' justifyContent='space-between'>
          <Stack spacing={2} direction='row'>
            <Filter applyFilter={setFilter} />
            <Typography variant="body2" fontWeight={800} textAlign='left' align="center">{domains.length}</Typography>
          </Stack>
          <Stack spacing={2} direction='row'>
            <Tooltip title="Add Domains" arrow>
              <IconButton aria-label="add-domains" onClick={handleOpen} color='primary'>
                <DomainAddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add Bulk Domains" arrow>
              <IconButton aria-label="save-bulk-domains" onClick={addBulkAnalysisSubmission} color='primary'>
                <PlaylistAddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Save" arrow>
              <IconButton aria-label="save-domains" color='primary' onClick={saveDomains} disabled={!shouldSave}>
                <Badge color="secondary" variant="dot" invisible={!shouldSave}>
                  <SaveIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Add Domains</DialogTitle>
          <form onSubmit={newAnalysisSubmission}>
            <DialogContent>
              <TextField name="domains" label="Domains" fullWidth multiline />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Submit</Button>
            </DialogActions>
          </form>
        </Dialog>
        <DomainsList
          filter={filter}
          deleteDomain={deleteDomain}
          refreshDomain={refreshDomain}
        />
      </>}
      {!userInfo && <Typography variant="body1" textAlign='left' align="center">This feature is exclusive for registered users</Typography>}
    </Stack>
  );
}