import { createSlice } from '@reduxjs/toolkit';

const totalVerifications = localStorage.getItem('totalVerifications');
const totalReports = localStorage.getItem('totalReports');
const totalProofs = localStorage.getItem('totalProofs');
const totalFailedVerifications = localStorage.getItem('totalFailedVerifications');

const initialState = {
  totalVerifications: totalVerifications ? totalVerifications : 0,
  totalReports: totalReports ? totalReports : 0,
  totalProofs: totalProofs ? totalProofs : 0,
  totalFailedVerifications: totalFailedVerifications ? totalFailedVerifications : 0,
};

const dashboardSlice = createSlice({
  name: 'DashboardSlice',
  initialState,
  reducers: {
    setTotalVerifications: (state, action) => {
      state.totalVerifications = action.payload;
      localStorage.setItem('totalVerifications', action.payload);
    },
    setTotalReports: (state, action) => {
      state.totalReports = action.payload;
      localStorage.setItem('totalReports', action.payload);
    },
    setTotalProofs: (state, action) => {
      state.totalProofs = action.payload;
      localStorage.setItem('totalProofs', action.payload);
    },
    setTotalFailedVerifications: (state, action) => {
      state.totalFailedVerifications = action.payload;
      localStorage.setItem('totalFailedVerifications', action.payload);
    },
    reset: (state) => {
      state.totalVerifications = 0;
      state.totalReports = 0;
      state.totalProofs = 0;
      state.totalFailedVerifications = 0;
      localStorage.clear();
    },
  },
});

export const {
  setTotalVerifications,
  setTotalReports,
  setTotalProofs,
  setTotalFailedVerifications,
  reset,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;