import { createSlice } from '@reduxjs/toolkit';

const userProfileStorage = localStorage.getItem('userProfile');

const initialState = {
  userProfile: userProfileStorage ? userProfileStorage : null,
};

const userProfileSlice = createSlice({
  name: 'UserProfileSlice',
  initialState,
  reducers: {
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
      localStorage.setItem('userProfile', action.payload);
    },
    reset: (state) => {
      state.userProfile = null;
      localStorage.clear();
    },
  },
});

export const {
  setUserProfile,
  reset,
} = userProfileSlice.actions;

export default userProfileSlice.reducer;