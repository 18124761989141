import { IconButton, Typography, Stack, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Alert, Snackbar } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import DraftsIcon from '@mui/icons-material/Drafts';
import { useShowUserSimpleProfileQuery } from '../slices/showApiSlice';
import { useState } from 'react';
import { useMarkAsSolveMutation } from '../slices/reportsApiSlice';
import { dateAndHour } from '../utils/dates';
import { Link as RouterLink } from 'react-router-dom';

export default function ReportHandler({ report, reportsID }: { report: any, reportsID: string }) {

  const [open, setOpen] = useState(false);

  const { data: user } = useShowUserSimpleProfileQuery(report.author);
  const [markAsSolve, { isLoading }] = useMarkAsSolveMutation();
  const [openSnack, setOpenStack] = useState(false);

  const handleCloseSnack = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenStack(false);
  };

  const markAsSolved = async () => {
    try {
      await markAsSolve({
        reportsID,
        reportID: report._id,
      });

      setOpenStack(true);
    } catch (error) {
      console.log(error);
    }
    setOpen(false);
  };


  return (<><Stack direction='row' alignItems='center'
        sx={{
          borderLeft: '2px solid',
          borderColor: report.handled ? 'gray' : 'secondary.main',
        }}
    >
        <Stack alignItems='center' display='flex' justifyContent="flex-start">
            <IconButton
                sx={{ p: '5px', m: '2px' }}
                size="small"
                component={RouterLink}
                to={`/show/user/${report.author}`}
                aria-label="show-user-profile"
                // onClick={() => alert('will jump to user profile')}
                color={'primary'}>
                <AccountCircleIcon />
            </IconButton>

            <IconButton
                type="button"
                size="small"
                aria-label="read-message"
                color={report.handled ? 'inherit' : 'primary'}
                sx={{ p: '5px', m: '2px' }}
                onClick={() => setOpen(true)}>
                {report.handled ? <DraftsIcon /> : <MarkunreadIcon />}
            </IconButton>

        </Stack>

        <Stack alignItems='center' width='100%'>

            <Typography variant="body1" fontWeight="800" textAlign="left" width='100%'>
                {user?.email || report.author}
            </Typography>

            <Typography variant="body2" textAlign="left" width='100%' color="gray">
                {dateAndHour(new Date(report.date))}
            </Typography>
            <Typography variant="body1" noWrap textAlign="left" width='100%'>
                {report.message}
            </Typography>

        </Stack>
    </Stack>
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth={true}
            maxWidth="md"
        >
            <DialogTitle>Message reported</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`${user?.email || report.author} submitted the following message:`}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="report"
                    label=""
                    type="text"
                    fullWidth
                    // variant="filled"
                    multiline
                    maxRows={20}
                    defaultValue={report.message}
                    disabled={true}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        color: 'black',
                        WebkitTextFillColor: 'black',
                      },
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    onClick={markAsSolved}
                    disabled={isLoading || report.handled}
                >
                    Mark as solved
                </Button>
            </DialogActions>
        </Dialog>
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={openSnack}
            autoHideDuration={6000}
            onClose={handleCloseSnack}>
            <Alert onClose={handleCloseSnack} variant='filled' severity="success" sx={{ width: '100%' }}>
                Report submitted successfully!
            </Alert>
        </Snackbar>
    </>);
}