import './App.css';
import { ThemeOptions, ThemeProvider, createTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import InAppLayout from './layouts/InAppLayout';
import AuthLayout from './layouts/AuthLayout';
import { useLocation } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  setUser,
  clearUser,
} from './slices/userSlice';

// import { logout } from './slices/authSlice';

function App() {

  const authRoutes = ['/sign-in', '/sign-up', '/forgot-password', '/reset-password'];
  const location = useLocation();
  const inAuthRoutes = authRoutes.includes(location.pathname);

  const dispatch = useDispatch();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log('User state changed!', user);
      if (user) {
        dispatch(setUser(JSON.stringify(user)));
      } else {
        dispatch(clearUser());
      }
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  const themeOptions: ThemeOptions = {
    palette: {
      mode: 'light',
      primary: {
        main: '#e36d5b',
      },
      secondary: {
        main: '#ffc107',
      },
      error: {
        main: '#f44336',
      },
    },
    typography: {
      fontFamily: [
        'Nunito',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            borderBottomStyle: 'solid',
            backgroundColor: 'transparent',
            color: 'inherit',
          },
        },
      },
    },
  };
  const strataTheme = createTheme(themeOptions);

  return (
    <ThemeProvider theme={strataTheme}>
      <div className="App">
        {inAuthRoutes ?
          <AuthLayout>
            <Outlet />
          </AuthLayout>
          :
          <InAppLayout>
            <Outlet />
          </InAppLayout>
        }
      </div>
    </ThemeProvider>
  );
}

export default App;
