import { CircuitURL } from '../constants';
import { apiSlice } from './apiSlice';

export const policyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCircuits: builder.query({
      query: () => ({
        url: CircuitURL,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    getCircuitDetails: builder.query({
      query: (id: string) => ({
        url: `${CircuitURL}/${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    getCircuitByPolicyId: builder.query({
      query: (id: string) => ({
        url: `${CircuitURL}/policy/${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useGetCircuitsQuery,
  useGetCircuitDetailsQuery,
  useGetCircuitByPolicyIdQuery,
} = policyApiSlice;