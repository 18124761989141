import { FileOsintURL } from '../constants';
import { apiSlice } from './apiSlice';

export const filesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    retrieveHashIndicators: builder.mutation({
      query: (hash: string) => ({
        url: `${FileOsintURL}/${hash}`,
        method: 'POST',
      }),
    }),
    getFileAnalysis: builder.query({
      query: (hash: string) => ({
        url: `${FileOsintURL}/${hash}`,
        method: 'GET',
      }),
    }),
    postFileBinary: builder.mutation({
      query: (file: FormData) => {
        return {
          url: `${FileOsintURL}/binaryupload`,
          method: 'POST',
          body: file,
        };
      },
    }),
    postFileBase64: builder.mutation({
      query: (file: string) => {
        return {
          url: `${FileOsintURL}/base64upload`,
          method: 'POST',
          body: file,
        };
      },
    }),
    postAllHashes: builder.mutation({
      query: (hashes: string[]) => ({
        url: `${FileOsintURL}/hashes`,
        method: 'POST',
        body: { hashes },
      }),
    }),
    mergeToAllHashes: builder.mutation({
      query: (hashes: string[]) => ({
        url: `${FileOsintURL}/hashes`,
        method: 'PATCH',
        body: { hashes },
      }),
    }),
    getAllHashes: builder.query({
      query: () => ({
        url: `${FileOsintURL}/hashes`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useRetrieveHashIndicatorsMutation,
  useGetFileAnalysisQuery,
  usePostFileBinaryMutation,
  usePostFileBase64Mutation,
  usePostAllHashesMutation,
  useMergeToAllHashesMutation,
  useGetAllHashesQuery,
} = filesApiSlice;