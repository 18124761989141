import { Box, Button, Stack, Typography } from '@mui/material';
import blogo from '../assets/images/mzstratalogod_opt.svg';
import Link from '@mui/material/Link';


function AuthLayout({ children }: any) {

  return (
        <Stack direction="row"
            justifyContent='space-between'>
            <Stack direction="column" sx={{
              width: '100%',
            }}>
                <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      //minHeight: '100vh',
                    }}
                >
                    {children}
                </Box>
            </Stack>
            <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '100vh',
                  width: '100%',
                  bgcolor: '#282828',
                  backgroundImage: `url(${blogo})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  justifyContent: 'center',
                  alignItems: 'center',
                  pb: 35,
                }}>
                <Stack maxWidth={400}>
                    <Typography variant="h2" component="div" sx={{
                      color: '#fff',
                      textAlign: 'left',
                      mt: 2,
                      mb: 2,
                    }}>
                        Want to know more?
                    </Typography>
                    <Typography variant="h4" component="div" sx={{
                      color: '#fff',
                      textAlign: 'left',
                      mt: 2,
                      mb: 2,
                    }}>
                        Visit our website and learn how <b>strata-protection</b> works.
                    </Typography>
                    <Button
                        component={Link}
                        href="https://www.invisiblelab.dev/"
                        variant="outlined"
                        target="_blank"
                        sx={{
                          color: '#fff',
                          textAlign: 'left',
                          mt: 2,
                          mb: 2,
                          borderColor: '#fff',
                          borderWidth: 2,
                          '&:hover': {
                            bgcolor: '#fff',
                            color: '#282828',
                            borderColor: '#fff',
                            borderWidth: 2,
                          },
                        }}>
                        Learn More
                    </Button>
                </Stack>
            </Box>
        </Stack>
  );
}

export default AuthLayout;