import { Paper, InputBase, IconButton } from '@mui/material';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';

interface FilterProps {
  applyFilter: (value: string) => void;
}

export default function Filter({ applyFilter }: FilterProps) {
  const [filterValue, setFilterValue] = useState('');

  const handleSearch = (event: any) => {
    event.preventDefault();
    applyFilter(filterValue);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  };

  return (
    <>
      <Paper
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 450, maxWidth: 650, margin: '18px auto', mb: 12 }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={'Filter data from Emails List...'}
          defaultValue={filterValue || ''}
          inputProps={{ 'aria-label': 'search emails list' }}
          onChange={handleFilterChange}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handleSearch(event);
            }
          }}
        />
        <IconButton
          color={'primary'}
          sx={{ p: '10px' }} aria-label="search" onClick={handleSearch}>
          <SearchIcon />
        </IconButton>
      </Paper>
    </>
  );
}