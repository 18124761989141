import { useParams } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
import { IPolicy, IProof } from '../types';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import axios from 'axios';
import PolicyValidatorForm from '../components/PolicyValidatorForm';
import { Box, Button } from '@mui/material';

export default function Verify() {

  const [policyData, setPolicyData] = useState<IPolicy>();
  const [proofData, setProofData] = useState<IProof>();

  const { id } = useParams();
  // const navigate = useNavigate(); // navigate('/verify/123?name=John')
  // const location = useLocation();
  // if (id === undefined || id === null) {
  //     navigate('/open-verification');
  // }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const proofResponse = await axios.get('/api/v1/proofs/' + id);
        setProofData(proofResponse.data);

        const policyResponse = await axios.get('/api/v1/policies/' + proofResponse.data.policy);
        setPolicyData(policyResponse.data);
      } catch (error) {
        // Handle errors
        console.error('Error fetching data:', error);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  // const queryParams = new URLSearchParams(location.search);
  // const nameParam = queryParams.get('name');

  return (
        < >
            {/* {nameParam && <p>Name: {nameParam}</p>} */}
            <Box sx={{
              pb: 2,
              display: 'flex',
              flexDirection: 'row',

            }}>
                <Button
                    startIcon={<KeyboardBackspaceIcon fontSize='medium' />}
                    component={RouterLink}
                    color='inherit'
                    to="/open-verification"
                    sx={{
                      textAlign: 'left',
                      justifyContent: 'flex-start',
                    }}
                >
                    View other published data
                </Button>
            </Box>
            {policyData && proofData &&
                <PolicyValidatorForm policyData={policyData} proofData={proofData} />
            }
        </>
  );
}