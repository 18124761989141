import { Box } from '@mui/material';
import { green, yellow, red } from '@mui/material/colors';

interface CellRiskScoreProps {
  riskScore: number;
}

export default function CellRiskScore({ riskScore }: CellRiskScoreProps) {
  let color;
  if (riskScore < 50) {
    color = green[500];
  } else if (riskScore < 80) {
    color = yellow[700];
  } else {
    color = red[500];
  }

  return (
    <Box 
      sx={{ 
        bgcolor: color,
        color: 'white',
        width: 36,
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
      }}
    >
      {riskScore}
    </Box>
  );
}