import { Document } from 'mongoose';

export enum NotificationTypes {
  NO_ACTION,
  ANONYMOUS_ACCOUNT,
  EMAIL_NOT_VERIFIED,
  MFA_NOT_VERIFIED,
  APP_LINK,
}

export type UserNotification = {
  type: NotificationTypes;
  message: string;
  to?: string;
  text?: string;
};

export interface IKey {
  name: string;
  help: string;
  var_name: string;
  type: string;
  visibility: string;
}

export interface IPolicy extends Document {
  _id: string;
  title: string;
  circuit_type: string;
  short: string;
  description: string;
  keys: IKey[];
}

export interface IProof extends Document {
  identity: string;
  type: string;
  proof: string;
  policy: string;
  author: string;
  createdAt: string;
}

export interface ICircuit extends Document {
  policy: string;
  circuit: {
    backend: string;
    abi: {
      parameters: {
        name: string;
        type: {
          kind: string;
          sign: string;
          width: number;
        };
        visibility: string;
      }[];
      param_witnesses: {
        [key: string]: number[];
      };
      return_type: string;
      return_witnesses: number[];
    };
    bytecode: string;
    proving_key: string;
    verification_key: string;
  };
}

export type Policy = {
  _id: string;
  title: string;
  circuit_type: string;
  short: string;
  description: string;
  keys: string[];
};

export type Proof = {
  _id: string;
  domain_name: string;
  proof: string;
  policy: number;
  createdAt: string;
};

