import { TrackerURL } from '../constants';
import { apiSlice } from './apiSlice';

export const trackerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRecordsForASetOfRefs: builder.query({
      query: (refs) => ({
        url: `${TrackerURL}/?refs=${refs.join(',')}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useGetRecordsForASetOfRefsQuery,
} = trackerApiSlice;