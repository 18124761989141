import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import useAuthentication from '../auth/fbase';
import { Alert, Chip, CircularProgress, Divider, Stack } from '@mui/material';
import logo from '../assets/images/mzstratalogoc_opt.svg';
// import { login } from '../auth/fbase';

export default function SignIn() {

  const navigate = useNavigate();

  const { loading, error, login, anonymousLogin } = useAuthentication();
  const userInfo = useSelector((state: any) => {
    const user = state.user;
    if (user) {
      return JSON.parse(user.user);
    }
    return null;
  });

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get('redirect') || '/';

  // error && console.error(error);

  React.useEffect(() => {
    if (userInfo) {
      console.log('userInfo', userInfo);
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      const email = data.get('email') as string;
      const password = data.get('password') as string;
      login(email, password);
      // navigate(redirect);
    } catch (errorLogin) {
      console.error(errorLogin);
    }
  };

  const handleAnonymousAuth = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      anonymousLogin();
      // navigate(redirect);
    } catch (errorAL) {
      console.error(errorAL);
    }
  };

  return (
        <Stack
            direction="column"
            justifyContent='space-between'
            alignItems="flex-start"
        >
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              pl: 10,
              pt: 10,
            }}>
                <Link component={RouterLink} to="/" sx={{
                  height: 64,
                  width: 96,
                }}>
                    <img src={logo} alt="logo" height='100%' width='100%' />
                </Link>
            </Box>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                      marginTop: 8,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                >
                    <Stack direction="row" spacing={2} sx={{ mb: 2 }} width='100%'>
                        <Box sx={{
                          width: '100%',
                          p: 0,
                        }}>
                            <Typography variant="h4" textAlign='left' >
                                Sign in to your account.
                            </Typography>
                        </Box>
                        <Box sx={{
                          width: '100%',
                        }}>
                            {loading && <CircularProgress />}
                        </Box>
                    </Stack>

                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            variant="standard"
                            InputLabelProps={{
                              shrink: true,
                              sx: {
                                fontSize: '1.2rem',
                              },
                            }}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            variant="standard"
                            InputLabelProps={{
                              shrink: true,
                              sx: {
                                fontSize: '1.2rem',
                              },
                            }}
                        />
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 3 }}
                            disabled={loading}
                        >
                            Sign In
                        </Button>
                        {/* <Grid container flexGrow={1} justifyContent="space-between">
                            <Grid item>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>

                            </Grid>
                        </Grid> */}
                    </Box>
                    <Divider orientation='horizontal' sx={{
                      width: '100%',
                    }}>
                        <Chip label="or" />
                    </Divider>
                    <Box component="form" noValidate onSubmit={handleAnonymousAuth} sx={{
                      width: '100%',
                    }}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={loading}
                        >
                            Enter anonymously
                        </Button>
                    </Box>
                </Box>
            </Container>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              pl: 10,
              pt: 10,
            }}>
                <Typography variant="h6" textAlign='left' >
                    Don't have an account?
                    <Link
                        component={RouterLink}
                        to={redirect ? `/sign-up?redirect=${redirect}` : '/sign-up'}
                        color="inherit"
                        sx={{
                          ml: 1,
                          mr: 1,
                          textDecoration: 'none',
                          fontWeight: 'bold',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}
                    >
                        Sign Up
                    </Link>
                    for an account now.
                </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              pl: 10,
              pt: 10,
            }}>
                {error &&
                    <Alert severity="error">
                        {error}
                    </Alert>}
            </Box>
        </Stack>
  );
}