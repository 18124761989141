import { createSlice } from '@reduxjs/toolkit';

const currentUserStorage = localStorage.getItem('user');
const userNotificationsStorage = localStorage.getItem('userNotifications');
const unhandledReportsStorage = localStorage.getItem('unhandledReports');
const userReportsStorage = localStorage.getItem('userReports');
const userProofsStorage = localStorage.getItem('userProofs');

const initialState = {
  user: currentUserStorage ? currentUserStorage : null,
  userNotifications: userNotificationsStorage ? new Set(JSON.parse(userNotificationsStorage)) : new Set(),
  unhandledReports: unhandledReportsStorage ? parseInt(unhandledReportsStorage) : 0,
  userReports: userReportsStorage ? JSON.parse(userReportsStorage) : [],
  userProofs: userProofsStorage ? JSON.parse(userProofsStorage) : [],
};

const userSlice = createSlice({
  name: 'UserSlice',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      localStorage.setItem('user', action.payload);
    },
    setUserNotifications: (state, action) => {
      const newNotifications = new Set(action.payload);
      newNotifications.forEach((notification) => {
        state.userNotifications.add(notification);
      });
      localStorage.setItem('userNotifications', JSON.stringify(Array.from(state.userNotifications)));
    },
    removeUserNotification: (state, action) => {
      state.userNotifications.delete(action.payload);
      localStorage.setItem('userNotifications', JSON.stringify(Array.from(state.userNotifications)));
    },
    setUnhandledReports: (state, action) => {
      state.unhandledReports = action.payload;
      localStorage.setItem('unhandledReports', action.payload);
    },
    setUserReports: (state, action) => {
      state.userReports = action.payload;
      localStorage.setItem('userReports', JSON.stringify(action.payload));
    },
    setUserProofs: (state, action) => {
      state.userProofs = action.payload;
      localStorage.setItem('userProofs', JSON.stringify(action.payload));
    },
    clearUser: (state) => {
      state.user = null;
      state.userNotifications = new Set();
      state.unhandledReports = 0;
      state.userReports = [];
      state.userProofs = [];
      localStorage.clear();
    },
  },
});

export const {
  setUser,
  clearUser,
  setUserNotifications,
  removeUserNotification,
  setUnhandledReports,  //@audit to be converted to general statistics
  setUserReports,
  setUserProofs,
} = userSlice.actions;

export default userSlice.reducer;